import { createContext, useMemo, useReducer, type JSX } from 'react';
import type { DidomiAction } from './didomiActions';
import didomiReducer from './didomiReducer';
import type { DidomiState } from './didomiTypes';

type DidomiContextProps = {
  state: DidomiState;
  dispatch: React.Dispatch<DidomiAction>;
};

const INITIAL_STATE: DidomiState = {
  hasLoaded: false,
  hasConsentChanged: false,
  isInIframe: false,
  consent: {},
};

const DidomiContext = createContext({} as DidomiContextProps);

function DidomiProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [state, dispatch] = useReducer(didomiReducer, INITIAL_STATE);
  const value: {
    state: DidomiState;
    dispatch: React.Dispatch<DidomiAction>;
  } = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <DidomiContext.Provider value={value}>{children}</DidomiContext.Provider>
  );
}

export { DidomiContext, DidomiProvider };
