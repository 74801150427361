import type { AcmApiError } from '@canalplus/types-acm';
import { AcmErrorCode } from '@canalplus/types-acm';
import { isSomeEnum } from '@dce-front/onewebapp-utils';

/** AcmApiError type guard */
export const isAcmApiError = (error: unknown): error is AcmApiError =>
  // error is an object
  typeof error === 'object' &&
  error !== null &&
  // error has the expected properties
  'errorCode' in error &&
  'errorDescription' in error &&
  'isOk' in error &&
  'statusCode' in error &&
  // error properties have the expected types
  isSomeEnum(AcmErrorCode)(error.errorCode) &&
  typeof error.errorDescription === 'string' &&
  typeof error.isOk === 'boolean' &&
  typeof error.statusCode === 'number';
