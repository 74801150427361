import type { JSX } from 'react';
import { useCallback, useState } from 'react';
import { DetailPageTransactionalSvg } from '../../../icons/icons';
import { ButtonRetractable } from '../../Actions/ButtonRetractable/ButtonRetractable';
import type { HeaderSubscribeButtonProps } from './HeaderSubscribeButton.types';

const getAvailableSpace = (element: HTMLElement | null) => {
  if (!element) {
    return 0;
  }
  // Because of justify-content: flex-end, we calculate the available space in container by subtracting the
  // distance between the container.DOMRect.left and the first element.DOMRect.left
  const container = element.parentElement;
  const containerFirstElementLeft =
    container?.firstElementChild?.getBoundingClientRect().left || 0;
  const containerLeft = container?.getBoundingClientRect().left || 0;
  const availableWidth = containerFirstElementLeft - containerLeft;
  return availableWidth > 0 ? availableWidth : 0;
};

/**
 * Header subscribe button component. It comes pre-configured with the appropriate icon and variant.
 * It wraps a ButtonRetractable component with the appropriate styles and icon, by also checking if
 * the label is overflowing on mobile devices. If it is overflowing, the label is hidden and only the
 * icon is displayed.
 *
 * - It uses the 'slim' variant and the DetailPageTransactionalSvg.
 * - NoJS render, the label is displayed, truncated if it overflows.
 * - With JS, it checks if the label is overflowing on mobile and checks for available space in the container.
 *
 * > **Note:** For mobile devices, only the icon is visible (label for screen readers's only), and
 *   for desktop devices, only the label is visible.
 *
 * @returns The rendered HeaderSubscribeButton component.
 *
 * @example
 *
 * ```tsx
 * <HeaderSubscribeButton label="Subscribe" onClick={() => {}} />
 * <HeaderSubscribeButton label="Subscribe" id="myId" onClick={() => {}} disabled />
 * ```
 */
export function HeaderSubscribeButton({
  label,
  id,
  ...rest
}: HeaderSubscribeButtonProps): JSX.Element {
  const [buttonRef, setButtonRef] = useState<HTMLButtonElement | null>(null);

  const handleComputeAvailableSpace = useCallback(
    () => getAvailableSpace(buttonRef),
    [buttonRef],
  );

  return (
    <ButtonRetractable
      ref={setButtonRef}
      id={id}
      label={label}
      variant="slim"
      icon={
        <DetailPageTransactionalSvg
          role="img"
          aria-label={label}
          className="dt-breakpoint-sm-start:hidden"
        />
      }
      computeAvailableSpace={handleComputeAvailableSpace}
      {...rest}
    />
  );
}
