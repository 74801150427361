import loadable from '@loadable/component';
import Spinner from '../../Spinner/Spinner';

const LoadableExternalServiceModal = loadable(
  () => import('./ExternalServiceModal'),
  {
    fallback: <Spinner />,
  },
);

export default LoadableExternalServiceModal;
