import { type PassSdkConfig, type PassSdkFunction } from '@canalplus/sdk-pass';
import { usePassSdkContext } from './private/usePassSdkContext';

/**
 * Automatically bind a sdk-pass function with the context config
 *
 * ⚠️ To be used in a `passSdkContext` provider or the `PassSdkProvider` component
 *
 * @param passSdkFunction
 * @param parameters The required parameters for the function
 * @param partialConfig The config to override the context config
 *
 * @returns A bind sdk-pass function with config and parameters
 */
export const usePassSdk = <T extends PassSdkFunction>(
  passSdkFunction: T,
  partialConfig?: Partial<PassSdkConfig>,
): ((parameters: Parameters<T>[1]) => ReturnType<T>) => {
  const config = usePassSdkContext();

  return (parameters) =>
    passSdkFunction(
      { ...config, ...partialConfig },
      parameters as any,
    ) as ReturnType<T>;
};
