import type { ForwardedRef, JSX } from 'react';
import { cloneElement, forwardRef } from 'react';
import { useDiveContext } from '../../../context';
import { buttonCVA, buttonIconCVA, buttonLabelCVA } from './Button.cva';
import type { ButtonProps } from './Button.types';

const LABEL_LONG_LENGTH_THRESHOLD = 15;

/**
 * A Button is a clickable Element that triggers an action. It contains a label passed as `children` and an optional `icon` ReactElement:
 *
 * @example
 *
 * ```tsx
 * <Button variant="primary" icon={<PlayIcon />} onClick={onClick} >
 *   Click to play
 * </Button>
 * ```
 *
 * Use the `renderWrapper` prop when you need the visual styles of the Button, but not its default `<button>` HTML Element:
 *
 * @example
 *
 * ```tsx
 * <Button
 *  renderWrapper={({ id, disabled, className, onClick, children }) => {
 *    // Rendering an HTML Anchor that has the visual styles of a Button:
 *    return (
 *      <a
 *        id={id}
 *        className={className}
 *        onClick={onClick}
 *        aria-disabled={disabled}
 *        {...(disabled ? {} : { href: "https://canalplus.com/" })}
 *      >
 *        {children}
 *      </a>
 *    );
 *  }}
 * >
 *     Click to navigate
 * </Button>
 * ```
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(
    {
      children = '',
      icon,
      disabled = false,
      type = 'button',
      id,
      onClick,
      variant = 'primary',
      iconPosition = 'left',
      width = 'fit',
      font = 'canal',
      className,
      'data-testid': dataTestId,
      renderWrapper,
      role,
      ...a11y
    },
    ref,
  ): JSX.Element {
    const { device } = useDiveContext();
    const hasStringChildren = typeof children === 'string';
    const hasIconAndLabel = !!icon && !!children;

    const wrapperStyles = buttonCVA({
      variant,
      disabled,
      width,
      device,
      hasIconAndLabel,
      iconPosition,
      className,
    });
    const iconStyles = buttonIconCVA({
      variant,
      device,
      disabled,
      className: icon?.props?.className, // Conserve the original className of the icon
    });

    const labelStyles = buttonLabelCVA({
      variant,
      disabled,
      font,
      device,
      isLongLabel:
        hasStringChildren && children.length >= LABEL_LONG_LENGTH_THRESHOLD,
      className: !hasStringChildren ? children.props.className : undefined,
    });

    const buttonContent = (
      <>
        {icon &&
          cloneElement(icon, {
            className: iconStyles,
            'aria-hidden': hasIconAndLabel,
          })}
        {hasStringChildren ? (
          <span className={labelStyles}>{children}</span>
        ) : (
          cloneElement(children, { className: labelStyles })
        )}
      </>
    );

    if (renderWrapper) {
      // eslint-disable-next-line react-compiler/react-compiler
      return renderWrapper({
        id,
        ref,
        onClick,
        children: buttonContent,
        disabled,
        className: wrapperStyles,
        ...a11y,
      });
    }

    return (
      <button
        className={wrapperStyles}
        id={id}
        data-testid={dataTestId}
        type={type}
        disabled={disabled}
        aria-disabled={disabled}
        onClick={onClick}
        ref={ref as ForwardedRef<HTMLButtonElement>}
        role={role}
        {...a11y}
      >
        {buttonContent}
      </button>
    );
  },
);
