import { cva } from 'class-variance-authority';

export const listCVA = cva(
  [
    'font-dt-font-family-system font-dt-font-weight-regular',
    'py-dt-spacing-none',
    'space-x-dt-spacing-200',
  ],
  {
    variants: {
      device: {
        tv: '',
        web: ['rounded-dt-radius-medium', 'px-dt-spacing-200'],
      },
    },
  },
);

export const listTitlesContainerCVA = cva('', {
  variants: {
    device: { tv: 'py-dt-spacing-150', web: 'py-dt-spacing-175' },
    layout: {
      inline: 'flex-row items-baseline',
      stacked: 'flex-col items-start',
    },
  },
  compoundVariants: [
    {
      device: 'tv',
      layout: 'inline',
      className: 'space-x-dt-spacing-200',
    },
    {
      device: 'tv',
      layout: 'stacked',
      className: 'space-y-dt-spacing-100',
    },
    {
      device: 'web',
      layout: 'inline',
      className: 'space-y-dt-spacing-none space-x-dt-spacing-100',
    },
    {
      device: 'web',
      layout: 'stacked',
      className: 'space-y-dt-spacing-100 space-x-dt-spacing-none',
    },
  ],
});

export const listTitleCVA = cva('', {
  variants: {
    device: {
      tv: 'text-dt-font-size-30 leading-dt-font-line-height-36',
      web: 'text-dt-font-size-18 leading-dt-font-line-height-22',
    },
  },
});

export const listLabelsContainerCVA = cva('flex flex-row-reverse items-center');

export const listLabelCVA = cva('', {
  variants: {
    device: {
      tv: '',
      web: '',
    },
    disabled: { true: '', false: '' },
    hasLineThrough: { true: '', false: '' },
  },
  compoundVariants: [
    {
      hasLineThrough: false,
      device: 'web',
      disabled: false,
      className: 'text-dt-theme-text-list-row-list-row-label',
    },
    {
      hasLineThrough: false,
      device: 'web',
      disabled: true,
      className: 'text-dt-theme-text-list-row-list-row-label-disabled',
    },
    {
      hasLineThrough: true,
      device: 'web',
      disabled: false,
      className: 'text-dt-theme-text-list-row-list-row-price',
    },
    {
      hasLineThrough: true,
      device: 'web',
      disabled: true,
      className: 'text-dt-theme-text-list-row-list-row-price-disabled',
    },
  ],
});

export const listSublabelCVA = cva('text-start', {
  variants: {
    device: {
      tv: 'mr-dt-spacing-200',
      web: [
        'mr-dt-spacing-100',
        'text-dt-font-size-16 leading-dt-font-line-height-20',
      ],
    },
  },
});

export const listChevronIconCVA = cva('', {
  variants: {
    device: {
      tv: '',
      web: '',
    },
    disabled: { true: '', false: '' },
    hasLineThrough: { true: '', false: '' },
  },
  compoundVariants: [
    {
      hasLineThrough: false,
      device: 'web',
      disabled: false,
      className: 'fill-dt-theme-icon-list-row-chevron',
    },
    {
      hasLineThrough: false,
      device: 'web',
      disabled: true,
      className: 'fill-dt-theme-icon-list-row-chevron-disabled',
    },
    {
      hasLineThrough: true,
      device: 'web',
      disabled: false,
      className: 'fill-dt-theme-icon-list-row-chevron-price',
    },
    {
      hasLineThrough: true,
      device: 'web',
      disabled: true,
      className: 'fill-dt-theme-icon-list-row-chevron-price-disabled',
    },
  ],
});

export const listCheckIconCVA = cva('h-full, w-full', {
  variants: {
    device: {
      tv: 'fill-dt-theme-tv-icon-list-icon tv-focus-parent:fill-dt-theme-tv-icon-list-icon-focus',
      web: 'fill-dt-theme-icon-list-row-check-icon-accent',
    },
  },
});
