import { DidomiSDK } from '@didomi/react';
import { type JSX } from 'react';
import { getPublicConfig } from '../../../helpers/config/config-helper';
import { useInvariantSelector } from '../../../helpers/hooks/useInvariantSelector';
import {
  didomiNoticeIdSelector,
  getFeatureToggleDidomi,
} from '../../../store/slices/application-selectors';
import {
  hasDidomiConsentChanged,
  hasDidomiLoaded,
  setDidomiConsent,
} from './DidomiProvider/didomiActions';
import { useDidomiDispatch } from './DidomiProvider/didomiHooks';
import { useDidomi } from '../hooks/useDidomi';

function Didomi({
  isTrackingScriptLoaded,
}: {
  isTrackingScriptLoaded: boolean;
}): JSX.Element | null {
  const didomiDispatch = useDidomiDispatch();
  const { DIDOMI } = getPublicConfig();
  const noticeId = useInvariantSelector(didomiNoticeIdSelector);
  const isFeatDidomi = useInvariantSelector(getFeatureToggleDidomi);

  // For TV UseDidomi hook is needed to check if the user has already given his consent
  // and Redirect to didomi-standalone page if consent is not given
  // For Web UseDidomi hook is used to manage the consent
  useDidomi({
    isTrackingScriptLoaded,
    enabled: isFeatDidomi,
    // Todo: Remove hardcoded noticeId when the noticeId is available in BO
    noticeId: $_BUILD_RENDERMODE_CSR ? 'T3LLUWmj' : noticeId,
  });

  // For TV We don't need DIDOMI SDK used only for Web
  return !$_BUILD_RENDERMODE_CSR ? (
    <DidomiSDK
      apiKey={DIDOMI?.API_PUBLIC_KEY}
      noticeId={noticeId}
      onReady={() => {
        didomiDispatch(hasDidomiLoaded(true));
      }}
      onConsentChanged={() => {
        didomiDispatch(hasDidomiConsentChanged(true));
        didomiDispatch(setDidomiConsent(window.Didomi.getCurrentUserStatus()));
      }}
    />
  ) : null;
}

export default Didomi;
