import { getContentsWithContext } from '../helpers/contents/contents-helper';
import { rewriteObjectUrls } from '../helpers/proxyUrls/proxyUrls';
import { getFormattedContentGrid } from '../templates/ContentGrid/data/formater';
import { getFormattedContentGridHighlights } from '../templates/ContentGridHighlights/data/formatter';
import getFormattedContentRowLiveData from '../templates/ContentRowLive/data/formatter';
import getFormattedContentRowPerso from '../templates/ContentRowPerso/data/formater';
import formatContentRowData from '../templates/ContentRowStandard/data/formater';
import { getCreativeMediaFormatted } from '../templates/CreativeMedia/data/formater';
import { getDetailLightFormatted } from '../templates/DetailLight/data/formater';
import type { InitialDataEpisodes } from '../templates/DetailV5/components/EpisodesList/EpisodesList.types';
import getFormattedMoreInfos from '../templates/DetailV5/components/MoreInfos/data/formatter';
import { getDetailV5Formatted } from '../templates/DetailV5/data/formater';
import { getFormattedEPGGrid } from '../templates/EPGGrid/data/formater';
import getFormattedError from '../templates/Error/data/formatter';
import getFormattedGabaritList from '../templates/GabaritList/data/formater';
import { getFormattedLandingV5 } from '../templates/LandingV5/data/formatter';
import getFormattedLiveGrid from '../templates/LiveGrid/data/formatter';
import { getFormattedLiveTvFavorites } from '../templates/LiveGridFavorites/data/formater';
import getFormattedMosaic from '../templates/Mosaic/data/formatter';
import getFormattedMultiLive from '../templates/MultiLiveSetup/data/formatter';
import getFormaterMyAccountData from '../templates/MyAccountIdp/data/formater';
import { getSearchFormatted } from '../templates/Search/data/formater';
import getFormattedSearchStandalone from '../templates/SearchStandalone/data/formatter';
import getFormattedSectionsList from '../templates/SectionsList/data/formatter';
import { getShowcaseDataFormatted } from '../templates/Showcase/data/formater';
import getFormattedStub from '../templates/Stub/data/formatter';
import type {
  FormatterResult,
  GenericOutput,
  UniversalFormatterProps,
} from './types';
import { FetchRequestTypes } from './types';

/**
 * ## universalFormatter
 * Universal formatter which contains all formatters needed for the useQueryTemplate hook
 * @param hodorResponse It contains Hodor response (with json inside), formatterProps and the template
 * @param formatterProps It contains the formatterProps
 * @param template It contains the current template
 * @example universalFormatter<T>({ data: response, formatterProps: formatterProps || {}, template })
 */
export async function universalFormatter<T extends GenericOutput>({
  hodorResponse,
  formatterProps,
  template,
}: UniversalFormatterProps): Promise<FormatterResult<T>> {
  let result: FormatterResult<GenericOutput>;

  // Switch case is used instead of mapping due to some tricky cases
  // Where we need multiple steps to return the formatted value

  switch (template) {
    case FetchRequestTypes.ContentRowLive:
      result = getFormattedContentRowLiveData(
        hodorResponse,
        formatterProps?.trackingContext,
      );
      break;
    case FetchRequestTypes.ContentRowPerso:
      result = getFormattedContentRowLiveData(hodorResponse);
      break;
    case FetchRequestTypes.ContentRowPersoContainer:
      result = getFormattedContentRowPerso(
        hodorResponse,
        formatterProps?.trackingContext,
      );
      break;
    case FetchRequestTypes.ContentGridPerso:
      result = {
        ...hodorResponse,
        contents: getContentsWithContext(
          hodorResponse.contents,
          hodorResponse.context,
        ),
      };
      break;
    case FetchRequestTypes.DownloadToGo:
      result = {
        currentPage: hodorResponse.currentPage,
        tracking: hodorResponse.tracking,
      };
      break;
    case FetchRequestTypes.EPG:
      result = {
        type: hodorResponse.type,
        timeSlices: hodorResponse.timeSlices,
        rubriques: hodorResponse.rubriques,
      };
      break;
    case FetchRequestTypes.EPGGrid:
      result = getFormattedEPGGrid(
        hodorResponse,
        formatterProps?.blackListedChannels,
      );
      break;
    case FetchRequestTypes.Error:
      result = getFormattedError(hodorResponse);
      break;
    case FetchRequestTypes.GabaritList:
      result = getFormattedGabaritList(
        hodorResponse,
        !!formatterProps?.isLiveGridFavoritesEnabled,
      );
      break;
    case FetchRequestTypes.LandingV5:
      result = getFormattedLandingV5(
        hodorResponse,
        !!formatterProps?.featIdentityV5,
        !!formatterProps?.isIOS,
      );
      break;
    case FetchRequestTypes.LiveGrid:
      result = getFormattedLiveGrid(hodorResponse);
      break;
    case FetchRequestTypes.SectionsList:
      result = getFormattedSectionsList(hodorResponse);
      break;
    case FetchRequestTypes.SearchStandalone:
      result = getFormattedSearchStandalone(hodorResponse);
      break;
    case FetchRequestTypes.MultiLive:
      result = getFormattedMultiLive(hodorResponse);
      break;
    case FetchRequestTypes.Mosaic:
      result = getFormattedMosaic(hodorResponse);
      break;
    case FetchRequestTypes.MoreInfos:
      result = getFormattedMoreInfos(hodorResponse);
      break;
    case FetchRequestTypes.LiveGridFavoritesContainer:
    case FetchRequestTypes.LiveGridFavorites:
      result = getFormattedLiveTvFavorites(hodorResponse);
      break;
    case FetchRequestTypes.Stub:
      result = getFormattedStub(hodorResponse);
      break;
    case FetchRequestTypes.ContentGrid:
      result = getFormattedContentGrid(
        hodorResponse,
        formatterProps?.dispatch,
        !!formatterProps?.featIdentityV5,
      );
      break;
    case FetchRequestTypes.ContentRowStandard:
      result = formatContentRowData(hodorResponse);
      break;
    case FetchRequestTypes.MyAccountIdp:
      result = getFormaterMyAccountData(hodorResponse);
      break;
    case FetchRequestTypes.EpisodeContainer:
      result = {
        episodes: hodorResponse.episodes,
      } as InitialDataEpisodes;
      break;
    case FetchRequestTypes.SearchContainer:
      result = getSearchFormatted(hodorResponse);
      break;
    case FetchRequestTypes.ShowcaseContainer:
      result = getShowcaseDataFormatted(hodorResponse);
      break;
    case FetchRequestTypes.DetailV5:
      result = getDetailV5Formatted({
        data: hodorResponse,
        isFeatDetailTabHighlights: !!formatterProps?.isFeatDetailTabHighlights,
        isFeatDetailTabRating: !!formatterProps?.isFeatDetailTabRating,
        isFeatDetailTabStatistics: !!formatterProps?.isFeatDetailTabStatistics,
        isFeatDetailTabRanking: !!formatterProps?.isFeatDetailTabRanking,
        isFeatDetailTabTimeline: !!formatterProps?.isFeatDetailTabTimeline,
        isFeatShortVideoList: !!formatterProps?.isFeatShortVideoList,
      });
      break;
    case FetchRequestTypes.DetailLight:
      result = getDetailLightFormatted(hodorResponse);
      break;
    case FetchRequestTypes.CreativeMedia:
      result = getCreativeMediaFormatted(hodorResponse);
      break;
    case FetchRequestTypes.Highlights:
      result = getFormattedContentGridHighlights(hodorResponse);
      break;
    default:
      result = { ...hodorResponse, tracking: hodorResponse.tracking };
  }

  if (!('currentPage' in result)) {
    result = { ...result, currentPage: hodorResponse.currentPage };
  }

  if (!('meta' in result)) {
    result = { ...result, meta: hodorResponse.meta };
  }

  return rewriteObjectUrls(result) as FormatterResult<T>;
}
