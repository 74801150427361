import { cva } from 'class-variance-authority';

export const headerListCVA = cva(
  [
    'flex items-center px-dt-spacing-100 pb-dt-spacing-100 dt-breakpoint-sm-start:pt-dt-spacing-100',
    'list-none m-dt-spacing-none', // ! tailwind-base-styles reset UL
  ],
  {
    variants: {
      device: {
        tv: undefined,
        web: 'dt-breakpoint-md-start:space-x-dt-spacing-200',
      },
      isMeasurable: {
        true: 'absolute invisible z-0',
        false: undefined,
      },
      isHScrollable: {
        true: 'overflow-x-auto overflow-y-hidden no-scrollbar scroll-smooth',
        false: undefined,
      },
    },
  },
);

export const headerNavCVA = cva('relative w-full box-border', {
  variants: {
    hasOverflowGradients: {
      true: [
        "after:content-[''] after:dt-breakpoint-md-start:content-none",
        'after:bg-gradient-to-l after:from-dt-theme-gradient-header-nav-right-gradient-gradient-start after:to-dt-theme-gradient-header-nav-right-gradient-gradient-end',
        'after:w-16 after:block after:absolute after:inset-y-0 after:right-0 after:pointer-events-none after:z-10',
        "before:content-[''] before:dt-breakpoint-md-start:content-none",
        'before:bg-gradient-to-r before:from-dt-theme-gradient-header-nav-left-gradient-gradient-start before:to-dt-theme-gradient-header-nav-left-gradient-gradient-end',
        'before:w-16 before:block before:absolute before:inset-y-0 before:left-0 before:pointer-events-none before:z-10',
      ],
      false: undefined,
    },
  },
});
