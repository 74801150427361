import { AlertStatus } from '@dce-front/dive';
import { castToEnum } from '@dce-front/onewebapp-utils';
import type { MouseEvent, ReactNode, SyntheticEvent } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ThemeColor } from '../../constants/themeColor';
import { TrackingEventName } from '../../constants/tracking';
import {
  saveNotificationNextDisplayDate,
  type NotificationParams,
} from '../../helpers/notification/notification-helper';
import type { NotificationTrackingEventName } from '../../helpers/tracking/tracking-helper';
import { sendTrackingHitOnNotification } from '../../helpers/tracking/tracking-helper';

import { useInvariantSelector } from '../../helpers/hooks/useInvariantSelector';
import {
  isStartupNotificationOpenSelector,
  offerLocationSelector,
} from '../../store/slices/application-selectors';
import { pageURLSelector } from '../../store/slices/page-selectors';
import {
  accountIdSelector,
  isKidsProfileSelector,
} from '../../store/slices/user-selectors';
import { useAlerting } from '../Application/hooks/useAlerting';
import useLinker from '../Linker/hooks/useLinker';
import SlideDown from '../SlideDown/SlideDown';
import Alert from './Alert';
import AlertTv from './AlertTv';

export default function AlertNotificationContainer(): ReactNode {
  const accountId = useSelector(accountIdSelector);
  const isKids = useSelector(isKidsProfileSelector);
  const localeInfo = useInvariantSelector(offerLocationSelector);
  const pageURL = useSelector(pageURLSelector) || '';
  const isStartupNotificationOpen = useSelector(
    isStartupNotificationOpenSelector,
  );
  const { alert, currentPage, tracking } = useAlerting() || {};
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);

  const sendNotificationTracking = useCallback(
    (eventName: NotificationTrackingEventName) => {
      if (tracking?.dataLayer) {
        const { alert_segment_id: segmentId, alert_state: state } =
          tracking.dataLayer;
        sendTrackingHitOnNotification({
          eventName,
          isKids,
          themeColor: ThemeColor.Dark,
          alertType: state,
          segType: segmentId,
          version: $_BUILD_APP_VERSION,
          pageURL,
          offerLocation: localeInfo,
          tracking,
        });
      }
    },
    [isKids, localeInfo, pageURL, tracking],
  );

  useEffect(() => {
    if (alert && !isAlertOpen && !isStartupNotificationOpen) {
      setIsAlertOpen(true);
      sendNotificationTracking(TrackingEventName.ViewAlert);
    }
  }, [alert, isAlertOpen, sendNotificationTracking, isStartupNotificationOpen]);

  const handleClose = useCallback(() => {
    if (alert && currentPage && accountId !== undefined) {
      setIsAlertOpen(false);
      saveNotificationNextDisplayDate({
        displayTemplate:
          currentPage.displayTemplate as NotificationParams['displayTemplate'],
        contentID: alert.contentID,
        accountId,
        daysBetweenTriggers: alert?.triggers?.daysBetweenTriggers,
      });
      sendNotificationTracking(TrackingEventName.CloseAlert);
    }
  }, [alert, currentPage, accountId, sendNotificationTracking]);

  const { getLinkerSettings } = useLinker();
  const { onClick } = useMemo(
    () =>
      getLinkerSettings({
        data: { mainOnClick: alert?.button?.onClick },
        target: alert?.button?.onClick?.target,
      }),
    [alert, getLinkerSettings],
  );

  const handleCTAClick = useCallback(
    (e: MouseEvent<HTMLAnchorElement | HTMLButtonElement> | SyntheticEvent) => {
      onClick?.(e);
      handleClose();
    },
    [onClick, handleClose],
  );

  /* Update the CSS variable --size_alert_height
  to dynamically adjust the padding-top of the content
  based on the alert's height and the header's height */
  const slidedownRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const slidedown = slidedownRef.current;
    if (!isAlertOpen || !slidedown || $_BUILD_RENDERMODE_CSR) {
      return;
    }
    const root = document.documentElement;
    const observer = new ResizeObserver(() => {
      const alertHeight = slidedown.clientHeight;
      root.style.setProperty('--size_alert_height', `${alertHeight}px`);
    });
    observer.observe(slidedown);
    return () => {
      observer.disconnect();
      root.style.setProperty('--size_alert_height', `0px`);
    };
  }, [isAlertOpen]);

  if (!alert) {
    return null;
  }

  return !$_BUILD_RENDERMODE_CSR ? (
    <SlideDown ref={slidedownRef} isOpen={isAlertOpen}>
      <Alert
        status={castToEnum(AlertStatus)(alert.state)}
        title={alert.title}
        message={alert.description || ''}
        closable={alert.isClosable}
        onClose={handleClose}
        onClick={handleCTAClick}
        buttonLabel={alert.button?.label}
        buttonAriaLabel={alert.button?.ariaLabel}
      />
    </SlideDown>
  ) : (
    <AlertTv
      alert={alert}
      isOpen={isAlertOpen}
      handleClick={handleCTAClick}
      handleClose={handleClose}
    />
  );
}
