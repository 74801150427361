export const LAYER_PAGE = 0;
export const LAYER_IMMERSIVE = 1;
export const LAYER_IMMERSIVE_MOREINFOS = 2;
export const LAYER_IMMERSIVE_MOOD = 3;
export const LAYER_SETTINGS_MANAGE_PROFILES = 4;

export const LAYER_VOD_FUNNEL = 8;
export const LAYER_ADULT_KEYBOARD = 9;
export const LAYER_USER_MENU_SECTIONS_MODAL = 10;
export const LAYER_SETTINGS = 11;

export const LAYER_LIVEGRID_MODAL = 15;
export const LAYER_SLIDESHOW_POPIN = 17;
export const LAYER_WHOISWATCHING = 19;
export const LAYER_MAX_PROFILES_REACHED = 20;
export const LAYER_EXTERNAL_SERVICE_MODAL = 21;
export const LAYER_CALLBACK_MODAL = 22;
export const LAYER_EMBEDDED_VIDEO_FULL_FRAME = 25;
export const LAYER_GDPR_POPIN = 26;
export const LAYER_PRIVACY_MANAGER = 27; // must be higher than LAYER_GDPR_POPIN
export const LAYER_PRIVACY_MANAGER_SETTING = 28; // must be higher than LAYER_PRIVACY_MANAGER
export const LAYER_DIALOG_MODAL = 29; // Must be higher than LAYER_PRIVACY_MANAGER_SETTING

export const LAYER_VISUAL_DEBUG = 42;
export const LAYER_VISUAL_DEBUG_LOGS = 43;
