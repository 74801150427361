import type { RefObject } from 'react';
import { useEffect } from 'react';

export const useOnClickOutside = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T | null>,
  handler: (event: Event) => void,
  listen: boolean = true,
): void => {
  useEffect(() => {
    if (listen) {
      const listener = (event: Event) => {
        const el = ref?.current;
        // Do nothing if clicking ref element or child elements
        if (!el || el.contains((event?.target as Node) || null)) {
          return;
        }
        handler(event);
      };
      document.addEventListener(`click`, listener);
      return () => {
        document.removeEventListener(`click`, listener);
      };
    }
    return;
  }, [ref, handler, listen]);
};
