import { getEncodedQueryString } from '@dce-front/onewebapp-utils';
import type { PassSdkConfig } from '../config/types';
import { paths } from '../constants';
import { getSigninUrlScope } from './private/getSigninUrlScope';

export type GetSigninUrlParameters = {
  /** Fulfill **redirect_uri** parameter */
  redirectUri: string;

  /** Used to compute **scope** parameter */
  scope?: 'parental' | 'purchase' | 'delete';
};

/**
 * Get the Pass authentication URL\
 * ⚠️ To be used on web applications, not on TV
 */
export const getSigninUrl = (
  {
    baseUrl: { oauth: oauthBaseUrl },
    clientId,
    language,
    media,
    offerLocation,
    offerZone,
    portailId,
  }: PassSdkConfig,
  { redirectUri, scope }: GetSigninUrlParameters,
): string => {
  const url = `${oauthBaseUrl}${paths.login}`;

  const computedScope = getSigninUrlScope(scope);

  const queryParameters = {
    appLocation: offerLocation,
    client_id: clientId,
    media,
    offerZone,
    portailId,
    redirect_uri: redirectUri,
    response_type: 'code',
    scope: computedScope,
    state: `origref=${redirectUri}`,
    ...(language && { language }),
  };

  const encodedQueryParameters = getEncodedQueryString(queryParameters);

  return `${url}${encodedQueryParameters}`;
};

/**
 * Get the authentication page URL
 *
 * @alias
 * @deprecated Use {@link getSigninUrl} instead
 */
export const getSigninLink = getSigninUrl;
