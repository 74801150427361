import classNames from 'classnames';
import type {
  AriaAttributes,
  ForwardedRef,
  HTMLAttributes,
  JSX,
  ReactElement,
} from 'react';
import { cloneElement, forwardRef } from 'react';
import type { HeaderUserDropdownListItem as HeaderUserDropdownListItemType } from '../HeaderUserDropdown/HeaderUserDropdown.types';

type HeaderUserDropdownListItemProps = {
  /**
   * Is the item focused
   */
  isFocused?: boolean;
} & HeaderUserDropdownListItemType &
  AriaAttributes;

/**
 * HeaderUserDropdownListItem component to render a dropdown list item with proper accessibility attributes.
 *
 * @example
 * <HeaderUserDropdownListItem label="Settings" href="/settings" icon={<SettingsIcon />} />
 */
const HeaderUserDropdownListItem = forwardRef<
  HTMLDivElement | HTMLButtonElement | HTMLAnchorElement,
  Partial<HeaderUserDropdownListItemProps>
>(function HeaderUserDropdownListItem(
  {
    label,
    id,
    href,
    wrapper,
    icon,
    isFocused,
    ...props
  }: Partial<HeaderUserDropdownListItemProps>,
  ref: ForwardedRef<HTMLDivElement | HTMLButtonElement | HTMLAnchorElement>,
): JSX.Element {
  const style = classNames(
    'group flex items-center cursor-pointer no-underline box-border hover:outline-none',
    'font-dt-font-family-system font-dt-font-weight-regular',
    'text-dt-font-size-16 leading-dt-font-line-height-20',
    'h-32 px-dt-spacing-200 space-x-dt-spacing-100 w-full',
    'text-dt-theme-text-list-row-list-title transition-colors duration-200',
    'hover:bg-dt-theme-surface-card-tab-card-tab-hover focus:bg-dt-theme-surface-card-tab-card-tab-hover',
    {
      'bg-dt-theme-surface-card-tab-card-tab-hover': isFocused,
    },
  );

  const listItemIcon = (iconItem: ReactElement<HTMLAttributes<HTMLElement>>) =>
    cloneElement(iconItem, {
      className: classNames(
        'h-26 basis-26 grow-0 shrink-0 transition-colors duration-200',
      ),
    });

  const listItemChildren = (
    <>
      {icon && listItemIcon(icon)}
      <span className="text-dt-font-size-16 leading-dt-font-line-height-20">
        {label}
      </span>
    </>
  );

  if (wrapper) {
    return cloneElement(
      wrapper,
      // eslint-disable-next-line react-compiler/react-compiler
      {
        id,
        className: style,
        ref,
        ...props,
      },
      listItemChildren,
    );
  }

  return (
    <a
      ref={ref as ForwardedRef<HTMLAnchorElement>}
      href={href}
      id={id}
      className={style}
      {...props}
    >
      {listItemChildren}
    </a>
  );
});

export default HeaderUserDropdownListItem;
