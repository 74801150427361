import { getAllLocaleZones } from '@canalplus/sdk-core';
import { DisplayMode } from '@dce-front/onewebapp-utils';
import type { JSX } from 'react';
import { memo, useMemo, useRef } from 'react';
import type { Public } from '../../../config/application/types';
import IconFrance from '../../assets/svg/france.svg';
import IconMaurice from '../../assets/svg/maurice.svg';
import IconPologne from '../../assets/svg/pologne.svg';
import IconSuisse from '../../assets/svg/suisse.svg';
import { Country } from '../../constants/country';
import { ModalTypes } from '../../constants/modalTypes';
import { getPublicConfig } from '../../helpers/config/config-helper';
import { useAppDispatch } from '../../helpers/hooks/useAppDispatch';
import { useInvariantSelector } from '../../helpers/hooks/useInvariantSelector';
import {
  langKeySelector,
  offerLocationSelector,
} from '../../store/slices/application-selectors';
import { openModal } from '../../store/slices/modal';
import { setDisplayMode } from '../../store/slices/ui';
import styles from './ZoneSelector.css';

function ZoneSelector(): JSX.Element {
  const dispatch = useAppDispatch();
  const offerLocation = useInvariantSelector(offerLocationSelector);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const language = useInvariantSelector(langKeySelector);

  const onSelectLang = () => {
    dispatch(setDisplayMode(DisplayMode.MODAL));
    dispatch(
      openModal({
        modalType: ModalTypes.ZONES_LIST_MODAL,
        clickedElement: buttonRef.current,
      }),
    );
  };

  const config: Public = getPublicConfig();
  const selectedZone = getAllLocaleZones(config.zones)[offerLocation];

  const currentZone = useMemo(
    () =>
      `${selectedZone?.[language]?.country || selectedZone?.default.country}`,
    [offerLocation], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const currentFlag = useMemo(
    () => `${selectedZone?.default.picto}`,
    [offerLocation], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const icon = useMemo(() => {
    switch (currentFlag) {
      case Country.PL:
        return <IconPologne />;
      case Country.MU:
        return <IconMaurice />;
      case Country.FR:
        return <IconFrance />;
      case Country.CH:
        return <IconSuisse />;
      default:
        return null;
    }
  }, [currentFlag]);

  return (
    <button
      ref={buttonRef}
      onClick={onSelectLang}
      className={styles.zoneSelector}
      type="button"
    >
      <span className={styles.zoneSelector__country}>{currentZone}</span>
      {currentFlag && <div className={styles.zoneSelector__flag}>{icon}</div>}
    </button>
  );
}

export default memo(ZoneSelector);
