import type { KEYCODE } from '@dce-front/onewebapp-utils';
import { isClientSide } from '@dce-front/onewebapp-utils';
import { useCallback, useEffect } from 'react';

export type UseKeyboardEventOptions = {
  keycode: KEYCODE;
  listen: boolean;
  containerRef?: HTMLElement | null;
  triggerAction?: (event?: KeyboardEvent) => void;
};

/**
 * Catch keyboard `keydown` event to trigger a callback\
 * Use `addEventListener`
 */
export const useKeyboardEvent = (options: UseKeyboardEventOptions): void => {
  const { keycode, listen, containerRef, triggerAction } = options;

  const handleKeydown = useCallback(
    (event: KeyboardEvent) => {
      if (event.which === keycode) {
        event.stopPropagation();
        triggerAction?.();
      }
    },
    [triggerAction, keycode],
  );

  useEffect(() => {
    if (!isClientSide() || !listen || !triggerAction) {
      return;
    }

    (containerRef || document.body).addEventListener('keydown', handleKeydown);

    return () =>
      (containerRef || document.body).removeEventListener(
        'keydown',
        handleKeydown,
      );
  }, [listen, handleKeydown]); // eslint-disable-line react-hooks/exhaustive-deps
};
