import type { JSX } from 'react';
import SkipLink from '../../SkipLink/SkipLink';
import { useAreAnimationsAutoplay } from '../../Video/hooks/useAreAnimationsAutoplay';
import styles from '../Application.css';

export function SkipLinkToggleAutoplay(): JSX.Element {
  const { isAutoplayEnabled, toggleAnimationsAndTrailerAutoplay } =
    useAreAnimationsAutoplay();

  return (
    <SkipLink
      onClick={toggleAnimationsAndTrailerAutoplay}
      label={
        isAutoplayEnabled
          ? 'disableAnimationsAutoplay'
          : 'enableAnimationsAutoplay'
      }
      className={styles['application--skip-link']}
    />
  );
}
