import type {
  Middleware,
  MiddlewareFactory,
  MiddlewareHooks,
} from '../store/types';

/**
 * Enter middleware options
 */
export type EnterOptions = {
  /**
   * The index of the element to force focus on enter
   */
  forceFocusIndex: number;
  /**
   * Pass to true to force focus only on first enter
   */
  shouldForceFocusOnce?: boolean;
};

/**
 * Instanciate a new enter middleware that forces focus to a given element by index on enter
 *
 * @param options Options to instanciate this middleware
 *
 *  @example
 *  const MIDDLEWARE = [enter({ forceFocusIndex: 3, forceFocusOnce: true }), spacial()];
 *
 *  function MyComponent() {
 *    return (
 *      <Binder middleware={MIDDLEWARE}>
 *        <button>hello</button>
 *      </Binder>
 *    );
 *  }
 *
 * @returns Enter middleware factory
 */
export const enter: Middleware<EnterOptions> = ({
  forceFocusIndex,
  shouldForceFocusOnce,
}): MiddlewareFactory => {
  return (binder): MiddlewareHooks => {
    let hasFocusedOnce = false;
    return {
      focusEnter: () => {
        if (!hasFocusedOnce || !shouldForceFocusOnce) {
          hasFocusedOnce = true;
          return binder.getElements()[forceFocusIndex];
        }

        return undefined;
      },
    };
  };
};
