import type { JSX } from 'react';
import { SKIP_LINK_ID } from '../../SkipLink/constants';
import SkipLink from '../../SkipLink/SkipLink';
import styles from '../Application.css';
import { SkipLinkToggleAutoplay } from './SkipLinkToggleAutoplay';

function ApplicationSkipLinksWeb(): JSX.Element {
  return (
    <>
      <SkipLinkToggleAutoplay />
      <SkipLink
        href={`#${SKIP_LINK_ID.appMainContent}`}
        label="skipToMainContent"
        className={styles['application--skip-link']}
      />
      <SkipLink
        href={`#${SKIP_LINK_ID.appHeaderSearch}`}
        label="skipToSearch"
        className={styles['application--skip-link']}
      />
    </>
  );
}

function ApplicationSkipLinksTv(): JSX.Element | null {
  return null;
}

export const ApplicationSkipLinks = $_BUILD_RENDERMODE_CSR
  ? ApplicationSkipLinksTv
  : ApplicationSkipLinksWeb;
