import { Template } from '@canalplus/sdk-hodor';
import type { ApiV2PageStrate } from '@dce-front/hodor-types/api/v2/page/dtos/definitions';
import type { ApiV2Cover } from '@dce-front/hodor-types/api/v2/page/dtos/strates/cover/definitions';
import type { ApiV2CoverContent } from '@dce-front/hodor-types/api/v2/page/dtos/strates/cover/manual/definitions';
import { isSomeEnum } from '@dce-front/onewebapp-utils';
import type { CoverData } from '../../../../components/IdentityCover/types';
import { getLocationStateContext } from '../../../../helpers/contents/contents-helper';

const isTemplate = isSomeEnum(Template);

const isApiV2Cover = (object: any): object is ApiV2Cover[] =>
  !!object?.[0]?.contents;

const isApiV2CoverContentDeprecated = (
  object: any,
): object is ApiV2CoverContent =>
  !!object?.label ||
  !!object?.onClick ||
  !!object?.URLImage ||
  !!object?.URLImageRegular;

const isApiV2CoverContent = (object: any): object is ApiV2CoverContent =>
  !!object?.label ||
  !!object?.onClick ||
  !!object?.URLImageOptimizedCompact ||
  !!object?.URLImageOptimizedRegular ||
  !!object?.URLLogoType ||
  !!object?.URLLogoChannel;

const getFormattedIdentityCover = (
  covers?: ApiV2PageStrate[],
  featIdentityV5: boolean = false,
): CoverData | undefined => {
  if (!isApiV2Cover(covers)) {
    return;
  }

  if (covers[0]?.displayMode === 'deprecated' || !featIdentityV5) {
    if (!isApiV2CoverContentDeprecated(covers[0]?.contents?.[0])) {
      return;
    }

    const cover = covers[0]?.contents?.[0];
    const { label, onClick, URLImage, URLImageRegular } = cover || {};

    return {
      image: URLImage || URLImageRegular || '',
      label,
      ...(onClick && {
        ...(isTemplate(onClick.displayTemplate) && {
          context: getLocationStateContext(onClick.displayTemplate),
        }),
        onClick: {
          ...onClick,
        },
      }),
    };
  }

  if (!isApiV2CoverContent(covers?.[0]?.contents?.[0])) {
    return;
  }

  const cover = covers?.[0]?.contents?.[0];
  const {
    label,
    onClick,
    URLImageOptimizedCompact,
    URLImageOptimizedRegular,
    altImageOptimizedCompact,
    altImageOptimizedRegular,
    URLLogoType,
    altLogoType,
    URLLogoChannel,
    altLogoChannel,
    description,
  } = cover || {};

  return {
    imageMobile: URLImageOptimizedCompact ?? '',
    image: URLImageOptimizedRegular ?? '',
    altMobile: altImageOptimizedCompact ?? '',
    alt: altImageOptimizedRegular ?? '',
    description,
    urlLogoType: URLLogoType,
    altLogoType,
    urlLogoChannel: URLLogoChannel,
    altLogoChannel,
    label,
    ...(onClick && {
      ...(isTemplate(onClick.displayTemplate) && {
        context: getLocationStateContext(onClick.displayTemplate),
      }),
      onClick: {
        ...onClick,
      },
    }),
  };
};

export default getFormattedIdentityCover;
