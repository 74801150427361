import type { Timeout } from '../../../config/application/types';
import { getPublicConfig } from '../config/config-helper';

/**
 * Convert milliseconds into seconds
 *
 * @param ms Milliseconds value to convert into seconds
 * @returns Value in seconds, defaults to 0 if `ms` is not provided
 */
export const msToSeconds = (ms: number | string = 0): number => {
  const numberMs = typeof ms === 'number' ? ms : parseInt(ms, 10);
  return (!!numberMs && numberMs / 1000) || 0;
};

/**
 * Retrieve the timeout value from the public configuration.
 *
 * @default 250ms
 * @param key Key of the timeout value to retrieve
 * @returns Timeout value
 */
export const getPublicTimeout = (key?: Timeout): number => {
  return getPublicConfig()?.timeout?.[key || '250ms'];
};
