import { PassPlatform } from '@canalplus/sdk-core';
import { fillTemplate } from '@dce-front/onewebapp-utils';
import { useSelector } from 'react-redux';
import { useInvariantSelector } from '../../../helpers/hooks/useInvariantSelector';
import { getAvatarUrl } from '../../../helpers/url/url-helper';
import { FetchRequestTypes } from '../../../services/types';
import {
  appKeySelector,
  featAvatarV2Selector,
  hodorSdkConfigSelector,
  offerLocationSelector,
} from '../../../store/slices/application-selectors';

type AvatarContext = {
  avatarUrl: string;
  avatarTemplate: FetchRequestTypes;
  isAvatarV2: boolean;
};

/**
 * A hook that consists of exposing the right avatar context (legacy or v2) depending on featToggle
 * @example
 * If avatar feature toggle is not enabled, returns { avatarUrl: 'https://hodor.canalplus.pro/api/v2/mycanal/PC/{portailId}/avatars', avatarTemplate: 'Avatar', isAvatarV2: false }
 * If avatar feature toggle is enabled and hodor returns avatars url, returns { avatarUrl: 'https://hodor.canalplus.pro/api/...', avatarTemplate: 'AvatarV2', isAvatarV2: true }
 * useAvatarContext();
 */
export function useAvatarContext(): AvatarContext {
  const isAvatarV2 = useSelector(featAvatarV2Selector);

  const appKey = useInvariantSelector(appKeySelector);
  const offerLocation = useInvariantSelector(offerLocationSelector);
  const hodorSdkConfig = useSelector(hodorSdkConfigSelector);

  const avatarsV2Url = hodorSdkConfig?.urls?.avatars;

  if (isAvatarV2 && avatarsV2Url) {
    return {
      avatarUrl: fillTemplate(avatarsV2Url, [['offerLocation', offerLocation]]),
      avatarTemplate: FetchRequestTypes.AvatarV2,
      isAvatarV2,
    };
  }

  return {
    avatarUrl: getAvatarUrl(PassPlatform.Web, appKey),
    avatarTemplate: FetchRequestTypes.Avatar,
    isAvatarV2,
  };
}
