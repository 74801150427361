import type { JSX } from 'react';
import { useSelector } from 'react-redux';
import { useInvariantSelector } from '../../helpers/hooks/useInvariantSelector';
import {
  applicationFooterTreeSelector,
  footerDescriptionSelector,
  footerDescriptionTitleSelector,
  getFeatSocialNetworksFooter,
  getFeatureToggleLocaleSwitcherSelector,
  langKeySelector,
  socialMediaSelector,
} from '../../store/slices/application-selectors';
import FooterContainer from './FooterContainer';
import { getFilledLabels } from './getFilledLabels';

export function FooterContainerConnected(): JSX.Element {
  const isFeatSocialNetworksFooter = useSelector(getFeatSocialNetworksFooter);
  const isLocaleSwitcher = useInvariantSelector(
    getFeatureToggleLocaleSwitcherSelector,
  );
  const socialMedia = useSelector(socialMediaSelector);
  const tree = useSelector(applicationFooterTreeSelector);

  const lang = useSelector(langKeySelector);
  const footerDescription = useSelector(footerDescriptionSelector);
  const footerDescriptionTitle = useSelector(footerDescriptionTitleSelector);
  const labels = getFilledLabels(
    lang,
    footerDescriptionTitle,
    footerDescription,
  );

  return (
    <FooterContainer
      isFeatSocialNetworksFooter={isFeatSocialNetworksFooter}
      isLocaleSwitcher={isLocaleSwitcher}
      labels={labels}
      socialMedia={socialMedia}
      tree={tree}
    />
  );
}
