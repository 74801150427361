import {
  getThumborSources,
  getThumborUrl,
  MediaImage,
  THUMBOR_QUALITY_TO_DPR,
  type DeviceToImageUrl,
  type DiveViewportsWithDevice,
  type MediaImageProps,
  type ThumborSourcesDimensions,
} from '@dce-front/dive';
import {
  getDefaultViewports,
  getDimensionsFromResizeMode,
} from '@dce-front/onewebapp-dive-utils';
import { getDevicePixelRatio } from '@dce-front/onewebapp-utils';
import type { JSX } from 'react';
import { useSelector } from 'react-redux';
import { useInvariantSelector } from '../../helpers/hooks/useInvariantSelector';
import {
  applicationResizeSelector,
  imageQualityPercentageSelector,
  maxImageRatioSelector,
} from '../../store/slices/application-selectors';
import { imageQualityPercentageBySettingsSelector } from '../../store/slices/user-selectors';

export type ThumborMediaImageProps = {
  /**
   * The default image URL to request to Thumbor
   */
  url?: DeviceToImageUrl['default'];
  /**
   * The mobile-device-specific image URL to request to Thumbor
   */
  urlMobile?: DeviceToImageUrl['mobile'];
  /**
   * The image dimensions to request to Thumbor
   */
  dimensions: ThumborSourcesDimensions;
  /**
   * When not on TV device, whether the image only needs **desktop**-specific `<source>` media-queries.
   *
   * Useful for generating a lighter DOM.
   */
  isDesktopOnly?: boolean;
  /**
   * When not on TV device, whether the image only needs **mobile**-specific `<source>` media-queries.
   *
   * Useful for generating a lighter DOM.
   */
  isMobileOnly?: boolean;
  /**
   * Component test id
   */
  'data-testid'?: string;
  /**
   * Viewports to use
   *
   * If is undefined, it uses a default viewports :
   *  - `VIEWPORTS.ASCENDING` if `isMobileOnly` and `isDesktopOnly` are false
   *  - `VIEWPORTS.DESKTOP_ASCENDING` if `isDesktopOnly` is true
   *  - `VIEWPORTS.MOBILE_ASCENDING` if `isMobileOnly` is true
   */
  viewports?: DiveViewportsWithDevice;
} & Omit<MediaImageProps, 'src' | 'sources'>;

/**
 * Displays an image based on given `url` and `dimensions` using both:
 *
 * - Canal Plus' Thumbor image server, with myCanal-specific image settings
 * - DIVE's `MediaImage` component
 *
 * @see {@link https://canal-wiki.canal-plus.com/display/PE/Thumbor Thumbor server docs}
 * @see {@link https://deddev.gitlab-pages.canalplus.pro/dedmycanalwebapp/dive/?path=/docs/components-media-mediaimage--docs DIVE MediaImage docs}
 */
export default function ThumborMediaImage({
  url,
  urlMobile,
  dimensions,
  'data-testid': dataTestId,
  isDesktopOnly,
  isMobileOnly,
  viewports,
  ...rest
}: ThumborMediaImageProps): JSX.Element | null {
  const resizeMode = useInvariantSelector(applicationResizeSelector);
  const maxImageRatio = useSelector(maxImageRatioSelector);
  const qualitySettings = useInvariantSelector(imageQualityPercentageSelector);
  const qualityUserSettings = useSelector(
    imageQualityPercentageBySettingsSelector,
  );

  if (!url) {
    return null;
  }

  const quality = qualityUserSettings || qualitySettings;

  const devicePixelRatio = Math.min(
    THUMBOR_QUALITY_TO_DPR[quality],
    maxImageRatio ?? getDevicePixelRatio(),
  );

  /**
   * The urls returned from myCanal's back-end already include a thumbor-compatible format:
   * Thumbor server url (https://thumb.canalplus.pro/) + Thumbor-allowed source url
   */
  const includesAllowedSourceInUrl = true;

  const thumborUrl = getThumborUrl({
    url,
    dimensions: getDimensionsFromResizeMode(
      dimensions,
      $_BUILD_RENDERMODE_CSR,
      resizeMode,
    ),
    quality,
    devicePixelRatio,
    includesAllowedSourceInUrl,
  });

  const sources = !$_BUILD_RENDERMODE_CSR
    ? getThumborSources({
        urls: { default: url, ...(urlMobile && { mobile: urlMobile }) },
        dimensions,
        quality,
        devicePixelRatio,
        viewports:
          viewports || getDefaultViewports(isDesktopOnly, isMobileOnly),
        includesAllowedSourceInUrl,
      })
    : undefined;

  return (
    <MediaImage
      src={
        $_BUILD_RENDERMODE_CSR && thumborUrl.urlRetina
          ? thumborUrl.urlRetina
          : thumborUrl.url
      }
      sources={sources}
      data-testid={dataTestId}
      {...rest}
    />
  );
}
