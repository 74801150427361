import type { AcmSdkConfig } from '@canalplus/sdk-acm';
import type { HttpsProxyAgent } from 'https-proxy-agent';
import { getPublicConfig } from '../../helpers/config/config-helper';
import Logger from '../../helpers/logger/logger-helper';
import {
  appKeySelector,
  offerLocationSelector,
  offerZoneSelector,
} from '../../store/slices/application-selectors';
import type { IState } from '../../store/types/State-type';

type GetAcmSdkConfigParameters = {
  state: IState;
  proxyAgent?: HttpsProxyAgent<string>;
};

export const getAcmSdkConfig = ({
  state,
  proxyAgent,
}: GetAcmSdkConfigParameters): AcmSdkConfig => {
  const { endpoint } = getPublicConfig().api.acm;

  const appKeyLog = appKeySelector(state);
  const offerLocation = offerLocationSelector(state);
  const offerZone = offerZoneSelector(state);

  return {
    appKeyLog,
    endpoint,
    logger: Logger,
    offerLocation,
    offerZone,
    ...(proxyAgent && { fetchOptions: { agent: proxyAgent } }),
  };
};
