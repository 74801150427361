export type ListenerKey = string | number;
export type Listener<T> = (arg: T) => void;

/**
 * Simple event emitter class
 * @TODO one-navigation: maybe base the store callbacks API on this
 */
export class EventEmitter<Arg = void> {
  /**
   * Record of event listeners
   * @private
   */
  listeners: Record<ListenerKey, Listener<Arg>[]> = {};

  /**
   * Returns an array of listeners for a given key. Creates a new array if needed
   * @param key Event key
   * @returns Array of listeners
   */
  getListeners(key: ListenerKey): Listener<Arg>[] {
    if (!this.listeners[key]) {
      this.listeners[key] = [];
    }

    return this.listeners[key];
  }

  /**
   * Add a listener for given event key
   * @param key Event key
   * @param listener Listener callback
   */
  addListener(key: ListenerKey, listener: Listener<Arg>): void {
    this.getListeners(key).push(listener);
  }

  /**
   * Remove a listener for a given key
   * @param key Event key
   * @param listener listener callback
   */
  removeListener(key: ListenerKey, listener: Listener<Arg>): void {
    const listeners = this.getListeners(key);
    const index = listeners.indexOf(listener);
    if (index > -1) {
      listeners.splice(index, 1);
    }
  }

  /**
   * Emit an event for given key
   * @param key Event key
   * @param arg Callback arguments
   */
  emit(key: ListenerKey, arg: Arg): void {
    const listeners = this.getListeners(key);

    for (let i = 0; i < listeners.length; i += 1) {
      listeners?.[i]?.(arg);
    }
  }
}
