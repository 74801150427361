import { Template } from '@canalplus/sdk-hodor';
import {
  ButtonVariant,
  HeaderDropdownSeparator,
  HeaderUserDropdownButton,
} from '@dce-front/dive';
import type { ApiV2PageStrateButton } from '@dce-front/hodor-types/api/v2/page/dtos/strates/button';
import { isSomeEnum } from '@dce-front/onewebapp-utils';
import type { JSX } from 'react';
import { Fragment } from 'react';
import { useLogout } from '../../../helpers/pass/useLogout';
import { useSigninRedirect } from '../../../helpers/pass/useSigninRedirect';
import Linker from '../../Linker/Linker';
import type { LinkerSettings } from '../../Linker/types';

type HeaderProfileButtonStrateProps = { isLastStrate?: boolean } & Pick<
  ApiV2PageStrateButton,
  'title' | 'subtitle' | 'contents'
>;

function HeaderProfileButtonStrate({
  isLastStrate = false,
  title,
  subtitle,
  contents = [],
}: HeaderProfileButtonStrateProps): JSX.Element[] {
  const handleClick = useSigninRedirect();
  const logout = useLogout();

  return contents?.map(({ style, label = '', onClick }) => {
    const variant = isSomeEnum(ButtonVariant)(style)
      ? style
      : ButtonVariant.Primary;
    switch (onClick?.displayTemplate) {
      case Template.Authentication:
        return (
          <Fragment key={`headerProfile-strate-button-auth-${label}`}>
            <HeaderUserDropdownButton
              label={label}
              onClick={() => handleClick()}
              variant={variant}
              title={title}
              subtitle={subtitle}
            />
            {!isLastStrate && <HeaderDropdownSeparator />}
          </Fragment>
        );

      case Template.Logout:
        return (
          <HeaderUserDropdownButton
            key={`headerProfile-strate-button-logout-${label}`}
            label={label}
            onClick={logout}
            variant={variant}
          />
        );

      default:
        return (
          <Linker
            key={`headerProfile-strate-button-default-${label}`}
            data={{ mainOnClick: onClick }}
            renderWrapper={(linkerSettings: LinkerSettings) => (
              <HeaderUserDropdownButton
                label={label}
                variant={variant}
                {...(linkerSettings as Required<LinkerSettings>)}
              />
            )}
          />
        );
    }
  });
}

export default HeaderProfileButtonStrate;
