import classNames from 'classnames';
import type { JSX } from 'react';
import IconChevronLeft from '../../../assets/svg/chevronLeft.svg';
import IconChevronRight from '../../../assets/svg/chevronRight.svg';
import styles from './HorizontalListPageButton.module.css';

export type HorizontalListPageButtonProps = {
  title: string;
  className?: string; // use this to override some styles, such as position
  onClick?: () => void; // don't pass this if you're passing this button as a prop to HorizontalList - it will be overridden
  type: 'previous' | 'next';
};

export function HorizontalListPageButton({
  className = '',
  onClick,
  title,
  type,
}: HorizontalListPageButtonProps): JSX.Element {
  return (
    <button
      title={title}
      className={classNames(styles.HorizontalListPageButton, {
        [className]: !!className,
      })}
      onClick={onClick}
      type="button"
    >
      {type === 'previous' ? <IconChevronLeft /> : <IconChevronRight />}
    </button>
  );
}
