import { Composite } from '@floating-ui/react';
import type { JSX } from 'react';
import { useRef } from 'react';
import { useDiveContext } from '../../../context';
import { DiveBreakpoint } from '../../../types/Dive.types';
import { HeaderNavDropdown } from '../HeaderNavDropdown/HeaderNavDropdown';
import HeaderNavItem from '../HeaderNavItem/HeaderNavItem';
import { headerListCVA, headerNavCVA } from './HeaderNav.cva';
import type { HeaderNavProps } from './HeaderNav.types';
import { useOverflowDropdownItems } from './useOverflowDropdownItems';
import { useScrollIntoView } from './useScrollIntoView';

const isSSR = typeof window === 'undefined';

/**
 * Horizontal list of navigation items to be used in the header. It can contain a mix of `HeaderNavItem` and `HeaderNavDropdown` depending on the variant.
 *
 * It can be used in two variants:
 * - **Priority**: Items that don't fit in the header will be moved to a dropdown (desktop and tablet only)
 * - **Scroll** Items are horizontally scrollable (default mobile behavior)
 *
 * > **Note:** It uses `@floating-ui/react` library internally for accessibility and positioning management,
 * by defining a 'menubar' role on the list and 'menuitem' role on the items.
 *
 * ### Accessibility:
 * - **ARIA:** `role="menubar"` on the `<ul />` and `role="menuitem"` on the items.
 * - **Tab and Shift+Tab:** `<ul />` is considered a single tab stop. Only the menubar itself is tabbable. The items are accessible via arrow keys.
 * - **Navigation keys:** ArrowKeys (`Up`, `Down`, `Left`, `Right`) to navigate between items inside.
 * - **Press:** (`Enter` or `Space`) to select/click an item.
 *
 * @example
 *
 * ```tsx
 * <HeaderNav
 *    items={[.... as HeaderNavItem[]]}
 *    activeIndex={0} // first item is active
 *    dropdownLabel="More"
 * />
 * ```
 */
export function HeaderNav({
  items,
  activeIndex = -1,
  id,
  variant = 'priority',
  shouldCenterActiveItem = true,
  dropdownLabel = 'More',
  isTransparent = false,
  className,
  'data-testid': dataTestId,
  ...a11y
}: HeaderNavProps): JSX.Element {
  const { isTv, device } = useDiveContext();

  const isPriority = variant === 'priority';
  const isMobile = !isSSR && window.innerWidth < DiveBreakpoint.Sm;
  const hasDropdown = isPriority && !isTv && !isMobile;
  const hasOverflowGradients = !isTransparent && !isTv && !hasDropdown;

  const fullNavRef = useRef<HTMLElement>(null); // <nav>
  const measurableRef = useRef<HTMLUListElement>(null); // invisible <ul> with all items for measuring
  const visibleNavRef = useRef<HTMLUListElement>(null); // visible <ul> with items that fit in the nav
  const dropdownRef = useRef<HTMLLIElement>(null); // <li> with dropdown

  const { overflowingItems, visibleItems } = useOverflowDropdownItems({
    containerRef: fullNavRef,
    measurableRef,
    dropdownRef,
    items,
    enabled: hasDropdown,
  });

  // Hook to scroll the active item into view
  useScrollIntoView({
    containerRef: visibleNavRef,
    index: activeIndex,
    enabled: isMobile && !isTv && shouldCenterActiveItem,
  });

  /**
   * Indices of the items that are in the overflow dropdown. Disabled in the Composite component,
   * to avoid them being focusable or navigable horizontally (Bug).
   */
  const isDropdownInvisible = !(overflowingItems.length > 0);
  const overflowIndices = Array.from(
    { length: overflowingItems.length },
    (_, i) => i + visibleItems.length + 1,
  );
  const dropdownIndex = [visibleItems.length];
  const disabledIndices = isDropdownInvisible ? dropdownIndex : overflowIndices;

  return (
    <nav
      id={id}
      ref={fullNavRef}
      data-testid={dataTestId}
      className={headerNavCVA({ hasOverflowGradients, className })}
      role="navigation"
      {...a11y}
    >
      {/* Measurable Menu (hidden): Purely used as unit of measure, where it always contains all the items.
          This is used to determine which items should be visible and which should be in the dropdown in the useEffect.
          This element should remain immutable and not be affected by any state changes, unless the items prop itself changes
          at higher level.
      */}
      {isPriority && !isTv && !isMobile && (
        <ul
          ref={measurableRef}
          className={headerListCVA({ device, isMeasurable: true })}
          aria-hidden
          tabIndex={-1}
        >
          {items?.map((item) => (
            <li key={`hidden-${item.id || item.label}`}>
              <HeaderNavItem
                label={item.label}
                href="#"
                wrapper={item.wrapper}
                icon={item.icon}
              />
            </li>
          ))}
        </ul>
      )}

      {/* Real Menu (menubar) */}
      <Composite
        loop={!isTv}
        aria-label={a11y?.['aria-label']}
        className={headerListCVA({ device, isHScrollable: true })}
        orientation="horizontal"
        ref={visibleNavRef}
        render={<ul />}
        role="menubar"
        disabledIndices={hasDropdown ? disabledIndices : undefined}
      >
        {visibleItems?.map((item, i) => (
          <li key={item.id || item.label} role="none">
            <HeaderNavItem
              label={item.label}
              id={item.id}
              href={item.href}
              wrapper={item.wrapper}
              icon={item.icon}
              isCurrent={i === activeIndex}
            />
          </li>
        ))}
        {hasDropdown && (
          <li
            role="none"
            ref={dropdownRef}
            {...(overflowingItems.length < 1 && {
              'aria-hidden': true,
              className: 'invisible absolute left-0', // Only for measuring DOM dropdown width
            })}
          >
            <HeaderNavDropdown
              label={dropdownLabel}
              items={overflowingItems}
              currentIndex={activeIndex - visibleItems.length}
            />
          </li>
        )}
      </Composite>
    </nav>
  );
}
