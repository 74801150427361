import {
  addCharacter as addCharacterHelper,
  deleteCharacter as deleteCharacterHelper,
} from './helpers';
import type {
  ActionAddCharacter,
  ActionDecrement,
  ActionDeleteCharacter,
  ActionIncrement,
  ActionUpdateMode,
  Mode,
  ActionSetInputValue,
} from './types';
import { ActionType } from './types';

export const updateMode = (mode: Mode): ActionUpdateMode => ({
  type: ActionType.UPDATE_MODE,
  mode,
});

export const addCharacter = (
  id: string,
  inputValue: string[],
  cursorIndex: number,
  mode: Mode,
  isPolish: boolean,
): ActionAddCharacter => ({
  type: ActionType.ADD_CHAR,
  inputValue: addCharacterHelper(id, inputValue, cursorIndex, mode, isPolish),
});

export const deleteCharacter = (
  inputValue: string[],
  cursorIndex: number,
): ActionDeleteCharacter => ({
  type: ActionType.DELETE_CHAR,
  inputValue: deleteCharacterHelper(inputValue, cursorIndex),
});

export const setInputValue = (inputValue: string[]): ActionSetInputValue => ({
  type: ActionType.SET_INPUT_VALUE,
  inputValue,
});

export const dispatchIncrement: ActionIncrement = {
  type: ActionType.INCREMENT,
};

export const dispatchDecrement: ActionDecrement = {
  type: ActionType.DECREMENT,
};
