import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import {
  deleteQueryString,
  getProductionHostname,
  getUrlWithoutDefaultLang,
} from '../../helpers/url/url-helper';
import { pathnameSelector } from '../../store/slices/routing-selectors';
import { useHostnameLink } from './hooks/useHostnameLink';

// TODO write unit tests

/**
 * Update canonical url when canonical property is provided by meta from template
 * @param meta
 * @returns string
 */
export function CanonicalLinkTags({
  metaCanonical,
}: {
  metaCanonical?: string;
}) {
  const pathname = useSelector(pathnameSelector) || '';
  const hostname = useHostnameLink();

  const canonicalUrl = `https://${getProductionHostname(hostname)}${
    metaCanonical ?? getUrlWithoutDefaultLang(deleteQueryString(pathname))
  }`;

  return <Helmet link={[{ rel: 'canonical', href: canonicalUrl }]} />;
}
