import type { CSSProperties, JSX, ReactNode } from 'react';
import { useEffect, useRef } from 'react';
import { useIsomorphicLayoutEffect } from 'usehooks-ts';
import { useLayerId } from '../../hooks/useLayerId';
import { useStore } from '../../hooks/useStore';
import type { StoreBinder } from '../../store/StoreBinder';
import type { MiddlewareFactory } from '../../store/types';

export type BinderProps = {
  children: ReactNode;
  selector?: string;
  enabled?: boolean;
  middleware?: MiddlewareFactory[];
  layer?: number;
  binderId?: string;
  className?: string;
  style?: CSSProperties;
  [key: `data-${string}`]: string;
  forceFocusOnMount?: boolean;
};

/**
 * Group of focusable elements
 *
 * @param children Binder children
 * @param selector Selector used to query focusable elements. Warning : changing the selector
 *  in a way that cause the currently selected element to be not focusable will not unfocus it.
 *  Selector should not be changed dynamically.
 *
 *  Defaults to `a[href], button:enabled`
 * @param middleware Custom focus middleware. Defaults to `[spatial()]`
 * @param enabled If passed to `false`, the binder becomes unfocusable. Defaults to `true`
 * @param layer Layer of the binder. Defaults to `0`
 * @param binderId Id of the binder
 * @param data You can pass arbitrary `data-*` attributes
 * @param className Regular className attribute
 * @param style Regular style attribute
 * @param forceFocusOnMount /!\ Use it with caution /!\ it force the focus of this binder in 'focusDefault' function
 * @returns A JSX Element
 */
export function Binder({
  children,
  selector,
  enabled = true,
  layer: propsLayer,
  binderId,
  middleware,
  forceFocusOnMount,
  ...props
}: BinderProps): JSX.Element {
  const ref = useRef<HTMLDivElement | null>(null);
  const binderRef = useRef<StoreBinder | null>(null);
  const store = useStore();
  const contextLayerId = useLayerId();
  const layer = typeof propsLayer !== 'undefined' ? propsLayer : contextLayerId;

  // "evergreen" hook that keeps the store binder updated when something changes
  useEffect(() => {
    const binder = binderRef.current;

    if (binder) {
      if (selector) {
        binder.selector = selector;
      }

      if (middleware) {
        binder.setMiddleware(middleware);
      }

      if (binder.enabled !== enabled) {
        store.getLayer(binder.layerId).setBinderEnabled(binder, enabled);
      }

      if (binder.layerId !== layer) {
        console.warn(
          'OneNavigation: changing layer of a binder after initialization is not supported and is currently a no-op.',
        );
      }
    }
  }, [middleware, selector, enabled, layer, store]);

  // Mount / Unmount hook should only be triggered by store
  // Register as fast as possible
  // Trigger before paint to allow binder to be added before all useEffects in children
  useIsomorphicLayoutEffect(() => {
    // Not really testable under testing library
    /* istanbul ignore if  */
    if (!ref.current) {
      return;
    }

    const binder = store.getLayer(layer).addBinder({
      el: ref.current,
      middleware,
      enabled,
      layer,
      forceFocusOnMount,
      binderId,
      // Update binder selector only if defined else use store selector
      ...(selector && { selector }),
    });

    if (forceFocusOnMount) {
      store.getLayer(layer).focusDefault();
    }

    binderRef.current = binder;

    return () => {
      store.getLayer(binder.layerId).removeBinder(binder);
    };
  }, [ref, store]);

  return (
    <div ref={ref} data-binder={binderId || 'true'} {...props}>
      {children}
    </div>
  );
}
