import type { ForwardedRef, JSX } from 'react';
import { forwardRef } from 'react';
import type { DiListProps } from './DiList.types';

/**
 * A flexible `DiList` component with three customizable slots:
 *
 * - `prefix`: Renders before the main content.
 * - `children`: The main content.
 * - `suffix`: Renders after the main content.
 */
export const DiList = forwardRef<
  HTMLButtonElement | HTMLDivElement,
  DiListProps
>(function DiList(
  {
    prefix,
    children,
    suffix,
    as = 'button',
    disabled = false,
    ...props
  }: DiListProps,
  ref,
): JSX.Element {
  const content = (
    <>
      {prefix}
      {children}
      {suffix}
    </>
  );

  if (as === 'button') {
    return (
      <button
        ref={ref as ForwardedRef<HTMLButtonElement>}
        type="button"
        disabled={disabled}
        aria-disabled={disabled}
        {...props}
      >
        {content}
      </button>
    );
  }

  return (
    <div ref={ref as ForwardedRef<HTMLDivElement>} {...props}>
      {content}
    </div>
  );
});
