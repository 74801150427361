import { getProfile, type OneCoreProfile } from '@canalplus/ifc-onecore';
import { fillTemplate } from '@dce-front/onewebapp-utils';
import { getPublicConfig } from '../../../helpers/config/config-helper';
import Logger from '../../../helpers/logger/logger-helper';

export const processDidomiConsent = async ({
  noticeId,
}: {
  noticeId?: string;
}) => {
  const userProfile: OneCoreProfile = await getProfile();
  if (
    userProfile?.isAuthenticated &&
    (userProfile.askForConsent || !localStorage.getItem('euconsent-v2'))
  ) {
    handleConsentRedirection({ noticeId });
  }
};

export const handleConsentRedirection = ({
  noticeId,
}: {
  noticeId?: string;
}) => {
  const publicConfig = getPublicConfig();
  const didomiURL = publicConfig?.DIDOMI?.DIDOMI_STANDALONE_URL;
  const publicKey = publicConfig?.DIDOMI?.API_PUBLIC_KEY;
  const redirectUri = `${window.location.origin}${window.location.pathname}`;

  if (!didomiURL || !noticeId || !publicKey) {
    Logger.error('[RedirectionHandler] Didomi URL is not defined.');
    return;
  }

  const didomiRedirectURL = fillTemplate(didomiURL, [
    ['noticeId', noticeId],
    ['publicKey', publicKey],
    ['redirectUri', redirectUri],
  ]);

  Logger.info(
    `[RedirectionHandler] Redirecting to Didomi standalone page: ${didomiRedirectURL}`,
  );
  window.location.href = didomiRedirectURL;
};
