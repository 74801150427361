import { cva } from 'class-variance-authority';

export const listSharedCVA = cva(
  ['w-full items-center flex flex-row', 'border-solid box-border'],
  {
    variants: {
      device: {
        tv: [
          'rounded-dt-radius-xlarge',
          'px-dt-spacing-300',
          'tv-focus-self:scale-tv-focus',
          'tv-focus-self:bg-dt-theme-tv-surface-list-list-focus',
          'border-dt-border-regular',
          'tv-focus-self:border-dt-theme-tv-surface-list-list-focus',
        ],
        web: ['border-dt-border-thin border-dt-theme-border-list-row-list-row'],
      },
      disabled: {
        false: 'cursor-pointer',
      },
    },
    compoundVariants: [
      {
        device: 'tv',
        disabled: true,
        className: 'bg-transparent border-dt-theme-tv-border-list-list',
      },
      {
        device: 'tv',
        disabled: false,
        className:
          'bg-dt-theme-tv-surface-list-list border-dt-theme-tv-surface-list-list',
      },
      {
        device: 'web',
        disabled: false,
        className: 'bg-dt-theme-surface-list-row-list-row',
      },
      {
        device: 'web',
        disabled: true,
        className: 'bg-dt-theme-surface-list-row-list-row-disabled',
      },
    ],
  },
);

export const listSharedLeftIconCVA = cva('size-32 min-w-32 min-h-32');

export const listSharedTitlesContainerCVA = cva('flex flex-1');

export const listSharedTitleCVA = cva(['line-clamp-2 text-start'], {
  variants: {
    device: {
      tv: [
        'text-dt-theme-tv-text-list-list-row-title-default tv-focus-parent:text-dt-theme-tv-text-list-list-row-title-focus',
        'text-dt-font-size-30 leading-dt-font-line-height-36',
      ],
      web: 'text-dt-font-size-18 leading-dt-font-line-height-22',
    },
    disabled: {
      false: undefined,
      true: undefined,
    },
  },
  compoundVariants: [
    {
      device: 'web',
      disabled: false,
      className: 'text-dt-theme-text-list-row-list-title',
    },
    {
      device: 'web',
      disabled: true,
      className: 'text-dt-theme-text-list-row-list-row-title-disabled',
    },
  ],
});

export const listSharedSubtitleCVA = cva(
  ['text-start font-dt-font-family-system line-clamp-1'],
  {
    variants: {
      device: {
        tv: [
          'tv-focus-parent:text-dt-theme-tv-text-list-list-row-subtitle-focus text-dt-theme-tv-text-list-list-row-subtitle-default',
          'text-dt-font-size-30 leading-dt-font-line-height-36',
        ],
        web: 'text-dt-font-size-16 leading-dt-font-line-height-20',
      },
      disabled: {
        false: undefined,
        true: undefined,
      },
    },
    compoundVariants: [
      {
        device: 'web',
        disabled: false,
        className: 'text-dt-theme-text-list-row-list-row-subtitle',
      },
      {
        device: 'web',
        disabled: true,
        className: 'text-dt-theme-text-list-row-list-row-subtitle-disabled',
      },
    ],
  },
);

export const listSharedLabelCVA = cva('font-dt-font-family-system', {
  variants: {
    device: {
      tv: [
        'tv-focus-parent:text-dt-theme-tv-text-list-list-row-label-focus',
        'text-dt-theme-tv-text-list-list-row-label-default',
        'text-dt-font-size-30 leading-dt-font-line-height-36',
      ],
      web: 'text-dt-font-size-16 leading-dt-font-line-height-20',
    },
  },
});

export const listSharedSublabelCVA = cva('font-dt-font-family-system', {
  variants: {
    device: {
      tv: [
        'tv-focus-parent:text-dt-theme-tv-text-list-list-row-promotion-focus text-dt-theme-tv-text-list-list-row-promotion-default',
        'text-dt-font-size-30 leading-dt-font-line-height-36',
      ],
      web: '',
    },
    disabled: {
      false: '',
      true: '',
    },
  },
  compoundVariants: [
    {
      device: 'web',
      disabled: false,
      className: 'text-dt-theme-text-list-row-list-row-promotion',
    },
    {
      device: 'web',
      disabled: true,
      className: 'text-dt-theme-text-list-row-list-row-promotion-disabled',
    },
  ],
});

export const listSharedRightElementCVA = cva('flex items-center relative', {
  variants: {
    device: {
      tv: 'min-w-50 h-50 w-min',
      web: 'min-w-24 h-24 w-min',
    },
  },
});

export const listSharedChevronIconCVA = cva('w-full h-full', {
  variants: {
    device: {
      tv: 'tv-focus-parent:fill-dt-theme-tv-icon-list-icon-focus fill-dt-theme-tv-icon-list-icon',
      web: '',
    },
  },
});
