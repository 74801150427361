import { updateUserConsent, type PassUserData } from '@canalplus/sdk-pass';
import { usePassSdk } from '@canalplus/sdk-pass-react';
import { isClientSide } from '@dce-front/onewebapp-utils';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import { useAppDispatch } from '../../../helpers/hooks/useAppDispatch';
import Logger from '../../../helpers/logger/logger-helper';
import { getClientSideUserInfos } from '../../../helpers/user/getClientSideUserInfos';
import { shouldUsePassCache } from '../../../helpers/user/user-helper';
import { pathnameSelector } from '../../../store/slices/routing-selectors';
import { setTargetedAds } from '../../../store/slices/user';
import { authenticatedSelector } from '../../../store/slices/user-selectors';
import type { IState } from '../../../store/types/State-type';
import {
  setDidomiConsent,
  setDidomIsInIframe,
} from '../Didomi/DidomiProvider/didomiActions';
import {
  useDidomiAdvertisingConsent,
  useDidomiAnalyticsConsent,
  useDidomiConsent,
  useDidomiDispatch,
  useDidomiHasConsentChanged,
  useDidomiHasLoaded,
  useDidomiIsInIframe,
} from '../Didomi/DidomiProvider/didomiHooks';
import { processDidomiConsent } from '../Didomi/didomiTvHelpers';

/**
 * Custom hook to manage Didomi consent.
 *
 * This hook centralizes the effect handling related to Didomi consent management, optimizing for performance
 * by minimizing the number of re-renders and side effects.
 */

type UseDidomiWebParams = {
  enabled: boolean;
  isTrackingScriptLoaded: boolean;
};

const useDidomiWeb = ({
  enabled,
  isTrackingScriptLoaded,
}: UseDidomiWebParams): void => {
  const state = useStore<IState>().getState();
  const dispatch = useAppDispatch();
  const updateUserConsentApi = usePassSdk(updateUserConsent);
  const isAuthenticated = useSelector(authenticatedSelector);
  const pathname = useSelector(pathnameSelector);
  const hasWaitForPassJSON =
    typeof window !== 'undefined' &&
    typeof window.waitForPassJSON === 'function';

  // Didomi state and dispatch from custom hooks
  const hasAdvertisingConsent = useDidomiAdvertisingConsent();
  const hasAnalyticsConsent = useDidomiAnalyticsConsent();
  const hasDidomiLoaded = useDidomiHasLoaded();
  const hasConsentChanged = useDidomiHasConsentChanged();
  const didomiConsent = useDidomiConsent();
  const didomiDispatch = useDidomiDispatch();
  const isDidomiInIframe = useDidomiIsInIframe();
  const [anonymousMeasure, setAnonymousMeasure] = useState<boolean>(true);

  useEffect(() => {
    if (!(enabled && hasDidomiLoaded && isClientSide())) {
      return;
    }

    const updateUserConsentsAndReload = (
      userConsents: Pick<
        PassUserData,
        'trackingPub' | 'analytics' | 'anonymousTracking'
      >,
    ) => {
      updateUserConsentApi({
        passToken: window.passJSON?.passToken || '',
        settings: userConsents,
      })
        .then(() => {
          getClientSideUserInfos(state, { noCache: !shouldUsePassCache() });
          window.Didomi?.notice.hide();
          // Ensure reload happens only after API call is successful
          setTimeout(() => {
            window.location.reload();
          }, 0);
        })
        .catch((e) => Logger.error(`Pass::updateProfile Error: ${e}`));
    };

    document.addEventListener('userChangedConsent', (event: CustomEvent) => {
      if (
        event?.detail?.anonymousAllowed === false &&
        window.passJSON?.anonymousTracking !== false
      ) {
        setAnonymousMeasure(false);
      }
    });

    // Set tracking as anonymous if the tracking query param is set to anonymous and didomi is in iframe
    const query = new URLSearchParams(window.location.search);
    if (query.get('tracking') === 'anonymous') {
      didomiDispatch(setDidomIsInIframe(true));
      window.ttSetUserConsent({
        statsAllowed: false,
        adsAllowed: false,
        anonymousAllowed: true,
      });
    }

    const handleDidomiConsentUpdates = () => {
      // Update Didomi consents object in the store in case of page refresh or consent change
      if (
        !window.Didomi?.shouldUserStatusBeCollected() &&
        !didomiConsent?.purposes
      ) {
        didomiDispatch(setDidomiConsent(window.Didomi?.getCurrentUserStatus()));
      }

      if (
        isAuthenticated &&
        !hasConsentChanged &&
        !anonymousMeasure &&
        hasAdvertisingConsent !== undefined
      ) {
        updateUserConsentsAndReload({
          analytics: false,
          trackingPub: hasAdvertisingConsent,
          anonymousTracking: false,
        });
        return;
      }

      if (
        hasConsentChanged &&
        isTrackingScriptLoaded &&
        hasAnalyticsConsent !== undefined &&
        hasAdvertisingConsent !== undefined
      ) {
        const userConsents = {
          analytics: hasAnalyticsConsent,
          trackingPub: hasAdvertisingConsent,
          anonymousTracking: true,
        };

        window.setAnonymousMeasure(true);
        window.setAdsConsent(hasAdvertisingConsent);
        window.setStatsConsent(hasAnalyticsConsent);

        window.sendPopinDisplayEvent(hasAnalyticsConsent, hasAnalyticsConsent);
        window.sendPopinInteractionEvent(
          hasAnalyticsConsent,
          hasAdvertisingConsent,
        );

        if (isAuthenticated) {
          updateUserConsentsAndReload(userConsents);
        } else {
          getClientSideUserInfos(state, {
            noCache: !shouldUsePassCache(),
            ...userConsents,
          });
          // Ensure reload happens only after API call is successful
          setTimeout(() => {
            window.location.reload();
          }, 0);
        }
      }
    };

    handleDidomiConsentUpdates();
  }, [
    didomiDispatch,
    updateUserConsentApi,
    anonymousMeasure,
    didomiConsent,
    hasConsentChanged,
    hasDidomiLoaded,
    isAuthenticated,
    isTrackingScriptLoaded,
    enabled,
    hasAnalyticsConsent,
    hasAdvertisingConsent,
    isDidomiInIframe,
    state,
  ]);

  useEffect(() => {
    if (!(hasWaitForPassJSON && enabled && isClientSide())) {
      return;
    }

    const onPassJSON = async () => {
      if (pathname === '/cookies/' || isDidomiInIframe) {
        window.Didomi?.notice?.hide();
        return;
      }

      if (
        (isAuthenticated &&
          hasDidomiLoaded &&
          window.passJSON?.askForConsent &&
          !window.Didomi?.notice?.isVisible() &&
          !isDidomiInIframe) ||
        (hasDidomiLoaded && !Cookies.get('euconsent-v2') && !isDidomiInIframe)
      ) {
        window.Didomi?.reset();
        window.Didomi?.notice?.show();
      }

      // This is the case where the user has already given his consent and we need to update targetedAds in the store
      if (
        (hasDidomiLoaded && hasAdvertisingConsent) ||
        (!hasDidomiLoaded && window.passJSON?.trackingPub)
      ) {
        // will send this to player
        // Update the user.targetedAds in the store when GDPR consent is confirmed
        dispatch(setTargetedAds('1'));
      }
    };

    window.waitForPassJSON?.(onPassJSON);

    return () => {
      window.unsubscribeWaitForPassJSON?.(onPassJSON);
    };
  }, [
    dispatch,
    enabled,
    hasDidomiLoaded,
    hasWaitForPassJSON,
    isAuthenticated,
    hasAdvertisingConsent,
    pathname,
    isDidomiInIframe,
  ]);
};

type UseDidomiTvParams = {
  noticeId?: string;
  enabled: boolean;
};

const useDidomiTv = ({ noticeId, enabled }: UseDidomiTvParams): void => {
  useEffect(() => {
    if (enabled && isClientSide()) {
      processDidomiConsent({ noticeId });
    }
  }, [enabled, noticeId]);
};

export const useDidomi = $_BUILD_RENDERMODE_CSR ? useDidomiTv : useDidomiWeb;
