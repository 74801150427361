import { KEY_UP } from '../constants/keys';
import type {
  Middleware,
  MiddlewareFactory,
  MiddlewareHooks,
  OriginElement,
} from '../store/types';

/**
 * Options for dynamic middleware
 */
export type DynamicOptions = {
  /**
   * If the binder is not the direct ancestor of focusable elements, you can pass a selector to get the direct ancestor.
   */
  listSelector?: string;
};

/**
 * Instantiate a new dynamic middleware. This middleware aims to solve issues were binder focusable
 * elements are added / removed without the binder being noticed. It uses a mutation observer to
 * monitor DOM changes a re-trigger focus logic.
 *
 * @param options Middleware options
 *
 * @example
 *  const MIDDLEWARE = [dynamic({ listSelector: 'ul' }), spacial()];
 *
 *  function MyComponent() {
 *    return (
 *      <Binder middleware={MIDDLEWARE}>
 *        <button>hello</button>
 *      </Binder>
 *    );
 *  }
 *
 * @returns Dynamic middleware factory
 */
export const dynamic: Middleware<DynamicOptions> =
  ({ listSelector } = {}): MiddlewareFactory =>
  (binder): MiddlewareHooks => {
    // State variables, to memorize last focused element and mutation observer
    let memoizedElement: HTMLElement | undefined;
    let memoizedIndex: number = -1;
    let mutation: MutationObserver | undefined;

    // Handle mutations triggered by mutation observer
    const handleMutation = () => {
      const elements = binder.getElements();

      if (
        memoizedElement &&
        !elements.includes(memoizedElement) &&
        binder.store.activeLayer === binder.layerId &&
        binder.layer.currentBinder === binder
      ) {
        // We ignore case were there is no longer focusable elements within the binder, this
        // case is handled by `destroyed` hook
        if (elements.length > 0) {
          // Find the element index to refocus. We try to refocus the same index, falling back to
          // latest element if memoizedIndex is to high
          const targetIndex = Math.min(elements.length - 1, memoizedIndex);

          // Trigger the focus logic from layer
          binder.layer.focus(binder, elements[targetIndex]);

          // Trigger the focused hook from the binder
          if (elements?.[targetIndex]) {
            binder.callFocusedHook(elements[targetIndex]);
          }
        }
      }
      binder.dirty = true;
    };

    const focused = (element: OriginElement) => {
      // Setup the observer the first time we enter this binder
      if (!mutation) {
        mutation = new MutationObserver(handleMutation);
        let listEl: HTMLElement = binder.el;

        // If user provides a list selector, try to get that. Fallback to binder element
        if (listSelector) {
          listEl = binder.el.querySelector(listSelector) || listEl;
        }

        // Monitor changes on child list
        mutation.observe(listEl, { childList: true });
      }

      if (element instanceof HTMLElement) {
        memoizedElement = element;
        memoizedIndex = binder.getElements().indexOf(element);
      }
    };

    const destroyed = () => {
      // If focus is currently on this binder, simulate a KEY_UP to move on binder directly above.
      // This is not ideal and control of this behavior may be given to application
      if (
        binder.store.activeLayer === binder.layerId &&
        binder.layer.currentBinder === binder
      ) {
        binder.store.handleKey(KEY_UP);
      }

      // Cleanup the mutation observer
      if (mutation) {
        mutation.disconnect();
      }
    };

    return {
      focused,
      destroyed,
    };
  };
