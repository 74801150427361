import { useSelector } from 'react-redux';
import { useScript } from 'usehooks-ts';
import { getOnePlayerUrl } from '../../../helpers/url/url-helper';
import { platformSelector } from '../../../store/slices/application-selectors';

/** Inject the player script into the DOM. */
export const usePlayerScriptWeb = (shouldPreventLoad = false): boolean => {
  const platform = useSelector(platformSelector);
  const onePlayerSrc = getOnePlayerUrl(platform);

  const status = useScript(onePlayerSrc, {
    removeOnUnmount: false,
    id: 'onePlayer',
    shouldPreventLoad,
  });

  const isPlayerScriptLoaded = status === 'ready';

  return isPlayerScriptLoaded;
};

const usePlayerScriptTv = (): boolean => {
  return true;
};

export const usePlayerScript = $_BUILD_RENDERMODE_CSR
  ? usePlayerScriptTv
  : usePlayerScriptWeb;
