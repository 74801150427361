import { applyBodyScrollbarWidth } from './helpers';
import { isClientSide } from '../window/isClientSide';

/**
 * Determine the width of the scrollbar
 * Basic steps here are:
 * 1. Create hidden div (outer) with width set to '100px' and get offset width (should be 100)
 * 2. Force scroll bars to appear in div (outer) using CSS overflow property
 * 3. Create new div (inner) and append to outer, set its width to '100%' and get offset width
 * 4. Calculate scrollbar width based on gathered offsets
 * @returns {number} the with of the scrollbar
 */
export const getScrollbarWidth = (): number => {
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.width = '100px';
  document.body.appendChild(outer);

  const widthNoScroll = outer.offsetWidth;
  // force scrollbars
  outer.style.overflow = 'scroll';

  // add innerdiv
  const inner = document.createElement('div');
  inner.style.width = '100%';
  outer.appendChild(inner);

  const widthWithScroll = inner.offsetWidth;

  // remove divs
  if (outer.parentNode) {
    outer.parentNode.removeChild(outer);
  }

  return widthNoScroll - widthWithScroll;
};

/**
 * Safely add a class to an element
 *
 * @param {HTMLElement} elem
 */
const safeAdd =
  (elem: HTMLElement) =>
  (style: string): void => {
    if (!isClientSide()) {
      return;
    }

    if (!elem.classList.contains(style)) {
      elem.classList.add(style);
    }
  };

/**
 * addBodyNoScroll
 *
 * adds noscroll css class to body, or noscroll-{suffix} if suffix is provided
 * @param {string} suffix - optional suffix to add to the class
 * @returns {function}
 */
export const addBodyNoScroll = (suffix?: string): void => {
  safeAdd(document.body)(['noscroll', suffix].filter(Boolean).join('-'));
  // Necessary to fix scrollbar jump
  applyBodyScrollbarWidth(getScrollbarWidth());
};
