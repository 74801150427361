import loadable from '@loadable/component';
import Spinner from '../../components/Spinner/Spinner';

const LoadablePrivacyManager = loadable(
  () => import('./components/PrivacyManangerContainer'),
  {
    fallback: <Spinner />,
  },
);

export default LoadablePrivacyManager;
