import classNames from 'classnames';
import type { JSX, ReactElement } from 'react';
import { cloneElement } from 'react';
import { useDiveContext } from '../../context';
import { mediaContainerCVA, mediaCva } from './Media.cva';
import type { MediaAspectRatio, MediaProps } from './Media.types';
import { MediaContextProvider } from './provider';

const EmptyWrapper = ({ children }: { children: ReactElement }) => children;

/**
 * `Media` is a component used to render assets (Images, Videos), status, and editorial content for a given resource.
 * For the `Media` asset to render:
 * - Choose an `aspectRatio`: `1/1`, `2/3`, `3/4`, `4/7`, `4/3`, `16/9`, `16/6`, `23/6`
 * - Set fixed dimensions using props `width` and `heightAsset`
 * - Insert within sized parent element to occupy 100% of its width and height
 *
 * @example
 *
 * ```tsx
 * <Media
 *   aspectRatio="169"
 *   image={<MediaImage alt="Avatar: The Way of Water" src="https://avatar.jpeg" />}
 *   overlay={<MediaOverlay title="Avatar: The Way of Water" />}
 * />
 * ```
 */
export default function Media({
  id,
  aspectRatio,
  width,
  heightAsset,
  image,
  video,
  overlay,
  footer,
  className,
  'data-testid': dataTestId,
  showPlaceholderAnim,
  showTvFocusStyles = true,
  variant = 'solid',
  footerPosition = 'default',
  ...a11y
}: MediaProps): JSX.Element {
  const { isTv } = useDiveContext();
  /**
   * An additional parent div is needed when we need to customize the width/height of an element having 'aspect-*' class.
   * Since 'aspect-*' classes doesn't work with customized width/height. https://github.com/tailwindlabs/tailwindcss/discussions/11634
   * This wrapper defaults to EmptyWrapper, to avoid unnecessary divs and interference with the styling.
   * */
  const AspectRatioWrapper =
    footerPosition === 'responsive' || footerPosition === 'right'
      ? 'div'
      : EmptyWrapper;
  return (
    <MediaContextProvider footerPosition={footerPosition}>
      <div
        style={{ width }}
        className={mediaContainerCVA({
          hasAspectRatio: !!aspectRatio,
          isTv,
          footerPosition,
          className,
        })}
        id={id}
        data-testid={dataTestId}
        {...a11y}
      >
        <AspectRatioWrapper
          className={classNames({
            'w-1/2 dt-breakpoint-sm-start:w-full':
              footerPosition === 'responsive',
            'w-1/2': footerPosition === 'right',
          })}
        >
          <div
            style={{ height: heightAsset }}
            className={mediaCva({
              isTv,
              variant,
              showPlaceholderAnim,
              aspectRatio: aspectRatio as MediaAspectRatio,
              showTvFocusStyles,
            })}
          >
            {overlay}
            {video}
            {image &&
              cloneElement(image, {
                className: classNames(image.props.className, 'object-cover', {
                  relative: !aspectRatio,
                }),
              })}
          </div>
        </AspectRatioWrapper>
        {footer}
      </div>
    </MediaContextProvider>
  );
}
