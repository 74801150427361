import {
  Template,
  isApiV2WsFromPathError,
  wsFromPath,
} from '@canalplus/sdk-hodor';
import { getEncodedObject, isSomeEnum } from '@dce-front/onewebapp-utils';
import { markPerformance } from '../../helpers/performance/markPerformance';
import { getId } from '../../helpers/utils/utils-helper';
import { errorTemplate } from '../../store/slices/error';
import { openImmersive } from '../../store/slices/immersive';
import { requestTemplate } from '../../store/slices/page';
import { getDestructuredWsFromPath } from './helpers/getDestructuredWsFromPath';
import { getDifferentPathRedirect } from './helpers/getDifferentPathRedirect';
import { getUrlPageWithQueries } from './helpers/getUrlPageWithQueries';
import { shouldDifferentPathRedirect } from './helpers/shouldDifferentPathRedirect';
import type {
  PerformWsFromPathFetchWithQueryParameters,
  PerformWsFromPathParameters,
  PerformWsFromPathParametersBase,
  PerformWsFromPathParametersClientSpecific,
  PerformWsFromPathParametersServerSpecific,
  PerformWsFromPathRedirect,
  PerformWsFromPathRedirectTo,
} from './types';

let hasMarked = false;

export async function performWsFromPath(
  parameters: PerformWsFromPathParametersBase &
    PerformWsFromPathParametersClientSpecific,
): Promise<PerformWsFromPathRedirect | PerformWsFromPathRedirectTo | void>;

export async function performWsFromPath(
  parameters: PerformWsFromPathParametersBase &
    PerformWsFromPathParametersServerSpecific,
): Promise<
  PerformWsFromPathRedirect | PerformWsFromPathFetchWithQueryParameters | void
>;

export async function performWsFromPath({
  dispatch,
  hodorSdkConfig,
  isClient,
  path,
  query,
  search,
  shouldOpenImmersive,
  state,
  subscriberId,
}: PerformWsFromPathParameters): Promise<
  | PerformWsFromPathRedirect
  | PerformWsFromPathRedirectTo
  | PerformWsFromPathFetchWithQueryParameters
  | void
> {
  if (hodorSdkConfig) {
    const wsFromPathResponse = await wsFromPath(hodorSdkConfig, {
      path,
    });

    if (!hasMarked && state) {
      markPerformance('hodor-wsfrompath-fetched', state);
      hasMarked = true;
    }

    if (isApiV2WsFromPathError(wsFromPathResponse)) {
      const { text, title } = wsFromPathResponse.errorResponse;

      dispatch(
        errorTemplate({
          status: 404,
          immersive: false,
          displayTemplate: Template.Error,
          title,
          text,
        }),
      );

      return;
    }

    const {
      adult,
      displayName,
      displayTemplate,
      parameters,
      pathname: onClickPath,
      URLWebsite,
      perso,
    } = getDestructuredWsFromPath(wsFromPathResponse);

    if (!displayTemplate || !isSomeEnum(Template)(displayTemplate)) {
      throw Error(
        `Error occurs during wsFromPath: displayTemplate is undefined or invalid for the path "${path}"`,
      );
    }

    if (displayTemplate === Template.ExternalSite && URLWebsite) {
      return {
        redirect: URLWebsite,
      };
    }

    if (shouldDifferentPathRedirect(path, wsFromPathResponse.redirectTo)) {
      return getDifferentPathRedirect({
        path: onClickPath,
        // Since QS is decoded by express, we need to re-encode it
        query: query ? getEncodedObject(query) : undefined,
        search,
      });
    }

    const urlPageWithQueries = getUrlPageWithQueries(
      wsFromPathResponse,
      subscriberId,
    );

    dispatch(
      shouldOpenImmersive
        ? openImmersive({
            mainOnClick: {
              displayTemplate,
              displayName,
              path: onClickPath,
              adult,
              URLPage: urlPageWithQueries,
              parameters,
            },
            uniqueId: getId(),
          })
        : requestTemplate({
            mainOnClick: {
              displayTemplate,
              displayName,
              path: isClient ? onClickPath : path,
              adult,
              perso,
              URLPage: urlPageWithQueries,
              parameters,
            },
            uniqueId: getId(),
          }),
    );

    if (isClient) {
      return {
        redirectTo: wsFromPathResponse.redirectTo,
      };
    }

    return {
      fetchWithQueryParameters: {
        displayTemplate,
        onClickParameters: parameters,
        URLPage: urlPageWithQueries,
      },
    };
  }
}
