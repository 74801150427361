import type { JSX } from 'react';
import { WebMenuChevronDownSvg } from '../../../icons/icons';
import { Dropdown } from '../../Dropdown/Dropdown';
import { HeaderNavDropdownMenu } from '../HeaderNavDropdownMenu/HeaderNavDropdownMenu';
import HeaderNavItem from '../HeaderNavItem/HeaderNavItem';
import type { HeaderNavDropdownProps } from './HeaderNavDropdown.types';

// Header height 64px => Trigger height 38px => 64 - 38 = 26px / 2 = 13px
// To align the dropdown with the bottom of the header
const DROPDOWN_OFFSET_OPTIONS = { offset: { mainAxis: 13 } };

/**
 * A `HeaderNavDropdown` is a dropdown component for the `HeaderNav`
 *
 * @example
 *
 * ```tsx
 * <HeaderNavDropdown label="label" currentIndex={0} />
 * ```
 */

export function HeaderNavDropdown({
  label,
  items,
  currentIndex = -1,
  options = DROPDOWN_OFFSET_OPTIONS,
  'data-testid': dataTestId,
  tabIndex,
  dropdownPlacement,
}: HeaderNavDropdownProps): JSX.Element {
  return (
    <Dropdown
      data-testid={dataTestId}
      options={options}
      placement={dropdownPlacement}
      renderTrigger={({ triggerProps }) => (
        <HeaderNavItem
          label={label}
          icon={<WebMenuChevronDownSvg />}
          iconPosition="right"
          isCurrent={currentIndex >= 0 && currentIndex < items.length}
          tabIndex={tabIndex}
          {...triggerProps}
        />
      )}
    >
      <HeaderNavDropdownMenu items={items} currentIndex={currentIndex} />
    </Dropdown>
  );
}
