import type { ApiV2OnClick } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import { DIMENSIONS } from '@dce-front/onewebapp-dive-utils';
import classNames from 'classnames';
import type { JSX, MouseEventHandler, ReactNode } from 'react';
import { ImageType } from '../../ImageType/ImageType';
import styles from './UserMenuOption.module.css';

export type UserMenuOptionProps = {
  Linker: (props: any) => ReactNode;
  title: string;
  ariaTitle?: string;
  handleClick?: MouseEventHandler;
  id?: string;
  newWindowAriaLabel?: string;
  onClick?: ApiV2OnClick;
  showChevron?: React.ReactElement | JSX.Element;
  subTitle?: string;
  URLImage?: string;
};

export function UserMenuOption({
  ariaTitle = '',
  handleClick,
  id,
  Linker,
  newWindowAriaLabel = '',
  onClick,
  showChevron,
  subTitle,
  title,
  URLImage,
}: UserMenuOptionProps): JSX.Element {
  const item = (
    <>
      {URLImage && (
        <ImageType
          className={styles.UserMenuOption__image}
          URLImage={{ default: URLImage }}
          dimensions={DIMENSIONS.USER_MENU_IMAGE}
          isFixedDimensions
        />
      )}
      <span
        className={classNames(
          styles.UserMenuOption__label,
          'UserMenuOptionLabel',
        )}
      >
        {title}
      </span>
      {(subTitle || showChevron) && (
        <div className={styles.UserMenuOption__right}>
          {subTitle && (
            <span
              className={classNames(
                styles.UserMenuOption__subtitle,
                'UserMenuOptionSubtitle',
              )}
            >
              {subTitle}
            </span>
          )}
          {showChevron && (
            <div className={styles.UserMenuOption__icon}>{showChevron}</div>
          )}
        </div>
      )}
    </>
  );

  const commonProps = {
    className: styles.UserMenuOption,
    id,
  };

  return (
    <li data-testid="linker">
      {handleClick ? (
        <button
          aria-label={`${ariaTitle}`}
          onClick={handleClick}
          type="button"
          {...commonProps}
        >
          {item}
        </button>
      ) : (
        <Linker
          data={{ mainOnClick: onClick }}
          ariaLabel={`${ariaTitle} ${newWindowAriaLabel}`}
          {...commonProps}
        >
          {item}
        </Linker>
      )}
    </li>
  );
}
