import { KEYCODE } from '@dce-front/onewebapp-utils';
import classNames from 'classnames';
import type { JSX } from 'react';
import { useEffect, useState } from 'react';
import styles from './FlipSwitch.css';

export type FlipSwitchProps = {
  'aria-label'?: string;
  handleChange?: () => void;
  id: string;
  isChecked?: boolean;
  label?: string;
  name: string;
};

/**
 * Component that toggle episode summary
 *
 * @param label
 * @param handleChange
 * @param isChecked   whether or not the component is already checked
 * @param name        name of the component
 * @param id          id of the component
 */
export function FlipSwitch({
  'aria-label': ariaLabel = '',
  handleChange = () => {},
  id,
  isChecked = false,
  label = '',
  name,
}: FlipSwitchProps): JSX.Element {
  const [userUsedTab, setUserUsedTab] = useState(false);
  useEffect(() => window.addEventListener('keydown', handleFirstTab));

  const onChange = () => {
    window.dispatchEvent(new Event('resize')); // Dispatch event to repositioning actionbar for version mobile and tablet.
    handleChange();
  };

  const handleFirstTab = (e: KeyboardEvent) => {
    if (e.keyCode === KEYCODE.TAB) {
      setUserUsedTab(true);
      window.removeEventListener('keydown', handleFirstTab);
      window.addEventListener('mousedown', handleMouseDownOnce);
    }
  };

  const handleMouseDownOnce = () => {
    setUserUsedTab(false);
    window.addEventListener('keydown', handleFirstTab);
  };

  const inputAriaLabel = label ? '' : ariaLabel;

  return (
    <div className={styles.flipSwitch}>
      <label
        className={classNames(styles.flipSwitch__label, {
          [styles.flipSwitch__label__focus!]: userUsedTab,
        })}
        htmlFor={id}
      >
        {label && <span className={styles.flipSwitch__labelText}>{label}</span>}
        <input
          type="checkbox"
          name={name}
          id={id}
          className={styles.flipSwitch__checkbox}
          onChange={onChange}
          value={isChecked ? 'true' : 'false'}
          aria-label={inputAriaLabel}
        />
        <span
          className={classNames(styles.flipSwitch__toggle, {
            [styles.flipSwitch__toggle__focus!]: userUsedTab,
          })}
        />
      </label>
    </div>
  );
}
