import { zap } from '@canalplus/ifc-onecore';
import type { IMultiContent } from '@canalplus/oneplayer-types';
import { isTOfferLocation } from '@canalplus/sdk-core';
import { DisplayMode } from '@dce-front/onewebapp-utils';
import type { LinkerData } from '../../components/Linker/types';
import { PlayerPlatform } from '../../constants/playerPlatforms';
import { HTML5_PLAYER } from '../../constants/playerTypes';
import { StrateMode } from '../../constants/strates';
import { getPublicConfig } from '../../helpers/config/config-helper';
import {
  getContentID,
  type PlayerContent,
} from '../../helpers/fullscreen/fullscreen-helper';
import type { ILiveGridPlayerData } from '../../helpers/liveTV/liveTV-helper';
import { getOnePlayerBaseUrl } from '../../helpers/url/url-helper';
import {
  isHDRSelector,
  isLowLatencySelector,
} from '../../store/slices/user-selectors';
import { resetAdultBrowsing } from './adult';
import {
  adultBrowsingSelector,
  adultDisclaimerReadSelector,
} from './adult-selectors';
import {
  fullLocaleSelector,
  getFeatureToggleHdr,
  getFeatureToggleLowLatency,
  getNBOLiveInformations,
  isAndroidSelector,
  offerLocationSelector,
  offerZoneSelector,
  platformSelector,
  tokenCMSSelector,
} from './application-selectors';
import type { PlayerState } from './player';
import { openFullScreen, setPlayerType, startPlayer } from './player';
import { setDisplayMode } from './ui';

export const launchPlayer =
  ({
    settings,
    infoTracking,
    event,
  }: Pick<PlayerState, 'settings' | 'infoTracking'> & {
    event?: React.SyntheticEvent;
  }): Redux.ThunkAction<void> =>
  (dispatch) =>
    (window as any).waitForPassToken(() => {
      dispatch(startPlayer({ settings, infoTracking, event }));
    });

const PLAYER_PLATFORM = {
  [StrateMode.LiveTv]: PlayerPlatform.Live,
  [PlayerPlatform.Multi]: PlayerPlatform.Multi,
};

export const launchPlayerFullScreen =
  ({
    data,
    event,
    localSettings,
    trackingContext,
    type,
  }: {
    data: PlayerState['data'] | LinkerData;
    event?: React.SyntheticEvent;
    localSettings?: Partial<PlayerState['settings']>;
    trackingContext?: PlayerState['trackingContext'];
    type?: string;
  }): Redux.ThunkAction<void> =>
  (dispatch, getState) => {
    if (event) {
      event.preventDefault(); // Stop Linker redirect
    }

    const state = getState();
    const context = platformSelector(state);
    const offerZone = offerZoneSelector(state);
    const offerLocation = offerLocationSelector(state);
    const isAndroid = isAndroidSelector(state);
    const locale = fullLocaleSelector(state);
    const isFeatHdr = getFeatureToggleHdr(state);
    const isFeatLowLatency = getFeatureToggleLowLatency(state);

    const lowLatency = isLowLatencySelector(state);
    const disclaimerRead = adultDisclaimerReadSelector(state);
    const adultBrowsing = adultBrowsingSelector(state);

    const hdr = isHDRSelector(state);

    const {
      NBOlivePlayerButtonLabel = ' ',
      NBOlivePlayerButtonUrl = '',
      NBOlivePlayerText = '',
    } = getNBOLiveInformations(state);

    /**
     * disclaimerRead = true, adult disclaimer is settled with the code
     * adultBrowsing = true, we are navigating in adult corner
     * type = live, only trigger TV live
     */
    if (type === StrateMode.LiveTv && adultBrowsing && disclaimerRead) {
      dispatch(resetAdultBrowsing());
    }

    if ($_BUILD_RENDERMODE_CSR && type === StrateMode.LiveTv) {
      const tokenCMS = tokenCMSSelector(state);
      zap({ channelId: getContentID(data as PlayerContent), tokenCMS }).catch(
        console.error,
      );
      return;
    }

    const platform =
      type === PlayerPlatform.Multi || type === StrateMode.LiveTv
        ? PLAYER_PLATFORM[type]
        : PlayerPlatform.Hapi;

    const trackingContextData =
      (data as ILiveGridPlayerData)?.onClick?.trackingContext ||
      (data as LinkerData)?.mainOnClick?.trackingContext ||
      trackingContext;

    const {
      contextType,
      context_type: contextTypeAlt,
      contextDetail,
      context_list_id: contextListId,
    } = trackingContextData || {};
    const trackingContextType = contextType || contextTypeAlt;
    const trackingContextDetail = contextDetail || contextListId;
    const publicConfig = getPublicConfig();
    dispatch(openFullScreen({ trackingContext: trackingContextData }));
    dispatch(setPlayerType({ playerType: HTML5_PLAYER }));
    dispatch(
      launchPlayer({
        settings: {
          platform,
          uiLanguage: locale,
          context: publicConfig.PLAYER.ONE_PLAYER.CONTEXT,
          env: publicConfig.PLAYER.ONE_PLAYER.ONE_PLAYER_ENV,
          base: getOnePlayerBaseUrl(context),
          content: (type === PlayerPlatform.Multi
            ? (data as IMultiContent[])
            : getContentID(data as PlayerContent)) as NonNullable<
            PlayerState['settings']
          >['content'],
          params: {
            autoplay: true,
            offerZone,
            offerLocation: isTOfferLocation(offerLocation)
              ? offerLocation
              : undefined,
            startLiveProgramFromBeginning: (data as Record<string, boolean>)
              .startLiveProgramFromBeginning,
            exitButton: {
              showFullScreen: true,
              showWindowed: true,
            },
            unauthorizedChannelErrorContent: {
              buttonLabel: NBOlivePlayerButtonLabel,
              buttonUrl: NBOlivePlayerButtonUrl,
              textLabel: NBOlivePlayerText,
            },
            ...localSettings?.params,
          },
          refererTrackingId: trackingContextType
            ? `${trackingContextType} ${trackingContextDetail}`
            : '',
          ...((isFeatLowLatency || isFeatHdr) && {
            featureFlags: {
              hdr: isFeatHdr && hdr,
              lowLatency: isFeatLowLatency && lowLatency,
            },
          }),
          ...localSettings,
        },
        event,
      }),
    );
    dispatch(
      setDisplayMode(
        isAndroid ? DisplayMode.FULLSCREEN : DisplayMode.FULLWINDOWED,
      ),
    );
  };
