import { useEffect, useRef } from 'react';

/**
 * In some cases we need to compare props with old values
 * https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 *
 * @param value The value to be compared
 * @returns The previous value
 */
export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>(undefined);

  useEffect(() => {
    ref.current = value;
  });

  // eslint-disable-next-line react-compiler/react-compiler
  return ref.current;
};
