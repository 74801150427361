import { Type } from '@dce-front/onewebapp-utils';
import classNames from 'classnames';
import type { JSX } from 'react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  addCharacter,
  deleteCharacter,
  dispatchDecrement,
  dispatchIncrement,
  updateMode,
} from './actions';
import {
  CLEAR_KEY,
  CONTENT_KEYS,
  FOOTER_KEYS,
  INITIAL_STATE,
  LOWER_KEY,
  NEXT_KEY,
  POLISH_CONTENT_KEYS,
  POLISH_LAST_ROW_CONTENT_KEYS,
  PREV_KEY,
  SPACE_KEY,
  SPECIALS_KEY,
  TO_HIDE_IN_SPECIAL_MODE,
  UPPER_KEY,
} from './const';
import { Mode } from './types';
import styles from './VirtualKeyboard.module.css';
import { virtualKeyboardContext } from './VirtualKeyboardContext';
import { VirtualKeyboardKey } from './VirtualKeyboardKey/VirtualKeyboardKey';

export type VirtualKeyboardProps = {
  isDisabled?: boolean;
  isPolish?: boolean;
  layout?: 'normal' | 'polish' | 'big' | string[];
  maxInputValue?: number;
  selectedId?: string;
  type?: Type;
};

/**
 * The **VirtualKeyboard** component allows the user to enter text with a graphical interface.
 * We can add or delete keys, change the characters case, move the cursor...
 * The component expose its state, which contains especially the input value, and its dispatcher (you have a full control for changing his state)
 *
 * The librairie expose an object with 3 entry points:
 *
 * - **VirtualKeyboard**: the component
 * - **VirtualKeyboardProvider**: a React component used to provide the component context in your application
 * - **virtualKeyboardContext**: a React context to manipulate the component state
 *
 * ### Usage
 *
 * ```javascript
 * import { VirtualKeyboard } from '@canalplus/mycanal-sharedcomponent';
 *
 * const Example: FC = ({ selectedId = 'kb-a' }) => {
 *   return <VirtualKeyboard selectedId={selectedId} />;
 * };
 *
 * export default Example;
 * ```
 *
 * **VirtualKeyboard** takes only one property, _selectedId_, which highlight the selected key
 *
 * ### VirtualKeyboardProvider
 *
 * By using it, you can provide the state and the dispatcher of **VirtualKeyboard** in all of your application
 *
 * ```javascript
 * <VirtualKeyboardProvider>
 *   <App />
 * </VirtualKeyboardProvider>
 * ```
 *
 * Like this, all children of **VirtualKeyboardProvider** will be able to use **virtualKeyboardContext** with the `useContext` react hook.
 *
 * ### virtualKeyboardContext
 *
 * Everywhere you need to:
 *
 * - Read the state of the component, use the `state` object
 * - Modify the state of the component, use the `dispatch` function
 *
 * ```javascript
 * const Example: FC = () => {
 *   const { state, dispatch } = useContext(virtualKeyboardContext);
 * };
 * ```
 *
 * ⚠️ The component completely self-contained and you shouldn't normally need to use the dispatcher
 * For more informations on which _Actions_ can be dispatched, please refer to our [codebase](https://gitlab.canalplus.pro/deddev/dedmycanalwebapp/-/blob/develop/libs/sharedcomponent/src/components/VirtualKeyboard/actions.ts)
 *
 * @param selectedId Focus a key by its `id`
 */
export const VirtualKeyboard = ({
  isDisabled = false,
  isPolish = false,
  layout = 'normal',
  maxInputValue = Number.MAX_VALUE,
  selectedId,
  type,
}: VirtualKeyboardProps): JSX.Element => {
  const { state = INITIAL_STATE, dispatch } = useContext(
    virtualKeyboardContext,
  );
  const { cursorIndex, inputValue, mode } = state;
  const [isAddCharDisabled, setIsAddCharDisabled] = useState(false);

  const contentKeysToMap = isPolish ? POLISH_CONTENT_KEYS : CONTENT_KEYS;

  useEffect(() => {
    setIsAddCharDisabled(inputValue.length >= maxInputValue);
  }, [inputValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const pressKey = useCallback(
    (id: string) => {
      switch (id) {
        case UPPER_KEY.id:
          return dispatch(updateMode(Mode.UPPERCASE));

        case LOWER_KEY.id:
          return dispatch(updateMode(Mode.LOWERCASE));

        case SPECIALS_KEY.id:
          return dispatch(updateMode(Mode.SPECIALS));

        case CLEAR_KEY.id:
          return dispatch(deleteCharacter(inputValue, cursorIndex));

        case NEXT_KEY.id:
          return dispatch(dispatchIncrement);

        case PREV_KEY.id:
          return dispatch(dispatchDecrement);

        default:
          return dispatch(
            addCharacter(id, inputValue, cursorIndex, mode, isPolish),
          );
      }
    },
    [dispatch, state, isAddCharDisabled], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return useMemo(
    () => (
      <div
        id="virtualKeyboard"
        className={styles.virtualKeyboard}
        data-layout={layout}
      >
        <div className={styles.virtualKeyboard__wrapper}>
          <VirtualKeyboardKey
            keyProp={SPACE_KEY}
            mode={mode}
            pressKey={pressKey}
            isDisabled={isAddCharDisabled || type === Type.Number || isDisabled}
            isSelected={SPACE_KEY.id === selectedId}
          />

          {/* The polish layout design force us to make some tricky conditions to be rendered correctly */}
          <div className={styles.virtualKeyboard__content}>
            {contentKeysToMap.map((key) => {
              // The first and the second row has almost the same number of items and the space-between flex positionning is working great
              // But we have 2 less items in special mode and those hasn't to be rendered
              return mode === Mode.SPECIALS &&
                key.special === TO_HIDE_IN_SPECIAL_MODE ? null : (
                <VirtualKeyboardKey
                  keyProp={key}
                  key={key.id}
                  mode={mode}
                  pressKey={pressKey}
                  isDisabled={
                    isAddCharDisabled ||
                    (type === Type.Number && key.type !== Type.Number) ||
                    isDisabled
                  }
                  isSelected={key.id === selectedId}
                  layout={layout}
                />
              );
            })}

            {isPolish ? (
              // The last row contains much less items than the others and should be positioned centered
              <div
                className={classNames(
                  styles.virtualKeyboard__content,
                  styles.virtualKeyboard__content__isLastRow,
                )}
              >
                {POLISH_LAST_ROW_CONTENT_KEYS.map((key) => (
                  <VirtualKeyboardKey
                    keyProp={key}
                    key={key.id}
                    mode={mode}
                    pressKey={pressKey}
                    isDisabled={
                      isAddCharDisabled ||
                      (type === Type.Number && key.type !== Type.Number) ||
                      isDisabled
                    }
                    isSelected={key.id === selectedId}
                    layout={layout}
                  />
                ))}
              </div>
            ) : null}
          </div>

          <VirtualKeyboardKey
            isDisabled={isDisabled}
            isSelected={CLEAR_KEY.id === selectedId}
            keyProp={CLEAR_KEY}
            mode={mode}
            pressKey={pressKey}
          />
        </div>

        <div className={styles.virtualKeyboard__footer}>
          {FOOTER_KEYS.map((key) => {
            const isActive =
              (key.id === UPPER_KEY.id && mode === Mode.UPPERCASE) ||
              (key.id === LOWER_KEY.id && mode === Mode.LOWERCASE) ||
              (key.id === SPECIALS_KEY.id && mode === Mode.SPECIALS);

            return (
              <VirtualKeyboardKey
                keyProp={key}
                key={key.id}
                isActive={isActive}
                pressKey={pressKey}
                isDisabled={type === Type.Number ? true : isDisabled}
                isSelected={key.id === selectedId}
              />
            );
          })}
        </div>
      </div>
    ),
    [selectedId, maxInputValue, state, isAddCharDisabled, pressKey, isPolish], // eslint-disable-line react-hooks/exhaustive-deps
  );
};
