import { ContentType } from '@canalplus/sdk-hodor';
import { HeaderUserDropdownList } from '@dce-front/dive';
import type { JSX } from 'react';
import { getHeaderProfileListItems } from '../../../helpers/navigation/navigation-helper';
import { useUserMenuExtended } from '../hooks/useUserMenuExtended';
import HeaderProfileButtonStrate from './HeaderProfileButtonStrate';

function HeaderProfileContent(): JSX.Element {
  const { userMenuExtendedNonProfileStrates: userMenuStrates } =
    useUserMenuExtended();

  return (
    <>
      {userMenuStrates.map((strate, index) => {
        switch (strate.type) {
          case ContentType.Sections: {
            const listItems = getHeaderProfileListItems(strate.contents);
            return (
              <HeaderUserDropdownList
                key={`headerProfile-strate-${strate.type}`}
                items={listItems}
              />
            );
          }

          case ContentType.Button: {
            const { type, title, subtitle, contents } = strate;
            return (
              <HeaderProfileButtonStrate
                key={`headerProfile-strate-${type}`}
                title={title}
                subtitle={subtitle}
                contents={contents}
                isLastStrate={index === userMenuStrates.length - 1}
              />
            );
          }

          default:
            return null;
        }
      })}
    </>
  );
}

export default HeaderProfileContent;
