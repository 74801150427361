import type { PassSdkConfig } from '@canalplus/sdk-pass';
import type { JSX, ReactNode } from 'react';
import { createContext } from 'react';

export const PassSdkContext = createContext<PassSdkConfig | null>(null);

const { Provider } = PassSdkContext;

export type PassSdkProviderProps = {
  passSdkConfig: PassSdkConfig;
  children: ReactNode;
};

/** React context provider to use the `usePassSdk` hook */
export function PassSdkProvider({
  children,
  passSdkConfig,
}: PassSdkProviderProps): JSX.Element {
  return <Provider value={passSdkConfig}>{children}</Provider>;
}
