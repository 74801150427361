import type { AriaAttributes, ButtonHTMLAttributes, Ref } from 'react';
import type { XOR } from 'ts-essentials';
import type { DiveProps, DiveSvgProps } from '../../../types/Dive.types';

export enum ButtonIconVariant {
  Default = 'default',
  Video = 'video',
  Header = 'header',
  MediaOverlay = 'media-overlay',
  MediaOverlayPvr = 'media-overlay-pvr',
}

export enum ButtonIconColorVariant {
  Neutral = 'neutral',
  Positive = 'positive',
  Negative = 'negative',
}

type ButtonIconAsButtonProps = {
  /**
   * The type of HTML element wrapping the `ButtonIcon`.
   *
   * Useful for when you want to render the `ButtonIcon` as a descendant of a `button` element.
   *
   * @default 'button'
   */
  as?: 'button';
  /**
   * This Boolean attribute prevents the user from interacting with the button: it cannot be pressed or focused.
   *
   * @defaultValue false
   */
  disabled?: ButtonHTMLAttributes<HTMLButtonElement>['disabled'];
  /**
   * This Boolean attribute adds a selected state style to the button.
   * (e.g. to indicate that the user is on or is currently viewing the referenced page)
   *
   * @defaultValue false
   */
  isSelected?: boolean;
  /**
   * The onClick handler
   */
  onClick?: ButtonHTMLAttributes<HTMLButtonElement>['onClick'];
};

type ButtonIconAsDivProps = {
  /**
   * The type of HTML element wrapping the `ButtonIcon`.
   *
   * Useful for when you want to render the `ButtonIcon` as a descendant of a `button` element.
   *
   * @default 'button'
   */
  as?: 'div';
};

export type ButtonIconProps = {
  /**
   * The icon element to display within the ButtonIcon.
   */
  icon: React.ReactElement<DiveSvgProps>;
  /**
   * The icon color
   *
   * @defaultValue 'neutral'
   */
  iconColor?: `${ButtonIconColorVariant}`;
  /**
   * The label to be displayed below the ButtonIcon
   */
  label?: string;
  /**
   * The style variant of the ButtonIcon
   *
   * @defaultValue 'default'
   */
  variant?: `${ButtonIconVariant}`;
  /**
   * The id of the ButtonIcon
   */
  id?: ButtonHTMLAttributes<HTMLButtonElement>['id'];
  /**
   * React ref.
   */
  ref?: Ref<HTMLButtonElement | HTMLDivElement>;
} & DiveProps &
  Omit<AriaAttributes, 'aria-disabled' | 'aria-hidden'> &
  // ButtonIconSharedProps &
  XOR<ButtonIconAsButtonProps, ButtonIconAsDivProps>;
