import type { ApiV2ButtonOnClick } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import type {
  ApiV2StubButton,
  ApiV2StubPage,
} from '@dce-front/hodor-types/api/v2/page/dtos/display_templates/stub';

export interface IStubState {
  title?: string;
  text?: string;
  URLImage?: string;
  onClick?: ApiV2ButtonOnClick;
  button?: ApiV2StubButton;
}

const getFormattedStub = (data: ApiV2StubPage): IStubState => {
  const { text, title, URLImage, button } = data || {};

  return {
    text,
    title,
    URLImage,
    button,
    onClick: button?.onClick,
  };
};

export default getFormattedStub;
