import { Template } from '@canalplus/sdk-hodor';
import type { ApiV2Context } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import type {
  ApiV2BroadcastContentWithTrackingContext,
  ContentRowLiveData,
} from '../../templates/ContentRowLive/data/formatter';
import type { LocationStateContext } from '../../typings/routing';
import { getCurrentTimestamp } from '../date/date-helper';
import { mapContextListItemPosition } from '../tracking/tracking-helper';

export const getLocationStateContext = (
  displayTemplate?: string,
): LocationStateContext => {
  switch (displayTemplate) {
    case Template.DetailLight:
    case Template.DetailPage:
    case Template.DetailSeason:
    case Template.DetailShow:
    case Template.Quicktime:
    case Template.CreativeMedia:
    case Template.Slideshow:
    case Template.ContextualOfferPage:
    case Template.PaymentMeans:
    case Template.Stub:
      return 'immersive';

    default:
      return undefined;
  }
};

/**
 * setContextForOnClick
 *
 * @param {object} button
 * @returns {object}
 */
export const setContextForOnClick = (button: any): any => {
  const newButton = { ...button };
  if (newButton.onClick) {
    newButton.context = getLocationStateContext(
      newButton.onClick.displayTemplate,
    );
  }
  return newButton;
};

/**
 * Add to a content:
 * - **trackingContext** if needed
 * - **locationStateContext**
 */
export const getContentWithContexts = <
  T extends {
    onClick?: Routing.IOneDiscoveryOnClick;
    button?: { onClick?: Routing.IOneDiscoveryOnClick };
    [T: string]: unknown;
  },
>(
  content: T,
  trackingContext?: ApiV2Context,
): T => {
  const { onClick, button } = content;
  const hasOnClickButton = !onClick && button && button.onClick;
  const onClickElement = hasOnClickButton ? button.onClick : onClick;
  const { displayTemplate } = onClickElement || {};
  const locationStateContext = getLocationStateContext(displayTemplate);

  const hasTrackingContext =
    locationStateContext === 'immersive' ||
    displayTemplate === Template.Fullscreen ||
    displayTemplate === Template.Landing ||
    displayTemplate === Template.ContentGrid ||
    displayTemplate === Template.GabaritList ||
    displayTemplate === Template.ExternalSite;

  const trackingContextData = hasTrackingContext &&
    trackingContext && { trackingContext };

  if (hasOnClickButton) {
    return {
      ...content,
      ...(button && {
        button: {
          ...button,
          onClick: {
            ...onClickElement,
            ...trackingContextData,
          },
        },
      }),
      context: locationStateContext,
    };
  }

  return {
    ...content,
    ...(onClick && {
      onClick: {
        ...onClick,
        ...trackingContextData,
      },
    }),
    context: locationStateContext,
  };
};

/**
 * getContentsWithContext
 *
 * @param {array} contents
 * @param {object} trackingContext used to track the context of reading a content
 * @returns {array}
 */
export const getContentsWithContext = (
  contents: any[] = [],
  trackingContext?: any,
): any[] => {
  const contentsWithContext = contents.map((content) =>
    getContentWithContexts(content, trackingContext),
  );
  return mapContextListItemPosition(contentsWithContext);
};

/**
 * getContentsOnlyWithTrackingContext
 *
 * @param {array} contents
 * @param {object} trackingContext used to track the context of reading a content
 * @returns {array}
 */
// TODO: Type `contents` with a union of types it can take so the return type of the function can be determined by itself
// After that, we'll be able to remove the `T` typing
export const getContentsOnlyWithTrackingContext = <T>(
  contents: any[],
  trackingContext: ApiV2Context,
): T[] =>
  contents.map((content) => {
    if (content.onClick && trackingContext) {
      content.onClick.trackingContext = trackingContext;
    }
    return content;
  });

/**
 * hasValidLiveTVContents
 *
 * @param {array} contents
 * @param {number} currentTime used to check if there is currently on air content
 * @returns {array}
 */

export const hasValidLiveTVContents = (
  contents: any[],
  currentTime: number = 0,
): boolean => {
  const onAirContent = contents.some(
    ({ startTime, endTime }) =>
      startTime > 0 &&
      endTime > 0 &&
      currentTime >= startTime &&
      currentTime <= endTime,
  );

  return onAirContent;
};

export type CurrentLiveContent = {
  current: ApiV2BroadcastContentWithTrackingContext;
  altLogoChannel?: string;
  URLLogoChannel?: string;
  channel: { epgID?: number };
  epgID?: number;
};

/**
 * getChannelCurrentLiveContent
 *
 * Find the content that is currently live within the contents available for the current channel
 *
 * @param channel data from contentRow
 * @returns the current live content
 */
export const getChannelCurrentLiveContent = (
  channel: ContentRowLiveData['channels'][0],
): CurrentLiveContent | undefined => {
  if (!channel?.contents) {
    return undefined;
  }

  const currentContent = channel.contents.find((content) => {
    const { startTime = 0, endTime = 0 } = content;
    const currentTimestamp = getCurrentTimestamp();

    return startTime <= currentTimestamp && currentTimestamp < endTime;
  });

  if (!currentContent) {
    return undefined;
  }

  return {
    current: currentContent,
    altLogoChannel: channel.Name,
    URLLogoChannel: channel.URLLogoChannel,
    channel: { epgID: channel.epgID },
    epgID: channel.epgID,
  };
};

/**
 * Represents a key-value mapping where keys are strings and values are either strings or undefined.
 */
type GetContentAriaLabelPlaceholderValue = {
  [key: string]: string | undefined;
};

/**
 * Generates an accessible aria label for a content item.
 *
 * @returns The generated aria label string. Returns empty string if no label could be generated.
 *
 * @example
 * ```typescript
 * const content = {
 *   altText: "Movie {title}",
 * };
 *
 * // Basic usage
 * getContentAriaLabel(content); // "Movie {title}"
 *
 * // With placeholders
 * getContentAriaLabel(content, { title: "Replacement" }); // "Movie Replacement"
 * ```
 */
export const getContentAriaLabel = <
  T extends { altText?: string; title?: string },
>(
  content: T,
  placeholderValue: GetContentAriaLabelPlaceholderValue = {},
): string => {
  if (!content.altText) {
    return content.title || '';
  }

  return (
    content.altText
      // Replace placeholder with value
      .replace(/\{[^}]+\}/g, (match) => {
        const key = match.slice(1, -1).trim();
        return placeholderValue[key] || '';
      })
      // Trim unnecessary punctuation
      .replace(/[,;.]\s*(?=[,;.])/g, '')
      // Trim extra spaces
      .replace(/\s+/g, ' ')
  );
};
