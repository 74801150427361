import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../helpers/hooks/useAppDispatch';
import { userChangeSettings } from '../../../store/slices/user';
import {
  areAnimationsAutoplaySelector,
  isTrailerAutoplaySelector,
} from '../../../store/slices/user-selectors';

/**
 * Users can enable/disable the animations autoplay
 * This value is stored in local storage and redux store
 */
export const useAreAnimationsAutoplay = (): {
  /**
   * Can all video animations (excluded the trailer) autoplay
   * in the user settings saved in local storage
   */
  areAnimationsAutoplay: boolean;
  /**
   * Can Trailer feature autoplay
   * in the user settings saved in local storage
   */
  isTrailerAutoplay: boolean;
  /**
   * Can Trailer feature or all other video animations autoplay
   * in the user settings saved in local storage
   */
  isAutoplayEnabled: boolean;
  /**
   * Toggle the autoplay value of all video animations (included the trailer)
   * in the user settings saved in local storage
   */
  toggleAnimationsAndTrailerAutoplay: () => void;
} => {
  const dispatch = useAppDispatch();
  const areAnimationsAutoplay = useSelector(areAnimationsAutoplaySelector);
  const isTrailerAutoplay = useSelector(isTrailerAutoplaySelector);

  const isAutoplayEnabled = areAnimationsAutoplay || isTrailerAutoplay;
  const toggleAnimationsAndTrailerAutoplay = () => {
    dispatch(
      userChangeSettings({
        areAnimationsAutoplay: !isAutoplayEnabled,
        isTrailerAutoplay: !isAutoplayEnabled,
      }),
    );
  };

  return {
    areAnimationsAutoplay,
    isTrailerAutoplay,
    isAutoplayEnabled,
    toggleAnimationsAndTrailerAutoplay,
  };
};
