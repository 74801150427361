const existInHashList = (hash: string, table: string[]): string[] =>
  table?.filter((hashItem) => hashItem.includes(hash));

const isInHashTable = (hash: string, table: string[]) => {
  const hashInList = existInHashList(hash, table);
  return hashInList.length > 0 ? [...hashInList].pop() : false;
};

const createUniqHash = (actualHash: string, foundedHash: string) => {
  const hashID = Number(foundedHash.split('-')[1]) || 0;
  const newHash = `${actualHash}-${hashID + 1}`;
  return newHash;
};

/**
 * getObjValue is used to search in an object using nested string
 * @param object any object you want
 * @param path with nested string like : 'foo.bar'
 * @returns the result of the nested string in the object
 */
export const getObjValue = <T extends object>(
  object: T,
  path: string,
): string => {
  if (!Object.keys(object).length) {
    return '';
  }
  const keys = path.toString().split('.');
  let result: object | string | number = object;
  keys.forEach((key: string) => {
    const newValue = (result as any)?.[key];
    result = newValue?.toString() ? newValue : '';
  });
  if (typeof result === 'object') {
    return '';
  }
  return (result as string | number).toString();
};

export type HashKey = { hash: string };
export type HashObjectReturn<T> = T & HashKey;

/**
 * Thats a little bit of magic but it worth it trust me
 * see the article here https://dev.to/pffigueiredo/typescript-utility-keyof-nested-object-2pa3
 * @returns all the props as nested string as a TYPE
 * @example 'foo.bar'
 */
type NestedKeyOf<ObjectType extends object> = {
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
    ? `${Key}` | `${Key}.${NestedKeyOf<ObjectType[Key]>}`
    : `${Key}`;
}[keyof ObjectType & (string | number)];

type PropType<T extends object> = NestedKeyOf<T> | NestedKeyOf<T>[];

/**
 * hashObject can generate a hash who can be used as a key
 * She replace the reactKey used before
 * She always give the same result with same entries
 * @param obj where you want to add the hash, inferred type is used to create prop autocompletion
 * @param prop is a string/number/symbol who is extracted from intefered or explicit type of obj
 * @param debug who can be passed to true to show warning about prop validation
 * @example hashObject({foo: "bar"}, 'foo')
 * //                       ^? this props can be given using autocompletion
 * @example hashObject<SomeType>(obj, 'key-to-hash')
 * //                        ^? This time it's also autocompleted with explicit type of SomeType
 * @example hashObject({foo: {bar: "bip"}}, 'foo.bar')
 * //                               ^? the function also support nesting and it's in the autocompletion too <3
 * @returns a copy of original object with the property hash added
 */
export const hashObject = <T extends object>(
  obj: T,
  prop: PropType<T>,
  tempTable: string[] = [],
  debug = false,
): HashObjectReturn<T> => {
  let propToHash = Array.isArray(prop)
    ? prop.map((p: string) => getObjValue(obj, p)).join('_')
    : getObjValue(obj, prop);

  if (!propToHash) {
    if (debug) {
      console.warn(
        `You must pass valid object prop(s), the prop(s) :\n${JSON.stringify(
          prop,
          null,
          2,
        )}\ndoesn't exist in the object :\n${JSON.stringify(obj, null, 2)}`,
      );
    }
    propToHash = 'default-hash';
  }
  let hash = Math.abs(
    propToHash.split('').reduce((a, b) => {
      a = (a << 5) - a + b.charCodeAt(0);

      return a & a;
    }, 0),
  ).toString();
  const hashExist = isInHashTable(hash, tempTable);
  // this create a new hash if he already exist
  if (hashExist) {
    // replace original hash with correct one
    hash = createUniqHash(hash, hashExist);
  }
  tempTable.push(hash);
  return { ...obj, hash };
};

/**
 * mapStaticKey
 * @param array is the array to pass he need to be an array ob objects
 * @param prop is the props who's gonna be used to generate the hash
 * @param debug can be passe to true to show warnings about prop validation
 */
export const mapStaticKey = <T extends object>(
  array: T[] | undefined,
  prop: PropType<T>,
  debug = false,
): HashObjectReturn<T>[] => {
  const tempHashTable: string[] = [];
  return (array || []).map((item) =>
    hashObject<T>(item, prop, tempHashTable, debug),
  );
};
