import type { VARIANTS } from '@canalplus/oneplayer-constants/build/types/business/types';
import type {
  IAPIConfigEventsRaw,
  IAPIConfigRaw,
  IAPICredentialsRaw,
} from '@canalplus/oneplayer-types';
import type { OfferLocation, OfferZone } from '@canalplus/sdk-core';
import { isTOfferLocation } from '@canalplus/sdk-core';
import { isClientSide } from '@dce-front/onewebapp-utils';
import { TRAILER } from '../../../constants/contentTypes';
import { PlayerPlaybackState } from '../../../constants/playerStates';
import { getPublicConfig } from '../../../helpers/config/config-helper';
import { msToSeconds } from '../../../helpers/time/time-helper';
import {
  sendLaunchingPlayerError,
  type LaunchingPlayerError,
} from '../../../helpers/tracking/tracking-helper';
import {
  changeChannel,
  sendLaunchPlayerTracking,
  type PlayerState,
} from '../../../store/slices/player';

type GetFinalPlayerSettingsProps = {
  baseSettings: IAPIConfigRaw | null;
  credentials: IAPICredentialsRaw;
  dispatch: Redux.Dispatch;
  enableAd: boolean;
  isBlueTim: boolean;
  locale: string | undefined;
  offerLocation: OfferLocation | undefined;
  offerZone: OfferZone;
  onExit?: () => void;
  onKillPlayer?: () => void;
};

export function getFinalPlayerSettings({
  baseSettings,
  credentials,
  dispatch,
  enableAd,
  isBlueTim,
  locale,
  offerLocation,
  offerZone,
  onExit,
  onKillPlayer,
}: GetFinalPlayerSettingsProps): {
  credentials: IAPICredentialsRaw;
  settings: Partial<IAPIConfigRaw>;
} {
  // TODO: isStartOverAsReplayAvailable missing in oneplayer-types: update or remove obsolete key
  const { isStartOverAsReplayAvailable, startLiveProgramFromBeginning } =
    (baseSettings?.params as any) || {};

  const newSettings = {
    ...baseSettings,
    uiLanguage: locale,
    params: {
      ...baseSettings?.params,
      enableAd,
      offerZone,
      offerLocation:
        offerLocation && isTOfferLocation(offerLocation)
          ? offerLocation
          : undefined,
      isTabKeyNavigationCapturedByPlayer: true,
      // Hodor sends "startAt" in milliseconds but onePlayer needs seconds
      // That's the only place it needs to be converted
      // !warning! don't add startAt === 0 for OnePlayer when startAt is undefined on finalPlayerSettings
      startAt: baseSettings?.params?.startAt
        ? msToSeconds(baseSettings?.params.startAt)
        : undefined,
    },
    variant: isBlueTim
      ? ('telecomitalia_blue' as VARIANTS)
      : (getPublicConfig().PLAYER.ONE_PLAYER.VARIANT as VARIANTS),
    events: {
      onExitButtonClicked: () => {
        onKillPlayer?.();
        onExit?.();
      },
      // TODO: incorrect (?) event types in oneplayer-types
      // temporary fix
      onError: (err: { data: LaunchingPlayerError }) => {
        if (
          isClientSide() &&
          (window as any).sendPlayerError &&
          typeof (window as any).sendPlayerError === 'function'
        ) {
          // Useful for Newrelic
          (window as any).sendPlayerError(err, baseSettings);
        }
        return err?.data ? sendLaunchingPlayerError(err.data) : null;
      },

      onChannelChange: ({ data }: { data: Record<'epgID', number> }) =>
        dispatch(changeChannel({ epgID: data?.epgID })),

      onPlayerStateChange: (({ data }) => {
        if (
          data === PlayerPlaybackState.Ended &&
          baseSettings?.contentFunctionalType === TRAILER
        ) {
          onKillPlayer?.();
        }
      }) as IAPIConfigEventsRaw['onPlayerStateChange'],

      onVodContentLaunched: ({ data }: { data: PlayerState['data'] }) =>
        dispatch(sendLaunchPlayerTracking({ data })),

      onLiveContentLaunched: ({ data }: { data: PlayerState['data'] }) =>
        dispatch(
          sendLaunchPlayerTracking({
            data,
            startFromBeginning: startLiveProgramFromBeginning,
            isStartOverAsReplayAvailable,
          }),
        ),

      onMultiContentLaunched: ({ data }: { data: PlayerState['data'] }) =>
        dispatch(sendLaunchPlayerTracking({ data, isMultilive: true })),

      onEndOfAvailableContent: (() => {
        onKillPlayer?.();
      }) as () => void,
    } as any,
  } satisfies Partial<IAPIConfigRaw>;

  return { credentials, settings: newSettings };
}
