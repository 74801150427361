import type { GetSigninUrlParameters } from '../getSigninUrl';

const scopeMapping = {
  delete: ' sc_accountCancellation',
  parental: ' sc_parentalCode',
  purchase: ' sc_purchaseCode',
} satisfies Record<NonNullable<GetSigninUrlParameters['scope']>, string>;

/**
 * Used by `getSigninUrl`
 * @private
 */
export const getSigninUrlScope = (
  context: GetSigninUrlParameters['scope'],
): string => `pass_profile${context ? `${scopeMapping[context]}` : ''}`;
