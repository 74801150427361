import type { PassUserData } from '../types/PassUserData';

declare global {
  interface Window {
    passJSON?: PassUserData & { passToken: string };
    waitForPassJSON?: (fn?: () => void) => void;
    unsubscribeWaitForPassJSON?: (fn: () => void) => void;
  }
}

let callbacks: (() => void)[] = [];

/** Add the waitForPassJSON mechanism to the window object */
export const initWaitForPassJSON = (): void => {
  window.waitForPassJSON = (callback?: () => void) => {
    if (!window.passJSON && typeof callback === 'function') {
      callbacks.push(callback);

      return;
    }

    if (window.passJSON) {
      callback?.();

      if (callbacks.length) {
        callbacks.forEach((cb) => cb());
        callbacks = [];
      }
    }
  };

  window.unsubscribeWaitForPassJSON = (callback: () => void) => {
    const index = callbacks.findIndex((cb) => {
      return callback === cb;
    });

    if (index !== -1) {
      callbacks.splice(index, 1);
    }
  };
};
