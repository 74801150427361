/**
 * Scroll Helper
 * --------------------
 *
 * Animate any HTML Element scroll in pure Javascript
 *
 * @param element The **HTML element** to scroll
 * @param destination The final value of **scrollLeft** element property
 * @param duration The **duration** of the animation
 *
 * @category Scroll
 */
export const animateScroll = (
  element: HTMLElement,
  destination: number,
  duration: number,
): void => {
  const start = Date.now();
  const timer = setInterval(() => {
    if (!element) {
      clearInterval(timer);
    } else {
      const step = Math.min(1, (Date.now() - start) / duration);

      element.scrollLeft += step * (destination - element.scrollLeft);

      if (step === 1) {
        clearInterval(timer);
      }
    }
  }, 16); /* ~60fps */
};
