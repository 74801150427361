import { Button, ButtonIcon, MaterialChevronLeftSvg } from '@canalplus/dive';
import {
  useBodyNoScroll,
  useFocusTrap,
  useKeyboardEvent,
} from '@canalplus/mycanal-util-react';
import { KEYCODE } from '@dce-front/onewebapp-utils';
import classNames from 'classnames/bind';
import type { JSX } from 'react';
import { useCallback, useRef } from 'react';
import { createPortal } from 'react-dom';
import IconClose from '../../assets/svg/close.svg';
import styles from './ModalV2.module.css';
import type { ModalV2Props } from './ModalV2.types';

const cx = classNames.bind(styles);

const focusFromElement = (idFrom: HTMLElement | null | undefined): void =>
  idFrom?.focus();

export const DEFAULT_PORTAL_ELEMENT_ID = 'default-portal';

export function ModalV2({
  id,
  size,
  title,
  idBody,
  idFrom,
  children,
  className,
  role = 'dialog',
  hidePadding = false,
  preferScrollableBody = false,
  isTvDevice = false,
  autoFocus = true,
  bodyScrollLock = true,
  portalElementId = DEFAULT_PORTAL_ELEMENT_ID,
  //.Back button props
  backButtonIcon = <MaterialChevronLeftSvg />,
  backAriaLabel = 'back',
  onBack,
  // Close button props
  closeButtonIcon = <IconClose />,
  closeButtonLabel = 'Close',
  closeAriaLabel = 'close',
  isCloseButtonOnFirstPosition = true,
  hideCloseButton = false,
  onClose,
  // Additional styling
  classNameBackdrop,
  classNameContainer,
  classNameHeader,
  classNameBody,
  // Accessibility
  'aria-labelledby': ariaLabelledBy,
  ...a11y
}: ModalV2Props): JSX.Element {
  const ModalRef = useRef<HTMLDivElement>(null);

  useFocusTrap(ModalRef, { enabled: !isTvDevice, autoFocus });
  useBodyNoScroll({
    ignore: !bodyScrollLock,
    suffix: ['modalv2', id?.toLocaleLowerCase().trim()]
      .filter(Boolean)
      .join('-'),
  });

  const handleClose = useCallback(() => {
    onClose?.();
    if (idFrom) {
      focusFromElement(idFrom);
    }
  }, [onClose, idFrom]);

  // Listen to keyboard key Back/Esc to close modal
  useKeyboardEvent({
    keycode: KEYCODE.ESCAPE,
    listen: !isTvDevice && Boolean(onClose),
    triggerAction: handleClose,
  });

  const renderButtonClose = () => {
    return (
      onClose &&
      !hideCloseButton && (
        <ButtonIcon
          icon={closeButtonIcon}
          variant="default"
          onClick={handleClose}
          aria-label={closeAriaLabel}
          className={cx('ModalV2__buttonIcon', 'ModalV2__buttonIcon--close')}
        />
      )
    );
  };

  return createPortal(
    <div
      id={id}
      role={role}
      data-testid="ModalV2"
      className={cx(
        'ModalV2',
        {
          [`ModalV2--${size}`]: size,
          'ModalV2--no-padding': hidePadding,
          'ModalV2--scrollable-body': preferScrollableBody,
        },
        className,
      )}
      aria-modal="true"
      aria-labelledby={
        ariaLabelledBy || (title ? 'modalv2-idLabelledby' : undefined)
      }
      {...a11y}
    >
      <div
        data-testid="ModalV2-backdrop"
        className={cx('ModalV2__backdrop', classNameBackdrop)}
        onClick={(e) => {
          e.stopPropagation();
          handleClose();
        }}
      />
      <div
        ref={ModalRef}
        className={cx(
          'ModalV2__container',
          {
            [`ModalV2__container--${size}`]: size,
            'ModalV2__container--no-padding': hidePadding,
            'ModalV2__container--scrollable-body': preferScrollableBody,
          },
          classNameContainer,
        )}
        onClick={(e) => e.stopPropagation()}
      >
        {title && (
          <div
            className={cx(
              'ModalV2__header',
              {
                [`ModalV2__header--${size}`]: size,
                'ModalV2__body--no-padding': hidePadding,
              },
              classNameHeader,
            )}
          >
            <h1 id="modalv2-idLabelledby" className={cx('ModalV2__title')}>
              {title}
            </h1>
          </div>
        )}

        {isCloseButtonOnFirstPosition && renderButtonClose()}

        <div
          id={idBody}
          className={cx(
            'ModalV2__body',
            {
              [`ModalV2__body--${size}`]: size,
              'ModalV2__body--no-padding': hidePadding,
              'ModalV2__body--no-padding-top': !!title,
              'ModalV2__body--scrollable-body': preferScrollableBody,
            },
            classNameBody,
          )}
        >
          {children}
          {isTvDevice && onClose && !hideCloseButton && (
            <Button variant="secondary" width="full" onClick={handleClose}>
              {closeButtonLabel}
            </Button>
          )}
        </div>

        {!isCloseButtonOnFirstPosition && renderButtonClose()}

        {onBack && (
          <ButtonIcon
            icon={backButtonIcon}
            variant="default"
            onClick={onBack}
            aria-label={backAriaLabel}
            className={cx('ModalV2__buttonIcon', 'ModalV2__buttonIcon--back')}
          />
        )}
      </div>
    </div>,
    document.getElementById(portalElementId) || document.body,
  );
}
