import type { HeaderWithSearchLayoutProps } from '@canalplus/mycanal-sharedcomponent';
import { HeaderWithSearchLayout } from '@canalplus/mycanal-sharedcomponent';
import type { JSX } from 'react';
import { useSelector } from 'react-redux';
import { useActionCreators } from '../../../helpers/hooks/useActionCreators';
import { useTranslation } from '../../../lang';
import { closeSearch, querySearch } from '../../../store/slices/search';
import {
  isSearchActiveSelector,
  searchQuerySelector,
} from '../../../store/slices/search-selectors';

export function HeaderWithSearchLayoutOneDiscoveryConnected(
  props: Omit<
    HeaderWithSearchLayoutProps,
    | 'onClose'
    | 'onSearch'
    | 'dark'
    | 'isSearchOpen'
    | 'searchDefaultQuery'
    | 'closeAriaLabel'
    | 'searchAriaLabel'
  >,
): JSX.Element {
  const { t } = useTranslation();

  const isSearchOpen = useSelector(isSearchActiveSelector);
  const searchDefaultQuery = useSelector(searchQuerySelector);

  const actions = useActionCreators({
    onClose: closeSearch,
    onSearch: querySearch,
  });

  const closeAriaLabel = t('Search.closeSearchTriggerLabel');
  const searchAriaLabel = t('Search.searchInputLabel');

  return (
    <HeaderWithSearchLayout
      {...props}
      {...actions}
      closeAriaLabel={closeAriaLabel}
      isSearchOpen={isSearchOpen}
      searchAriaLabel={searchAriaLabel}
      searchDefaultQuery={searchDefaultQuery}
    />
  );
}
