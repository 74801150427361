import { useEffect, useRef, useState, type RefObject } from 'react';
import { throttle } from '../../../helpers/helpers';

/**
 * Helper that checks if the element content is overflowing.
 * @param element - The element to check.
 * @returns True if the element's content overflows.
 */
const isElementContentOverflowing = (element: HTMLElement): boolean => {
  return element.offsetWidth < element.scrollWidth;
};

export type UseTruncatedDetectorProps = {
  /** Element to watch */
  ref: RefObject<HTMLElement | null>;
  /** Container of the element to watch */
  /**
   * Function to compute the available space in the container. Up to the consumer to
   * calculate the available space based on the container and its children.
   * @returns The available space in the container
   * @default () => 0
   */
  computeAvailableSpace?: () => number;
  /** Boolean to enable/disable the hook */
  enabled?: boolean;
};

/**
 * Hook that detects if an element overflows outside of its own container, by checking
 * the refs scrollWidth and container's available space.
 *
 * @param ref - The element to watch.
 * @param computeAvailableSpace - Function to compute the available space in the container. Up to the consumer to
 * calculate the available space based on the container and its children (defaults to 0)
 * @param enabled - Boolean to enable/disable the hook.
 * @returns True if the element overflows outside its container
 *
 * @example
 *  const subscribeLabelRef = useRef<HTMLSpanElement>(null);
 *  const isOverflowingOnMobile = useIsOverflowing({
 *    ref: subscribeLabelRef,
 *    enabled: isMobile,
 *  });
 */
export const useIsOverflowing = ({
  ref,
  computeAvailableSpace = () => 0,
  enabled = true,
}: UseTruncatedDetectorProps): boolean => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const elementFullWidth = useRef<number>(0);
  const prevIsOverflowing = useRef<boolean>(false);

  // Check if the ref is
  useEffect(() => {
    if (!enabled || !ref?.current) {
      setIsOverflowing(false);
      return;
    }

    const element = ref.current;

    // We store the full width of the label at the first render
    if (!elementFullWidth.current && element?.scrollWidth) {
      elementFullWidth.current = element?.scrollWidth;
    }

    const computeOverflow = throttle(() => {
      let isCurrentlyOverflowing = false;
      // Check if the element is truncated with ellipsis
      if (element) {
        // If it is already truncated, we don't need to check again, instead,
        // we rely on the hasEnoughSpace check
        if (prevIsOverflowing.current) {
          const labelWidth =
            elementFullWidth.current || element?.scrollWidth || 0;
          // Calculate remaining space in the container
          const containerAvailableWidth = computeAvailableSpace();
          const hasEnoughSpace = labelWidth <= containerAvailableWidth;
          isCurrentlyOverflowing = !hasEnoughSpace;
        } else {
          // Since not currently truncated, we check if it is now
          isCurrentlyOverflowing = isElementContentOverflowing(element);
        }
      }
      prevIsOverflowing.current = isCurrentlyOverflowing;
      setIsOverflowing(isCurrentlyOverflowing);
    }, 250);

    computeOverflow();
    window.addEventListener('resize', computeOverflow);
    return () => {
      computeOverflow.cancel();
      window.removeEventListener('resize', computeOverflow);
    };
  }, [
    ref,
    enabled,
    elementFullWidth,
    prevIsOverflowing,
    setIsOverflowing,
    computeAvailableSpace,
  ]);

  return isOverflowing;
};
