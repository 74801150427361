import type { ApiV2Profile } from '@dce-front/hodor-types/api/v2/me/profiles/definitions';
import { addHeader } from '@dce-front/util-fetch';
import type { HodorApiParameter, HodorSdkConfig } from '../config/types';
import { fetchHodor } from '../helpers/fetchHodor';

export const HODOR_API_PARAMETERS: HodorApiParameter[] = [
  { id: 'tokenPass', in: 'header' },
];

const API = 'saveProfile';

export type SaveProfileParameters = {
  body: BodyInit;
  url: string;
};

export async function saveProfile(
  hodorSdkConfig: HodorSdkConfig,
  { body, url }: SaveProfileParameters,
): Promise<ApiV2Profile> {
  const { logger } = hodorSdkConfig;
  const startAt = Date.now();

  const response = await fetchHodor({
    api: API,
    body,
    hodorSdkConfig: {
      ...hodorSdkConfig,
      fetchOptions: {
        ...hodorSdkConfig.fetchOptions,
        headers: addHeader(
          hodorSdkConfig.fetchOptions?.headers,
          'Content-Type',
          'application/json',
        ),
        method: 'PUT',
      },
    },
    hodorSdkParameters: HODOR_API_PARAMETERS,
    startAt,
    url,
  });

  const jsonResponse = await response.json();

  logger.debug(`HODOR ${API} Response:`, jsonResponse);

  return jsonResponse;
}
