import { PlayerPlatform } from '../../../constants/playerPlatforms';
import type { VideoContent } from '../types';

export const isVideoEncrypted = (video: VideoContent): boolean =>
  video.encryption !== 'clear';

/**
 * This helper retrieves the correct video to be used depending on the encryption and formats available.
 * It should follow these rules in the following order:
 *  1. Choose encryption: not encrypted video (encryption field equals `clear`) before others
 *  2. Choose format: dash (when not iOS) > hls > mp4 (default)
 * @param videos - array of videos with for each the encryption and format
 * @returns the video object
 */
export const getBestVideoContent = (
  videos: Omit<VideoContent, 'contentID'>[] = [],
  isIOS = false,
): VideoContent | undefined => {
  if (!videos.length) {
    return;
  }

  // Sort videos array based on rules
  const [firstVideoContent] = [...videos].sort((currentVideo, nextVideo) => {
    const isCurrentVideoEncrypted = isVideoEncrypted(currentVideo);
    const isNextVideoEncrypted = isVideoEncrypted(nextVideo);

    if (isCurrentVideoEncrypted !== isNextVideoEncrypted) {
      // Rule 1 : Choose not encrypted videos before others
      return isCurrentVideoEncrypted ? 1 : -1;
    } else if (currentVideo.format !== nextVideo.format) {
      // Rule 2 (iOS): Avoid videos with 'dash' format when user is on iOS
      if (isIOS) {
        if (currentVideo.format === 'dash') {
          return 1;
        }
      } else {
        if (currentVideo.format === 'dash') {
          return -1;
        }
        if (nextVideo.format === 'dash') {
          return 1;
        }
      }

      // Rule 2: Choose videos with 'dash' format before 'hls' format, and 'hls' before 'mp4'
      if (currentVideo.format === 'hls') {
        return -1;
      } else if (nextVideo.format === 'hls') {
        return 1;
      } else {
        return 0;
      }
    } else {
      // Rule 3: Choose first rule before the second
      return 0;
    }
  });

  // Return the first video of the sorted array
  return firstVideoContent;
};

/**
 * This helper retrieves the correct player platform to be used depending on the encryption and formats available.
 * @returns the player format
 */
export const getPlayerPlatform = (
  videoContent: VideoContent,
  isIOS = false,
): PlayerPlatform | 'manual-hls' => {
  if (videoContent.encryption === 'encrypted') {
    return PlayerPlatform.Hapi;
  }

  // TODO[PromotionStrates]: remove endsWith, in waiting of an other way to determine the video format
  // .m3u8 = hls
  if (
    (videoContent.format === 'hls' ||
      videoContent.URLVideo?.endsWith('.m3u8')) &&
    !isIOS
  ) {
    return 'manual-hls';
  }

  return PlayerPlatform.Directfile;
};
