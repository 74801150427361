import loadable from '@loadable/component';
import Spinner from '../../components/Spinner/Spinner';

const LoadableShowcase = loadable(
  () => import('./components/ShowcaseContainer'),
  {
    fallback: <Spinner />,
  },
);

export default LoadableShowcase;
