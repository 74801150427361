import { fillTemplate } from '@dce-front/onewebapp-utils';
import type { AcmSdkConfig } from '../config/types';
import type { AcmApi } from '../constants/paths';
import { acmApiPath } from '../constants/paths';

type GetApiUrlParameters = {
  api: AcmApi;
} & Pick<AcmSdkConfig, 'endpoint' | 'offerLocation' | 'offerZone'>;

/**
 * Get filled url from **endpoint** + raw **path**
 *
 * Replace placeholders:
 * - **{appLocation}**
 * - **{offerZone}**
 */
export function getFilledApiUrl({
  api,
  offerLocation,
  offerZone,
  endpoint,
}: GetApiUrlParameters): string {
  const path = acmApiPath[api];
  const url = `${endpoint}${path}`;

  return fillTemplate(url, [
    ['offerLocation', offerLocation],
    ['offerZone', offerZone],
  ]);
}
