import { addQueriesParam, fillTemplate } from '@dce-front/onewebapp-utils';
import type { AppKey } from '../../../../../../config/application/types';
import { getPublicConfig } from '../../../../../helpers/config/config-helper';

export const getListProfileUrl = (appKey: AppKey): string => {
  const {
    baseUrl,
    initParameters: { allowedProfiles },
    profileParameters: { profilesTemplateVersion },
    paths: { profileUrl },
  } = getPublicConfig().api.hodor;

  const url = `${fillTemplate(baseUrl, [['appKey', appKey]])}/${profileUrl}`;

  return addQueriesParam(url, [
    ['allowedProfiles', allowedProfiles],
    ['profilesTemplateVersion', profilesTemplateVersion],
  ]);
};
