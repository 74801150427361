import type { MiddlewareFactory } from '@dce-front/one-navigation';
import { useMemo } from 'react';
import { getFeatureToggleNewTVNavigation } from '../../../store/slices/application-selectors';
import { useInvariantSelector } from '../useInvariantSelector';

export const useBinderMiddleware = (
  middleware: (featNewTVNavigation: boolean) => MiddlewareFactory[],
) => {
  const featNewTVNavigation = useInvariantSelector(
    getFeatureToggleNewTVNavigation,
  );

  return useMemo(() => {
    return middleware(featNewTVNavigation);
  }, [featNewTVNavigation, middleware]);
};
