export type DiveState = {
  /**
   * A boolean indicating if the app is rendered on a TV device
   */
  isTv?: boolean;
  /**
   * The type of `DiveDevice` the app is rendered on (e.g.: `"web"`, `"tv"`)
   */
  device?: DiveDevice;
};

export type DiveContextProviderProps = {
  children: React.ReactElement | React.ReactNode;
} & DiveState;

export type DiveSvgProps = React.SVGProps<SVGSVGElement> & {
  title?: string;
  description?: string;
} & DiveProps;

export type DiveSVG = React.FC<DiveSvgProps>;

export type DiveProps = {
  /**
   * **⚠️ Targets the component's top-level HTML element.**
   *
   * The `data-tv-focusable` attribute enables visual focus management for TV navigation systems.
   * When set to `true`, the component becomes focusable using a remote control's navigation keys
   * (e.g., arrow keys) or other non-traditional input methods.
   *
   * Typically used with TV navigation libraries to visually highlight non-natively actionable elements
   * (e.g., `div`, `span`) or to apply custom visual behavior for actionable components,
   * enhancing user's TV experience.
   *
   * @example
   * ```tsx
   * <DiveComponent data-tv-focusable />
   * ```
   *
   * @default undefined
   */
  'data-tv-focusable'?: boolean;

  /**
   * **⚠️ Targets the component's top-level HTML element.**
   *
   * The `data-tv-focusmode` attribute specifies how focus should be handled for components with
   * `data-tv-focusable="true"`. It determines the mechanism used to indicate focus state.
   *
   * This attribute provides flexibility to TV navigation libraries to customize how focus
   * is visually represented and technically implemented in TV navigation scenarios.
   *
   * @example
   * ```tsx
   * // Using attribute-based focus indication
   * <DiveComponent
   *   data-tv-focusable
   *   data-tv-focusmode="attribute"
   * />
   * ```
   *
   * @default undefined
   */
  'data-tv-focusmode'?: 'attribute';

  /**
   * **⚠️ Targets the component's top-level HTML element.**
   *
   * Component test identifier.
   */
  'data-testid'?: string;

  /**
   * **⚠️ Targets the component's top-level HTML element.**
   *
   * The `className` prop allows consumers to easily add margins or padding to DIVE components.
   *
   * **⚠️ INTENDED FOR LAYOUT ADJUSTMENTS, NOT FOR OVERRIDING DIVE COMPONENT STYLES.**
   *
   * @example
   * ```css
   * .custom-layout {
   *   margin: 16px;
   *   padding: 8px;
   * }
   * ```
   *
   * ```tsx
   * // Layout styles are passed via the `className` prop to the DIVE component
   * <DiveComponent className="custom-layout" />
   * ```
   */
  className?: string;
};

export enum DiveTheme {
  MyCanalDark = 'mycanal dark',
  MyCanalLight = 'mycanal light',
  BusinessDark = 'business dark',
  BusinessLight = 'business light',
  VODDark = 'vod dark',
  VODLight = 'vod light',
  VODaltDark = 'vodAlt dark',
  VODaltLight = 'vodAlt light',
  TelecomItalia = 'telecomitalia',
}

export enum DiveFontFamily {
  Canal = 'canal',
  Hind = 'hind',
}

export enum DiveDevice {
  Web = 'web',
  Tv = 'tv',
}

export enum DivePseudoClass {
  Default = 'default',
  Hover = 'hover',
  Active = 'active',
  Disabled = 'disabled',
  Focus = 'focus',
}

export enum DiveIconPosition {
  Left = 'left',
  Right = 'right',
}

/** A component's background color variant */
export enum DiveBackgroundVariant {
  Solid = 'solid',
  Ghost = 'ghost',
}

export enum DiveBreakpoint {
  Xxs = 375,
  Xs = 420,
  Sm = 700,
  Md = 960,
  Lg = 1285,
  Xl = 1600,
  Xxl = 1920,
}

export type DiveViewport =
  | 'tv1080p'
  | 'tv720p'
  | 'XLarge'
  | 'large'
  | 'medium'
  | 'small'
  | 'XSmall';

export type DiveDeviceMediaImage = 'default' | 'tablet' | 'laptop' | 'mobile';

export type DiveViewportsWithDevice = {
  viewport: DiveViewport;
  device?: DiveDeviceMediaImage;
  mediaQuery?: string;
}[];

/**
 * Enum representing key values for React.KeyboardEvent.key.
 * This enum is used to define key names that correspond to keyboard events.
 *
 * Note: Currently, this includes only the necessary keys,
 * but feel free to add more key names if needed.
 */
export enum KeyboardEventKey {
  ArrowDown = 'ArrowDown',
  ArrowUp = 'ArrowUp',
  ArrowRight = 'ArrowRight',
  ArrowLeft = 'ArrowLeft',
  Backspace = 'Backspace',
}
