import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../helpers/hooks/useAppDispatch';
import { getAllProfiles } from '../../../services/profiles/profileStorageService';
import {
  getFeatureToggleProfile,
  shouldDisplayWhoIsWatchingSelector,
} from '../../../store/slices/application-selectors';
import { userListProfile, userListProfiles } from '../../../store/slices/user';
import { profilesUpdateDateSelector } from '../../../store/slices/user-selectors';

/**
 * Hook to initialize list profiles.
 * Get list profiles from localStorage and store it in redux
 */
export const useInitProfiles = (): void => {
  const dispatch = useAppDispatch();

  const featProfile = useSelector(getFeatureToggleProfile);
  const shouldDisplayWhoIsWatching = useSelector(
    shouldDisplayWhoIsWatchingSelector,
  );
  const profilesUpdateDate = useSelector(profilesUpdateDateSelector);

  useEffect(() => {
    if (!featProfile && !shouldDisplayWhoIsWatching) {
      return;
    }

    const {
      currentProfile,
      profiles: profilesLocalStorage,
      profilesUpdateDate: profilesUpdateDateStored,
    } = getAllProfiles();

    if (
      profilesUpdateDate === profilesUpdateDateStored &&
      currentProfile &&
      profilesLocalStorage
    ) {
      dispatch(userListProfiles(profilesLocalStorage));
      dispatch(userListProfile(currentProfile));
    }
  }, [shouldDisplayWhoIsWatching, profilesUpdateDate, featProfile, dispatch]);
};
