import loadable from '@loadable/component';
import Spinner from '../Spinner/Spinner';

const LoadableAdultDisclaimerConnected = loadable(
  () => import('./AdultDisclaimer'),
  {
    fallback: <Spinner />,
  },
);

export default LoadableAdultDisclaimerConnected;
