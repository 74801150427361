import type { ErrorInfo, ReactNode } from 'react';
import { Component } from 'react';
import LoadableGenericError from '../GenericError/LoadableGenericError';

export type ErrorBoundaryProps = {
  children?: ReactNode;
  /** Shown when the error boundary encounters an error */
  fallback?: ReactNode;
  /**
   * Called when the error boundary encounters an error
   * If not passed in then the default behaviour would just log the error
   */
  onError?: (error: Error, info: ErrorInfo) => void;
};

type ErrorBoundaryStates = {
  hasError: boolean;
};

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryStates
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static defaultProps = {
    children: undefined,
    fallback: (
      <LoadableGenericError
        title="ErrorMessages.genericErrorTitle"
        text="ErrorMessages.genericErrorTxt"
      />
    ),
    onError: undefined,
  };

  static getDerivedStateFromError(): ErrorBoundaryStates {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo): void {
    console.error('Error while rendering template.', { error, info });
    if (this.props.onError) {
      this.props.onError(error, info);
    }
  }

  render(): ReactNode {
    if (this.state.hasError && this.props.fallback) {
      return this.props.fallback;
    }
    return this.props.children;
  }
}
