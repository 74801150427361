import loadable from '@loadable/component';
import Spinner from '../../components/Spinner/Spinner';

const LoadableSettingsConnected = loadable(
  () => import('./components/SettingsContainer'),
  {
    fallback: <Spinner />,
  },
);

export default LoadableSettingsConnected;
