import { SafetyCodeContext } from '@canalplus/types-acm';
import { useStore } from '@dce-front/one-navigation';
import type { JSX } from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useInvariantSelector } from '../../helpers/hooks/useInvariantSelector';
import {
  FocusManager,
  type HistoryFocusState,
} from '../../helpers/oneNavigation/FocusManager';
import {
  activeAdultSelector,
  adultBrowsingSelector,
} from '../../store/slices/adult-selectors';
import { getFeatureToggleFocusRestoration } from '../../store/slices/application-selectors';
import {
  immersiveDisplayNameSelector,
  immersiveDisplayTemplateSelector,
  immersiveParametersSelector,
  immersiveURLPageSelector,
  isImmersiveSelector,
} from '../../store/slices/immersive-selectors';
import { pageSelector } from '../../store/slices/page-selectors';
import LoadableAdultDisclaimerConnected from '../AdultDisclaimer/LoadableAdultDisclaimerConnected';
import Immersive from '../Immersive/Immersive';
import Player from '../PlayerFullScreen/Player';
import Template from '../Template/Template';
import { RoutingContextProvider } from './RoutingContext';

const ImmersiveModal = ({ focusManager }: { focusManager?: FocusManager }) => {
  const isImmersive = useSelector(isImmersiveSelector);
  const displayName = useSelector(immersiveDisplayNameSelector);
  const displayTemplate = useSelector(immersiveDisplayTemplateSelector);
  const immersiveParameters = useSelector(immersiveParametersSelector);
  const URLPage = useSelector(immersiveURLPageSelector);

  if (!isImmersive) {
    return null;
  }

  return (
    <Immersive>
      <RoutingContextProvider routingContext="immersive">
        <Template
          displayName={displayName}
          displayTemplate={displayTemplate}
          focusManager={focusManager}
          parameters={immersiveParameters}
          URLPage={URLPage}
        />
      </RoutingContextProvider>
    </Immersive>
  );
};

function Page(): JSX.Element {
  const history = useHistory<HistoryFocusState>();
  const page = useSelector(pageSelector);

  const featFocusRestoration = useInvariantSelector(
    getFeatureToggleFocusRestoration,
  );

  const adult = {
    active: useSelector(activeAdultSelector),
    adultBrowsing: useSelector(adultBrowsingSelector),
  };

  // if we are in adult context and parental code not yet validated we display the adult disclaimer
  const showAdultDisclaimer = page?.mainOnClick?.adult && !adult.adultBrowsing;

  // onFocusable should be called by the underlying template when it is loaded and first binder has focusable elements
  // we use that at page level to call focusDefault() and set focus
  const store = useStore();
  const focusManager = useMemo(() => {
    return new FocusManager(store, history, featFocusRestoration);
  }, [store, history, featFocusRestoration]);

  return (
    <>
      <ImmersiveModal focusManager={focusManager} />
      {!showAdultDisclaimer ? (
        <RoutingContextProvider routingContext="page">
          <Template
            animationEnded
            displayName={page?.mainOnClick?.displayName}
            displayTemplate={page?.mainOnClick?.displayTemplate}
            focusManager={focusManager}
            perso={page?.mainOnClick?.perso}
            parameters={page?.mainOnClick?.parameters}
            URLPage={page?.mainOnClick?.URLPage}
          />
        </RoutingContextProvider>
      ) : (
        <LoadableAdultDisclaimerConnected
          safetyCodeContext={SafetyCodeContext.Parental}
        />
      )}

      <Player />
    </>
  );
}

export default Page;
