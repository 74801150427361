import { setContext, trackSendPageView } from '@canalplus/ifc-onecore';
import type { OfferLocation, Platform } from '@canalplus/sdk-core';
import { Template } from '@canalplus/sdk-hodor';
import type { LocationDescriptorObject } from 'history';
import type { MouseEvent, SyntheticEvent } from 'react';
import { TemplateTypes } from '../../../../constants/templateTypes';
import { ThemeColor } from '../../../../constants/themeColor';
import { clientSideOneShopRedirection } from '../../../../helpers/user/user-helper';
import { handleLaunchExternalService } from '../../../../store/reducers/actions/ExternalServices-actions';
import { launchPlayerFullScreen } from '../../../../store/slices/player-thunk';
import type { AppHistory, LocationState } from '../../../../typings/routing';
import type { LinkerData, LinkerProps } from '../../types';

/**
 * Dispatch the good actions for TEMPLATE.EXTERNAL_SITE
 * @param e event click
 * @param linkerProps
 * @param dispatch
 * @param platform
 * @param offerLocation
 */

const onClickTemplateExternal = ({
  e,
  linkerProps,
  dispatch,
  platform,
  offerLocation,
}: {
  e: MouseEvent<HTMLAnchorElement | HTMLButtonElement> | SyntheticEvent;
  linkerProps: LinkerProps;
  dispatch: Redux.Dispatch;
  platform: Platform;
  offerLocation: OfferLocation;
}): void => {
  const { data, target = '_blank' } = linkerProps;
  const { contentID, mainOnClick: onClickHodor, trackingContext } = data || {};
  const {
    serviceID,
    URLPage,
    URLWebsite,
    target: onClickTarget,
  } = onClickHodor || {};

  e.preventDefault();

  dispatch(
    handleLaunchExternalService({
      // We should always use the contentID given in the onClick for deeplinks, the contentID on content is technical
      contentID: $_BUILD_RENDERMODE_CSR ? onClickHodor?.contentID : contentID,
      serviceID,
      URLPage,
      URLWebsite,
      trackingContext: trackingContext || onClickHodor?.trackingContext,
      target: onClickTarget || target,
      platform,
      offerLocation,
    }),
  );
};

/**
 * Generate the function onClick to execute for linker data `linkerProps`
 * @param linkerProps
 * @param dispatch
 * @param isNextEpisodeAutoplay
 * @param offerZone
 * @param offerLocation
 * @param platform
 * @returns Function onClick to execute for linker data `linkerProps`
 */
export const getOnClickGenerated =
  (
    linkerProps: LinkerProps,
    dispatch: Redux.Dispatch,
    offerLocation: OfferLocation,
    platform: Platform,
    history: AppHistory,
  ) =>
  (
    e: MouseEvent<HTMLAnchorElement | HTMLButtonElement> | SyntheticEvent,
  ): void => {
    const { data, onClick } = linkerProps;
    const { mainOnClick: onClickHodor } = data || {};

    const { displayTemplate, serviceID } = onClickHodor || {};

    /**
     * Link to linker settings onClick
     *
     * When we leave our application we send context lastUrl to oneCore
     * It allows to start application with an history used by R7ExtLib
     *
     * workflow for all linker component ex: detail and banner
     */
    if (
      $_BUILD_RENDERMODE_CSR &&
      displayTemplate === Template.ExternalSite &&
      serviceID
    ) {
      setContext({
        context: {
          lastUrl: [history.location.pathname || '/'],
        },
      });
    }

    if (!onClickHodor && !onClick) {
      return e.preventDefault();
    }

    if (onClick) {
      onClick(e);
    }

    if (displayTemplate === Template.ExternalSite) {
      onClickTemplateExternal({
        e,
        linkerProps,
        dispatch,
        platform,
        offerLocation,
      });
      return;
    }

    if (displayTemplate === TemplateTypes.LAUNCH_ONE_SHOP) {
      clientSideOneShopRedirection(onClickHodor?.contentID);
      return;
    }
  };

/**
 * Navigate to a link using router
 * @param history Router History
 * @param to LocationDescriptorObject information of the target to navigate to
 * @param replace Indicate if replace or push in history
 */
export const redirectRouter = (
  history: AppHistory,
  to: LocationDescriptorObject<LocationState>,
  replace?: boolean,
): void => {
  /* @todo use redirect from "react-router-dom" when we bump to react-router v6 */
  if (replace) {
    history.replace({ pathname: to.pathname, search: to.search }, to.state);
  } else {
    history.push({ pathname: to.pathname, search: to.search }, to.state);
  }
};

export const launchPlayerLinker = ({
  event,
  data,
  isKids,
  brand,
  objKey,
  dispatch,
}: {
  event: SyntheticEvent;
  data?: LinkerData;
  isKids: boolean;
  brand: string;
  objKey: string;
  dispatch: Redux.Dispatch;
}) => {
  dispatch(
    launchPlayerFullScreen({
      event,
      data,
      type: objKey,
    }),
  );
  const { trackingContext } = data?.mainOnClick || {};
  const launchPlayerPageName = `${brand} - Live TV - Lecture Player Live`;

  if ($_BUILD_RENDERMODE_CSR) {
    // TODO: Delete this condition when OneCore Will be ready to handle this case
    trackSendPageView({
      data: {
        ...trackingContext,
        ...(trackingContext?.context_type && {
          prop17: trackingContext?.context_type,
        }),
        ...(trackingContext?.contextDetail && {
          prop18: trackingContext?.contextDetail,
        }),
        eVar37: 'Live',
        eVar38: 'Regarder Live',
        themeColor: ThemeColor.Dark,
        profile_kids: isKids,
        page_name: launchPlayerPageName,
      },
      name: launchPlayerPageName,
    });
  }
};
