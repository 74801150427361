import type { JSX } from 'react';
import { useDiveContext } from '../../context';
import {
  headerCVA,
  headerContentCVA,
  headerLogoCVA,
  headerPrimaryNavCVA,
  headerSecondaryNavCVA,
} from './Header.cva';
import type { HeaderProps } from './Header.types';

/**
 * A header component to display at the top of the page. It is essentially composed of:
 * - A **logo** component to display in the header (e.g. `<HeaderLogo />`).
 * - A **primary navigation** component to display in the header (e.g. `<HeaderNav />`).
 * - A **secondary navigation** component to display in the header (e.g. `<Search /> + <Button /> + <HeaderUserDropdown />`).
 *
 * @example
 *
 * ```tsx
 * <Header
 *    logo={<HeaderLogo ... />}
 *    primaryNav={<HeaderNav ... />}
 *    secondaryNav={<>
 *      <Search ... />
 *      <SubscribeButton ... \>
 *      <HeaderUserDropdown ... />
 *    </>}
 * />
 * ```
 */
export function Header({
  logo,
  primaryNav,
  secondaryNav,
  isFixed = true,
  isTransparent = false,
  'data-testid': dataTestId,
  id,
  className,
}: HeaderProps): JSX.Element {
  const { device } = useDiveContext();
  return (
    <header
      role="banner"
      data-testid={dataTestId}
      id={id}
      className={headerCVA({ device, isFixed, isTransparent, className })}
    >
      <div className={headerContentCVA({ device })}>
        {logo && <div className={headerLogoCVA({ device })}>{logo}</div>}
        {primaryNav && (
          <div className={headerPrimaryNavCVA()}>{primaryNav}</div>
        )}
        {secondaryNav && (
          <div className={headerSecondaryNavCVA({ device })}>
            {secondaryNav}
          </div>
        )}
      </div>
    </header>
  );
}
