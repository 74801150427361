import { getRootDomain, setCookie } from '@dce-front/onewebapp-utils';
import type { Response } from 'express';
import { CookieKey } from '../../constants/cookie';
import { getPublicConfig } from '../config/config-helper';
import Logger from '../logger/logger-helper';

const tokenCmsCookiePath = '/';

/**
 * Define the tokenCMS cookie, needed for Akamai Cache
 */
export function setTokenCMS(
  /** The CMS token value */
  token: string,
  /** The hostname on which to apply cookie */
  hostname: string = '',
  /** The Express response to attach the cookie on */
  response?: Response,
): void {
  if (response) {
    const domain = getRootDomain(hostname);

    const options = {
      domain,
      httpOnly: false,
      maxAge: getPublicConfig().cookie.maxAge * 24 * 60 * 60 * 1000, // 30 days
      path: tokenCmsCookiePath,
    };

    response.cookie(CookieKey.TokenCMS, token, options);

    Logger.verbose(
      `Cookie ${CookieKey.TokenCMS} defined with ${JSON.stringify(options)}`,
    );
  } else {
    setCookie(CookieKey.TokenCMS, token, {
      httpOnly: false,
      expires: getPublicConfig().cookie.maxAge,
    });

    Logger.verbose(`Cookie ${CookieKey.TokenCMS} defined`);
  }
}

/**
 * Define the recommendations cookie just for the session, needed for Akamai Cache
 */
export const setCookieRecommandations = (
  /** The reco boolean from Pass */
  reco: boolean,
  /** The Express response to attach the cookie on */
  response: Response,
  /** The hostname on which to apply cookie */
  hostname: string = '',
): void => {
  const domain = getRootDomain(hostname);

  const options = {
    domain,
    httpOnly: false,
    path: '/',
  };

  response.cookie(CookieKey.Recommandations, reco.toString(), options);

  Logger.verbose(
    `Cookie ${CookieKey.Recommandations} defined with ${JSON.stringify(options)}`,
  );
};
