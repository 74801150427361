import { Template } from '@canalplus/sdk-hodor';
import type { ApiV2OnClick } from '@dce-front/hodor-types/api/v2/common/dto/definitions';

export type PlayerContent = {
  channel?: {
    epgID: string | number;
  };
  contentID?: string;
  onClick?: ApiV2OnClick & { firstEpisodeID?: string };
};
/**
 * getContentID
 *
 * @param content properties from Card (required)
 * @returns contentID or EpgID for Player stringified
 */
export const getContentID = (content: PlayerContent): string | number => {
  // use second verification for Startover in LiveRow
  if (
    (content.onClick &&
      content.onClick.displayTemplate === Template.Player &&
      content.channel &&
      content.channel.epgID) ||
    (content.channel && content.channel.epgID)
  ) {
    return content.channel.epgID;
  }

  const firstEpisodeId = content?.onClick?.firstEpisodeID;
  const contentID = content.contentID;

  return firstEpisodeId || contentID || '';
};
