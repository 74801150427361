/**
 * Drives the characters that will be available on the keyboard
 *
 * - `Number` type keyboard will only have number keys available
 * - `Email` type keyboard will have special characters to ease email input
 */
export const Type = {
  Number: 'number',
  Email: 'email',
};

export type Type = (typeof Type)[keyof typeof Type];
