import {
  HEADER_ID,
  HeaderTheme,
  useHeaderTheme,
} from '@canalplus/mycanal-sharedcomponent';
import { Header } from '@dce-front/dive';
import { Binder } from '@dce-front/one-navigation';
import classNames from 'classnames/bind';
import type { JSX } from 'react';
import { useSelector } from 'react-redux';
import { useInvariantSelector } from '../../helpers/hooks/useInvariantSelector';
import { MIDDLEWARE_HEADER } from '../../helpers/oneNavigation/middleware';
import {
  isHeaderActiveOnTVSelector,
  isHeaderTransparentSelector,
} from '../../selectors/header-selectors';
import { featDisplayHeaderMenuSelector } from '../../store/slices/application-selectors';
import { isSearchActiveSelector } from '../../store/slices/search-selectors';
import HeaderLogoOneDiscovery from './HeaderLogoOneDiscovery/HeaderLogoOneDiscovery';
import HeaderNavigationOneDiscovery from './HeaderNavigationOneDiscovery/HeaderNavigationOneDiscovery';
import styles from './HeaderOneDiscovery.module.css';
import HeaderUserMenuOneDiscovery from './HeaderUserMenuOneDiscovery/HeaderUserMenuOneDiscovery';
import { HeaderWithSearchLayoutOneDiscoveryConnected } from './HeaderWithSearchLayoutOneDiscovery/HeaderWithSearchLayoutOneDiscoveryConnected';

const cx = classNames.bind(styles);

function HeaderOneDiscovery(): JSX.Element {
  const isHeaderTransparent = useSelector(isHeaderTransparentSelector);
  const isSearchActive = useSelector(isSearchActiveSelector);
  const isHeaderActiveOnTV = useSelector(isHeaderActiveOnTVSelector);
  const featDisplayHeaderMenu = useInvariantSelector(
    featDisplayHeaderMenuSelector,
  );

  const headerTheme = useHeaderTheme(isHeaderTransparent, isSearchActive);
  const isTransparent = headerTheme === HeaderTheme.TRANSPARENT;

  return $_BUILD_RENDERMODE_CSR ? (
    <Binder
      data-template="header"
      enabled={featDisplayHeaderMenu && isHeaderActiveOnTV}
      middleware={MIDDLEWARE_HEADER}
      data-nosnippet
    >
      <Header
        logo={<HeaderLogoOneDiscovery />}
        primaryNav={
          <HeaderNavigationOneDiscovery isTransparent={isTransparent} />
        }
        secondaryNav={<HeaderUserMenuOneDiscovery />}
        isTransparent={isTransparent}
        isFixed={false} // Header is not fixed on TV
      />
    </Binder>
  ) : (
    <HeaderWithSearchLayoutOneDiscoveryConnected>
      <div data-nosnippet>
        <Header
          id={HEADER_ID}
          logo={<HeaderLogoOneDiscovery />}
          primaryNav={
            <HeaderNavigationOneDiscovery isTransparent={isTransparent} />
          }
          secondaryNav={<HeaderUserMenuOneDiscovery />}
          isTransparent={isTransparent}
          isFixed={false} // Position fixed is managed by StickyContainer parent wrapper
          className={cx({ 'HeaderOneDiscovery--searchOpen': isSearchActive })}
        />
      </div>
    </HeaderWithSearchLayoutOneDiscoveryConnected>
  );
}

export default HeaderOneDiscovery;
