import { addUniqReactKey } from '@canalplus/mycanal-util-react';
import type { JSX } from 'react';
import styles from './Footer.module.css';
import Socials from './Socials/Socials';
import type { SocialLinks } from './getSocialLinks';
import { getSocialLinks } from './getSocialLinks';
import type { FooterLabels } from './i18n';

export type MainColumnContent = {
  links: React.ReactNode[][];
  title?: string;
};

export type FooterProps = {
  /** Whether to display or not the social links */
  isFeatSocialNetworksFooter?: boolean;

  /** Whether the social links are defined in BO */
  isSocialLinksFromBO?: boolean;

  /** The content of each column */
  mainColumnContent?: MainColumnContent[];

  /** Object with links of different socials media */
  socialLinks: SocialLinks;

  zoneSelector?: React.ReactNode;

  labels: FooterLabels;
};

export function Footer({
  isFeatSocialNetworksFooter = false,
  isSocialLinksFromBO = false,
  mainColumnContent,
  socialLinks,
  zoneSelector,
  labels,
}: FooterProps): JSX.Element {
  const { title, content, credits } = labels;
  const socials = getSocialLinks(isSocialLinksFromBO, socialLinks);

  return (
    <footer className={styles.footer} role="contentinfo">
      <div data-nosnippet>
        <ul className={styles.footer__columns}>
          {mainColumnContent?.map((column) => (
            <li key={column.title} className={styles.footer__column}>
              {column.title && (
                <div className={styles.footer__column_title}>
                  {column.title}
                </div>
              )}
              <div className={styles.footer__column_links}>
                {column.links.map((subCols) => {
                  const col = addUniqReactKey(subCols);
                  return (
                    <ul key={col.reactKey}>
                      {subCols.map((link) => {
                        const href = addUniqReactKey(link);
                        return (
                          <li
                            key={href.reactKey}
                            className={styles.footer__column_link}
                          >
                            {href}
                          </li>
                        );
                      })}
                    </ul>
                  );
                })}
              </div>
            </li>
          ))}
          <li key="lastColumn" className={styles.footer__last_column}>
            {isFeatSocialNetworksFooter && <Socials socials={socials} />}
            {zoneSelector && (
              <div className={styles.footer__zoneSelector}>{zoneSelector}</div>
            )}
            <p className={styles.footer__text}>
              {title}
              {content && title ? ', ' : ''}
              {content}
            </p>
            <div className={styles.footer__credits}>{credits}</div>
          </li>
        </ul>
      </div>
    </footer>
  );
}
