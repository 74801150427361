import {
  isDeviceToViewportToImageDimensions,
  type DiveViewport,
  type MediaImageDimensions,
  type ThumborSourcesDimensions,
} from '@dce-front/dive';
import { ResizeMode } from '@dce-front/onewebapp-utils';

/** Returns the default media image dimensions based on the resizeMode */
export const getDimensionsFromResizeMode = (
  dimensions: ThumborSourcesDimensions,
  isTvDevice?: boolean,
  resizeMode?: ResizeMode,
): MediaImageDimensions | undefined => {
  let defaultViewport: DiveViewport = 'large';

  if (isTvDevice) {
    if (resizeMode === ResizeMode.RESIZE_720P) {
      defaultViewport = 'tv720p';
    } else {
      defaultViewport = 'tv1080p';
    }
  }

  return isDeviceToViewportToImageDimensions(dimensions)
    ? dimensions.default?.[defaultViewport] || dimensions.default?.large
    : dimensions?.[defaultViewport] || dimensions?.large;
};
