import type { ApiV2WsFromPathError } from '../api/wsFromPath';

/**
 * `ApiV2WsFromPathError` type guard
 */
export const isApiV2WsFromPathError = (
  response: object,
): response is ApiV2WsFromPathError => {
  return (response as ApiV2WsFromPathError).errorStatusCode === 404;
};
