import type { DirectionKey } from '../constants/keys';
import type {
  IStoreBinder,
  Middleware,
  MiddlewareFactory,
  MiddlewareHooks,
  OriginElement,
} from '../store/types';
import { nearest } from '../utils/nearest';

/**
 * Instanciate a new spatial middleware
 *
 * @example
 *  const MIDDLEWARE = [spatial()];
 *
 *  function MyComponent() {
 *    return (
 *      <Binder middleware={MIDDLEWARE}>
 *        <button>hello</button>
 *      </Binder>
 *    );
 *  }
 *
 * @returns Spatial middleware factory
 */
export const spatial: Middleware =
  (): MiddlewareFactory =>
  (binder: IStoreBinder): MiddlewareHooks => {
    const focus = (direction: DirectionKey, origin: OriginElement) =>
      nearest(direction, origin, binder.getElements());

    return {
      focusEnter: focus,
      focusWithin: focus,
    };
  };
