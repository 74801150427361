import type { JSX } from 'react';

/**
 * Separator for the HeaderDropdown (NavDropdown or UserDropdown), used to separate
 * different sections of the dropdown.
 *  - A thin horizontal line
 *  - Full width of the dropdown
 *
 * @returns {JSX.Element} The separator component
 *
 * @example
 *
 * ```tsx
 * <HeaderDropdownSeparator />
 * ```
 */
export function HeaderDropdownSeparator(): JSX.Element {
  return (
    <hr className="w-full border-solid border-dt-border-none border-b-dt-border-thin border-dt-theme-border-profile-card-divider" />
  );
}
