import type { JSX } from 'react';
import { createContext, useContext, useMemo } from 'react';

export type DropdownContextProps = {
  isOpen: boolean;
  isNested: boolean;
  activeIndex: number | null;
  setActiveIndex: React.Dispatch<React.SetStateAction<number | null>>;
  getReferenceProps: (
    userProps?: React.HTMLProps<HTMLElement>,
  ) => Record<string, unknown>;
  getItemProps: (
    userProps?: React.HTMLProps<HTMLElement>,
  ) => Record<string, unknown>;
};

const DropdownContext = createContext<DropdownContextProps>({
  isOpen: false,
  isNested: false,
  activeIndex: null,
  setActiveIndex: () => {},
  getReferenceProps: () => ({}),
  getItemProps: () => ({}),
});

export function DropdownProvider({
  children,
  isOpen,
  isNested,
  activeIndex,
  setActiveIndex,
  getReferenceProps,
  getItemProps,
}: DropdownContextProps & {
  children?: React.ReactNode;
}): JSX.Element {
  const value = useMemo(
    () => ({
      isOpen,
      isNested,
      activeIndex,
      setActiveIndex,
      getReferenceProps,
      getItemProps,
    }),
    [
      isOpen,
      isNested,
      activeIndex,
      setActiveIndex,
      getItemProps,
      getReferenceProps,
    ],
  );
  return (
    <DropdownContext.Provider value={value}>
      {children}
    </DropdownContext.Provider>
  );
}

export const useDropdownContext = (): DropdownContextProps => {
  const context = useContext(DropdownContext);
  if (!context) {
    throw new Error(
      'Dropdown components cannot be rendered outside the Dropdown component',
    );
  }
  return context;
};
