import type { DirectionKey } from '../constants/keys';
import { KEY_DOWN, KEY_LEFT, KEY_RIGHT, KEY_UP } from '../constants/keys';
import type { IElement, OriginElement } from '../store/types';

type DOMRectKey = keyof Pick<DOMRect, 'top' | 'bottom' | 'left' | 'right'>;
type Edge = 'front' | 'back' | 'left' | 'right';

/**
 * Return DOMRect properties rotated according to direction vector
 * @param direction - Direction key
 * @returns Rotated properties
 *
 * @example
 *          -- direction -->
 *
 *              leftEdge
 *             +---------+
 *  backEdge   | element |    frontEdge
 *             +---------+
 *              rightEdge
 */
export function getEdges(direction: DirectionKey): Record<Edge, DOMRectKey> {
  switch (direction) {
    case KEY_UP:
      return { front: 'top', back: 'bottom', left: 'left', right: 'right' };
    case KEY_DOWN:
      return { front: 'bottom', back: 'top', left: 'left', right: 'right' };
    case KEY_LEFT:
      return { front: 'left', back: 'right', left: 'top', right: 'bottom' };
    case KEY_RIGHT:
    default:
      return { front: 'right', back: 'left', left: 'top', right: 'bottom' };
  }
}

/**
 * Find the nearest element to origin in a given direction
 * @param direction Direction
 * @param origin Origin elements
 * @param elements Target candidate elements
 * @returns An element if find, undefined otherwise
 */
export function nearest<T extends IElement>(
  direction: DirectionKey,
  origin: OriginElement,
  elements: T[],
): T | undefined {
  const originBounds = origin.getBoundingClientRect();

  let nearestElement: T | undefined;
  let nearestBounds: DOMRect | undefined;

  const { front, back, left, right } = getEdges(direction);
  const rev = direction === KEY_LEFT || direction === KEY_UP ? -1 : 1;

  for (let i = 0; i < elements.length; i += 1) {
    const element = elements[i];
    const bounds = element?.getBoundingClientRect();

    // Skip if element is origin
    if (element === origin) {
      continue;
    }

    // Is target front edge ahead of origin front edge ?
    const isAhead = (bounds?.[front] || 0) * rev > originBounds[front] * rev;
    // Is target back edge behind of origin front edge ? In this case we consider there is an
    // overlap relative to direction vector
    const isOverlappingAhead =
      (bounds?.[back] || 0) * rev < originBounds[front] * rev;
    // Is left / right edge of target between left & right edge of origin ? In this case we
    // consider an overlap perpendicular to direction vector
    const isOverlappingSides =
      ((bounds?.[left] || 0) <= originBounds[right] &&
        (bounds?.[left] || 0) >= originBounds[left]) ||
      ((bounds?.[right] || 0) <= originBounds[right] &&
        (bounds?.[right] || 0) >= originBounds[left]);

    // Skip if :
    // - Target is not ahead of origin
    // - Target is ahead of origin with overlapping relative to direction vector but without
    //   overlapping perpendicular to direction vector
    if (!isAhead || (isOverlappingAhead && !isOverlappingSides)) {
      continue;
    }

    // If no nearest element yet
    if (!nearestBounds) {
      nearestElement = element;
      nearestBounds = bounds;
      continue;
    }

    // Compute horizontal & vertical gaps relative to direction vector
    const nearestForwardDistance = Math.abs(
      originBounds[front] * rev - nearestBounds[back] * rev,
    );
    const currentForwardDistance = Math.abs(
      originBounds[front] * rev - (bounds?.[back] || 0) * rev,
    );
    const nearestPerpendicularDistance = Math.min(
      Math.abs(originBounds[left] - nearestBounds[left]),
      Math.abs(originBounds[right] - nearestBounds[right]) + 1,
    );
    const currentPerpendicularDistance = Math.min(
      Math.abs(originBounds[left] - (bounds?.[left] || 0)),
      Math.abs(originBounds[right] - (bounds?.[right] || 0)) + 1,
    );

    const forwardDiff = Math.abs(
      nearestForwardDistance - currentForwardDistance,
    );
    const perpendicularDiff = Math.abs(
      nearestPerpendicularDistance - currentPerpendicularDistance,
    );

    if (
      (currentForwardDistance < nearestForwardDistance &&
        perpendicularDiff <= forwardDiff) ||
      (currentPerpendicularDistance < nearestPerpendicularDistance &&
        perpendicularDiff > forwardDiff)
    ) {
      nearestElement = element;
      nearestBounds = bounds;
      continue;
    }
  }

  return nearestElement;
}
