import type { JSX, ReactNode } from 'react';
import { createContext } from 'react';

export const ShowcaseContext = createContext<boolean>(false);

export function ShowcaseContextProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  return (
    <ShowcaseContext.Provider value={true}>{children}</ShowcaseContext.Provider>
  );
}
