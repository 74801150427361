import type { JSX } from 'react';
import { createContext, useContext, useMemo } from 'react';
import type { MediaFooterPosition } from './Media.types';

export type MediaContextProps = {
  footerPosition?: `${MediaFooterPosition}`;
};

const MediaContext = createContext<MediaContextProps>({
  footerPosition: 'default',
});

export function MediaContextProvider({
  children,
  footerPosition,
}: MediaContextProps & {
  children?: React.ReactNode;
}): JSX.Element {
  const value = useMemo(
    () => ({
      footerPosition,
    }),
    [footerPosition],
  );
  return (
    <MediaContext.Provider value={value}>{children}</MediaContext.Provider>
  );
}

export const useMediaContext = (): MediaContextProps => {
  const context = useContext(MediaContext);
  return context;
};
