import Polyglot, { type PolyglotOptions } from 'node-polyglot';
import { createContext, useContext, useMemo, type ReactNode } from 'react';

export type I18nTranslationFunction<T = string> = (
  phrase: T,
  options?: number | Polyglot.InterpolationOptions,
  locale?: string,
) => string;

type I18nContextProps<T = string> = {
  t: I18nTranslationFunction<T>;
};

const I18nContext = createContext<I18nContextProps | undefined>(undefined);

export const useTranslation = <T extends string>(): I18nContextProps<T> => {
  const context = useContext(I18nContext);

  if (!context) {
    throw new Error('useI18n must be used within an I18nProvider');
  }

  return context;
};

export type I18nProviderProps = {
  children: ReactNode;
} & Required<Pick<PolyglotOptions, 'locale' | 'phrases'>>;

export const I18nProvider = ({
  children,
  locale,
  phrases,
}: I18nProviderProps) => {
  const polyglot = useMemo(
    () => new Polyglot({ phrases, locale }),
    [locale, phrases],
  );

  const t = (
    phrase: string,
    options?: number | Polyglot.InterpolationOptions,
  ) => polyglot.t(phrase, options);

  return <I18nContext.Provider value={{ t }}>{children}</I18nContext.Provider>;
};
