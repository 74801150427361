/**
 * Return object with encoded values
 *
 * @category Function
 *
 * @example
 * const obj = {
 *  token: 'FR=AAAA+',
 *  string: 'test',
 * }
 *
 * getEncodedObject(obj) => { token: 'FR%3DAAAA%2B', string: 'test' }
 */
export const getEncodedObject = (
  object: Record<string, string>,
): Record<string, string> =>
  Object.entries(object).reduce(
    (accumulator: Record<string, string>, [key, value]) => {
      accumulator[key] = encodeURIComponent(value);
      return accumulator;
    },
    {},
  );
