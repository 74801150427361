import { ProfileAvatar } from '@canalplus/mycanal-sharedcomponent';
import type { ApiV2Profile } from '@dce-front/hodor-types/api/v2/me/profiles/definitions';
import { DIMENSIONS } from '@dce-front/onewebapp-dive-utils';
import { PROFILE, ProfileModal } from '@dce-front/onewebapp-utils';
import type { JSX } from 'react';
import { useSelector } from 'react-redux';
import IconArrowThick from '../../../assets/svg/arrowThick.svg';
import { TemplateTypes } from '../../../constants/templateTypes';
import { useAppDispatch } from '../../../helpers/hooks/useAppDispatch';
import { useInvariantSelector } from '../../../helpers/hooks/useInvariantSelector';
import { useTranslation } from '../../../lang';
import {
  applicationResizeSelector,
  getFeatureToggleKidsProfiles,
} from '../../../store/slices/application-selectors';
import {
  setProfileView,
  setSelectedProfile,
  wipeProfileInfos,
} from '../../../store/slices/profilesModal';
import { profilesSelector } from '../../../store/slices/user-selectors';
import Button from '../../Button/Button';
import ProfilesListTV from '../ProfilesListTV/ProfilesListTV';
import styles from './ProfilesList.css';

/**
 * ProfilesList
 *
 * Show lists of profiles.
 *
 * @returns {JSX.Element}
 *
 *
 */
function ProfilesList(): JSX.Element {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const profiles = useSelector(profilesSelector);
  const featKidsProfiles = useSelector(getFeatureToggleKidsProfiles);
  const profilesList = !featKidsProfiles
    ? profiles.filter(
        (profile) => profile.type === PROFILE && !profile.isKidsProfile,
      )
    : profiles.filter((profile) => profile.type === PROFILE);
  const resizeMode = useInvariantSelector(applicationResizeSelector);

  const handleClick = (profile?: ApiV2Profile) => {
    dispatch(wipeProfileInfos());
    dispatch(setSelectedProfile(profile));
    dispatch(
      setProfileView(
        profile
          ? ProfileModal.ProfileEditionView
          : ProfileModal.ProfileCreationView,
      ),
    );
  };

  const profilesListDimensions = DIMENSIONS.PROFILE_AVATAR.normal;

  return $_BUILD_RENDERMODE_CSR ? (
    <ProfilesListTV
      handleClick={handleClick}
      profilesList={profilesList}
      resizeMode={resizeMode}
    />
  ) : (
    <div className={styles.profilesList}>
      <h2 className={styles.profilesList__title}>
        {t('ProfileManager.manage')}
      </h2>
      <ul className={styles.profilesList__list}>
        {profilesList.map((profile) => (
          <li className={styles.profilesList__profile} key={profile.profileId}>
            <button
              type="button"
              onClick={() => handleClick(profile)}
              className={styles.profilesList__container}
              {...(profile.displayName && {
                'aria-label': t('ProfileManager.editAriaLabel', {
                  displayName: profile.displayName,
                }),
              })}
            >
              <div className={styles.profilesList__profileAvatarWrapper}>
                <ProfileAvatar
                  avatar={profile.URLImage || ''}
                  altImage={profile.displayName || ''}
                  customClass={styles.profilesList__profileAvatar}
                  dimensions={profilesListDimensions}
                  isKidsProfile={profile.isKidsProfile}
                  isTvDevice={false}
                />
              </div>
              <p className={styles.profilesList__label}>
                <span className={styles.profilesList__name}>
                  {profile.displayName}
                </span>
              </p>
              <IconArrowThick className={styles.profilesList__arrow} />
            </button>
          </li>
        ))}
      </ul>

      {profiles.length >= 10 ? (
        <p className={styles.profilesList__disclaimer}>
          <span className={styles.profilesList__disclaimer__line}>
            {t('ProfileManager.maxAmount')}
          </span>
          <span className={styles.profilesList__disclaimer__line}>
            {t('ProfileManager.deleteToCreate')}
          </span>
        </p>
      ) : (
        <Button
          handler={() => handleClick()}
          className={styles.profilesList__addProfileButton}
          color={TemplateTypes.SECONDARY}
          text={t('ProfileManager.addProfile')}
        />
      )}
    </div>
  );
}

export default ProfilesList;
