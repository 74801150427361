import { Template } from '@canalplus/sdk-hodor';
import type { ApiV2Strate } from '@dce-front/hodor-types/api/v2/page/dtos/strates/contents/definitions';
import { useEffect, useMemo, useState } from 'react';
import LoadableBanner from '../../../components/Banner/LoadableBanner';
import LoadableCarrouselTemplate from '../../../components/Carrousel/LoadableCarrouselTemplate';
import { StrateMode } from '../../../constants/strates';
import LoadableContentGrid from '../../../templates/ContentGrid';
import LoadableContentGridPerso from '../../../templates/ContentGridPerso';
import LoadableContentRowLiveTv from '../../../templates/ContentRowLive';
import LoadableContentRowPerso from '../../../templates/ContentRowPerso';
import LoadableContentRowStandard from '../../../templates/ContentRowStandard';
import LoadableLandingV5Container from '../../../templates/LandingV5';
import LoadablePromotionStrate from '../../../templates/LandingV5/components/PromotionStrate/LoadablePromotionStrate';

/**
 * Preload some chunks for CSR render mode
 */
export const usePreloadChunks = (strates?: ApiV2Strate[]): void => {
  const [isChunksPreloaded, setIsChunksPreloaded] = useState(false);

  const stratesToPreload = useMemo(() => {
    return strates?.reduce((previousValue: ApiV2Strate[], currentValue) => {
      const exists = previousValue.some(
        (item) =>
          item.type === currentValue.type &&
          item.strateMode === currentValue.strateMode,
      );
      if (!exists) {
        previousValue.push(currentValue);
      }
      return previousValue;
    }, []);
  }, [strates]);

  const preloadTemplates = useMemo(() => {
    return () => {
      stratesToPreload?.forEach(({ type, strateMode }) => {
        switch (type) {
          case Template.Banner:
            LoadableBanner.preload();
            break;
          case Template.Carrousel:
            LoadableCarrouselTemplate.preload();
            break;
          case Template.ContentRow:
            if (strateMode === StrateMode.Perso) {
              LoadableContentRowPerso.preload();
            } else if (strateMode === StrateMode.LiveTv) {
              LoadableContentRowLiveTv.preload();
            } else {
              LoadableContentRowStandard.preload();
            }
            break;
          case Template.ContentGrid:
            if (strateMode === StrateMode.Perso) {
              LoadableContentGridPerso.preload();
            } else {
              LoadableContentGrid.preload();
            }
            break;
          case Template.PromotionBanner:
            LoadablePromotionStrate.preload();
            break;
          case Template.Landing:
            LoadableLandingV5Container.preload();
            break;
          default:
            break;
        }
      });
    };
  }, [stratesToPreload]);

  useEffect(() => {
    if ($_BUILD_RENDERMODE_CSR && !isChunksPreloaded) {
      preloadTemplates();
      setIsChunksPreloaded(true);
    }
  }, [isChunksPreloaded, preloadTemplates]);
};
