import { UserMenuOption } from '@canalplus/mycanal-sharedcomponent';
import { Template } from '@canalplus/sdk-hodor';
import type { ApiV2OnClick } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import classNames from 'classnames';
import type { JSX, ReactElement, ReactNode } from 'react';
import IconRightArrow from '../../assets/svg/rightArrow.svg';
import { refreshProfileOneCoreAndReload as updateRightsAndReloadTv } from '../../helpers/ifcOneCore/ifc-onecore-helpers';
import ButtonLinker, {
  ButtonLinkerVariant,
} from '../ButtonLinker/ButtonLinker';
import { FlipSwitch } from '../FlipSwitch/FlipSwitch';
import Linker from '../Linker/Linker';
import styles from './Setting.css';

type LabelAndLinkProps = {
  /** The content to be rendered inside the component. */
  children: React.ReactNode;

  /**
   * Determines if the label should be displayed with a medium font size.
   * When `true`, the label will have a slightly larger font for better visibility.
   */
  isMediumLabelFontSize: boolean;

  /** An optional icon to be displayed alongside the label. */
  labelIcon?: ReactElement;

  /**
   * The text label to display.
   * This provides context or a title for the linked content or action.
   */
  label: string;
};

function LabelAndLink({
  isMediumLabelFontSize,
  labelIcon,
  label,
  children,
}: LabelAndLinkProps): JSX.Element {
  return (
    <>
      <div
        className={classNames(styles.setting__type, {
          [styles['setting__type--medium']!]: isMediumLabelFontSize,
        })}
      >
        {labelIcon || null}
        {label && <p>{label}</p>}
      </div>
      <div className={styles.setting__action}>{children}</div>
    </>
  );
}

export type SettingProps = {
  /**
   * Accessibility label for the component.
   * Helps screen readers describe the purpose of the setting.
   */
  'aria-label'?: string;

  /** The content to be rendered inside the component. */
  children?: ReactNode | null;

  /**
   * Optional text displayed below the setting.
   * Typically used for additional context or descriptions.
   */
  footerText?: string;

  /** Action called when flipSwitch was changed */
  handleChange?: (event?: React.MouseEvent<HTMLElement>) => void;

  /**
   * Unique identifier for the setting.
   * Useful for accessibility and handling interactions.
   */
  id?: string;

  /** Whether if flipSwitch is checked */
  isChecked?: boolean;

  /**
   * Controls the font size of the label.
   * When `true`, the label is displayed in a medium font size.
   */
  isMediumLabelFontSize?: boolean;

  /** whether the subscription section should be displayed or not */
  isSubscription?: boolean;

  /** Whether if render type is FlipSwitch */
  isTypeFlipSwitch?: boolean;

  /** Content to render */
  label?: string;

  /** Optional icon displayed alongside the label. */
  labelIcon?: ReactElement;

  /** Optional action-related label. */
  labelIsAction?: string;

  /** FlipSwitch name */
  name?: string;

  /**
   * Defines an action when the setting is clicked.
   * Can include API-related interactions.
   */
  onClick?: ApiV2OnClick;

  /** Placeholder text for input-based settings. */
  placeholder?: string;

  /**
   * Determines if the setting should remain fixed in its position.
   * When `true`, it is styled as a sticky element.
   */
  sticky?: boolean;

  /**
   * Adds a border around the setting.
   * When `true`, a visual border is applied.
   */
  withBorder?: boolean;
};

const Setting = ({
  'aria-label': ariaLabel = '',
  children = null,
  footerText = '',
  handleChange = () => null,
  id = '',
  isChecked = false,
  isMediumLabelFontSize = false,
  isSubscription = false,
  isTypeFlipSwitch = false,
  label = '',
  labelIcon,
  name = '',
  onClick,
  placeholder = '',
  sticky = false,
  withBorder = false,
}: SettingProps): JSX.Element => {
  const renderFlipSwitch = () => {
    return (
      <li className={styles.setting}>
        <div className={styles.setting__type}>{children}</div>
        <div className={styles.setting__action}>
          <FlipSwitch
            handleChange={handleChange}
            name={name}
            id={id}
            isChecked={isChecked}
            aria-label={ariaLabel}
          />
        </div>
      </li>
    );
  };

  const renderTvButton = () => {
    return (
      <li className={styles.setting_tv}>
        {onClick?.displayTemplate === Template.UpdateRights ? (
          <UserMenuOption
            ariaTitle={label}
            handleClick={() => updateRightsAndReloadTv({ noCache: true })}
            id={id}
            Linker={Linker}
            showChevron={<IconRightArrow />}
            subTitle={placeholder}
            title={label || ''}
          />
        ) : (
          <ButtonLinker
            className={classNames(styles.setting_tv_button, 'settingTvButton')}
            id={id}
            linkerData={{ mainOnClick: onClick }}
            ariaLabel={label}
            text={label}
            hasActionIcon={!!children}
            icon={children}
            subtext={placeholder}
            variant={ButtonLinkerVariant.MenuOption}
          />
        )}
        {footerText && (
          <div className={styles.setting_tv_info}>{footerText}</div>
        )}
      </li>
    );
  };

  const renderLinks = () => {
    if (isSubscription) {
      return <li className={styles.setting__multilinks}>{children}</li>;
    }

    return (
      <li
        className={classNames(styles.setting, {
          [styles.setting__withBorder!]: withBorder,
          [styles.setting__sticky!]: sticky,
        })}
      >
        {onClick ? (
          <Linker
            data={{ mainOnClick: onClick }}
            className={styles.setting__button}
          >
            <LabelAndLink
              labelIcon={labelIcon}
              isMediumLabelFontSize={isMediumLabelFontSize}
              label={label}
            >
              {children}
            </LabelAndLink>
          </Linker>
        ) : (
          <LabelAndLink
            labelIcon={labelIcon}
            isMediumLabelFontSize={isMediumLabelFontSize}
            label={label}
          >
            {children}
          </LabelAndLink>
        )}
      </li>
    );
  };

  if (isTypeFlipSwitch) {
    return renderFlipSwitch();
  }

  return $_BUILD_RENDERMODE_CSR ? renderTvButton() : renderLinks();
};

export default Setting;
