import classNames from 'classnames';
import type { JSX } from 'react';
import { forwardRef } from 'react';
import { useDiveContext } from '../../../../context';
import {
  MaterialChevronRightSvg,
  StatusCheckSvg,
} from '../../../../icons/icons';
import {
  listSharedCVA,
  listSharedChevronIconCVA,
  listSharedLabelCVA,
  listSharedLeftIconCVA,
  listSharedRightElementCVA,
  listSharedSublabelCVA,
  listSharedSubtitleCVA,
  listSharedTitleCVA,
  listSharedTitlesContainerCVA,
} from '../../../../shared/styles/List.Shared.cva';
import { DiList } from '../../../internal/DiList/DiList';
import {
  listCVA,
  listCheckIconCVA,
  listChevronIconCVA,
  listLabelCVA,
  listLabelsContainerCVA,
  listSublabelCVA,
  listTitleCVA,
  listTitlesContainerCVA,
} from './List.cva';
import type { ListProps } from './List.types';

/**
 * A List can represent continuous groups or hierarchies of text.
 * It can be composed of multiple informative texts and it can enable user interactions.
 *
 * @example
 *
 * ```tsx
 * <List title="Title" label="Label" leftIcon={<LinkSelfcareSvg />} />
 * ```
 */
export const List = forwardRef<HTMLButtonElement | HTMLDivElement, ListProps>(
  function List(
    {
      title,
      subtitle,
      label,
      actionableElement,
      lineThroughLabel,
      leftIcon,
      displayChevron = false,
      isChecked = false,
      isDisabled: disabled = false,
      layout = 'inline',
      checkIconAriaLabel = 'selected',
      className,
      ...props
    },
    forwardedRef,
  ): JSX.Element {
    const { isTv, device } = useDiveContext();
    const hasLeftElement = !isTv && !!leftIcon;
    const hasRightElement =
      displayChevron || isChecked || (!isTv && !!actionableElement);
    const hasLineThrough = !!lineThroughLabel;

    const prefixContent = hasLeftElement && (
      <div className={listSharedLeftIconCVA()}>{leftIcon}</div>
    );

    const mainContent = (
      <div
        className={classNames(
          listSharedTitlesContainerCVA(),
          listTitlesContainerCVA({
            device,
            layout,
          }),
        )}
      >
        <span
          className={classNames(
            listSharedTitleCVA({ device, disabled }),
            listTitleCVA({ device }),
          )}
        >
          {title}
        </span>
        {subtitle && (
          <span
            className={listSharedSubtitleCVA({
              device,
              disabled,
            })}
          >
            {subtitle}
          </span>
        )}
      </div>
    );

    const suffixContent = (label || lineThroughLabel || hasRightElement) && (
      <div className="flex items-center flex-row">
        {(label || lineThroughLabel) && (
          <div className={listLabelsContainerCVA()}>
            {label && (
              <span
                className={classNames(
                  listSharedLabelCVA({ device }),
                  listLabelCVA({
                    device,
                    disabled,
                    hasLineThrough,
                  }),
                )}
              >
                {label}
              </span>
            )}
            {lineThroughLabel && (
              <s
                className={classNames(
                  listSharedSublabelCVA({
                    device,
                    disabled,
                  }),
                  listSublabelCVA({
                    device,
                  }),
                )}
              >
                {lineThroughLabel}
              </s>
            )}
          </div>
        )}
        {hasRightElement && (
          <div className={listSharedRightElementCVA({ device })}>
            {displayChevron ? (
              <MaterialChevronRightSvg
                aria-hidden
                className={classNames(
                  listSharedChevronIconCVA({ device }),
                  listChevronIconCVA({
                    device,
                    hasLineThrough,
                    disabled,
                  }),
                )}
              />
            ) : isChecked ? (
              <StatusCheckSvg
                role="img"
                aria-label={checkIconAriaLabel}
                className={listCheckIconCVA({
                  device,
                })}
              />
            ) : (
              actionableElement
            )}
          </div>
        )}
      </div>
    );

    return (
      <DiList
        ref={forwardedRef}
        as={actionableElement && !isTv ? 'div' : 'button'}
        disabled={disabled}
        className={classNames(
          listSharedCVA({ device, disabled }),
          listCVA({ device }),
          className,
        )}
        prefix={prefixContent}
        suffix={suffixContent}
        {...props}
      >
        {mainContent}
      </DiList>
    );
  },
);
