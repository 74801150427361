import { memo } from 'react';
import type { AppHistory, AppLocation } from '../../../../typings/routing';
import useGetPageContent from '../hooks/useGetPageContent/useGetPageContent';
import usePageScrollTop from '../hooks/usePageScrollTop/usePageScrollTop';

export type DefaultRouteComponentProps = {
  location: AppLocation;
  history: AppHistory;
};

/** Route used like a fallback */
function DefaultRouteComponent({
  location,
  history,
}: DefaultRouteComponentProps): null {
  usePageScrollTop(history, location);

  useGetPageContent(history, location);

  return null;
}

const areEqual = (
  prevProps: DefaultRouteComponentProps,
  nextProps: DefaultRouteComponentProps,
): boolean => {
  const currLoc = prevProps.location.pathname;
  const nextLoc = nextProps.location.pathname;

  return currLoc === nextLoc;
};

DefaultRouteComponent.displayName = 'DefaultRouteComponent';

export default memo(DefaultRouteComponent, areEqual);
