import loadable from '@loadable/component';
import Spinner from '../../../components/Spinner/Spinner';

const LoadableSlideshowNotifications = loadable(
  () => import('./SlideshowNotifications'),
  {
    fallback: <Spinner />,
  },
);

export default LoadableSlideshowNotifications;
