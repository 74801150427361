import type { ApiV2ExpertModeHighlightPage } from '@dce-front/hodor-types';
import type { HighLightState } from './types';

export const getFormattedContentGridHighlights = (
  hodorResponse: ApiV2ExpertModeHighlightPage,
): HighLightState => {
  return {
    empty: hodorResponse.empty,
    sportEventId: hodorResponse.sportEventId,
    strates: hodorResponse.strates,
    currentPage: hodorResponse.currentPage ?? {},
    tracking: hodorResponse.tracking,
  };
};
