import classNames from 'classnames';
import type { JSX, MouseEvent, Ref } from 'react';
import type { ButtonShapes } from '../../constants/button';
import { TemplateTypes } from '../../constants/templateTypes';
import Spinner from '../Spinner/Spinner';
import styles from './Button.css';

export const BIG_LABEL_MINIMUN_LENGTH = 15; // default value for a button with width 645px and font-size 32px (detailV5)

export type ButtonProps = {
  ariaLabel?: string;
  buttonRef?: Ref<HTMLAnchorElement | HTMLButtonElement>;
  className?: string;
  color?: TemplateTypes;
  fullWidth?: boolean;
  handler?: (e: MouseEvent) => void;
  hasActionIcon?: boolean;
  icon?: React.ReactNode;
  id?: string;
  isBlank?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isMobile?: boolean;
  isSubmit?: boolean;
  link?: string;
  shape?: ButtonShapes;
  shouldHandleBigLabel?: boolean;
  subtext?: string;
  text?: string;
  isV5Style?: boolean;
  title?: string;
  bigLabelMinimumLength?: number;
  [data: `data-${string}`]: string;
};

/**
 * Button
 *
 * @param className     Custom className to apply
 * @param text          Text to display inside the button
 * @param handler       Function to bind to 'onClick'
 * @param color         Theme of the button, see prop types for all supported colors
 * @param isMobile      whether we are on mobile or not
 * @param isSubmit      whether to apply type="submit" to the button
 * @param isDisabled    whether to enable/disable JS events and add styles
 * @param fullWidth     If true, the button will take up the full width of its container
 * @param shape         Shape of the button
 * @param icon          Icon of the button
 * @param ariaLabel     Define a string that labels the current element
 * @param href          Url of the link
 * @param isBlank       whether if the url should be opened in new tab
 */

function Button({
  ariaLabel = '',
  buttonRef,
  className = '',
  color = TemplateTypes.PRIMARY,
  fullWidth = false,
  handler = () => {},
  hasActionIcon = false,
  icon,
  id,
  isBlank = false,
  isDisabled = false,
  isLoading = false,
  isMobile = false,
  isSubmit = false,
  link = '',
  shape,
  shouldHandleBigLabel = false,
  subtext = '',
  text = '',
  isV5Style,
  title,
  bigLabelMinimumLength = BIG_LABEL_MINIMUN_LENGTH,
  ...props
}: ButtonProps): JSX.Element {
  const showBigLabelVariant =
    shouldHandleBigLabel && text.length >= bigLabelMinimumLength;

  const getClassnames = () =>
    classNames(styles.button, [styles[`button__${color}`]!], {
      [styles['button--mobile']!]: isMobile,
      [styles['button--fullWidth']!]: fullWidth,
      [styles['button--isV5Style']!]: isV5Style,
      [styles['button--isV5Style--big']!]: isV5Style && showBigLabelVariant,
      [styles[`button__${shape}`]!]: shape,
      globalButton: isV5Style,
      [className]: className,
    });

  const hasActionGroup = hasActionIcon && subtext;
  const targetProps = isBlank
    ? ({ target: '_blank', rel: 'noreferrer' } as const)
    : {};

  return link ? (
    <a
      className={getClassnames()}
      href={link}
      onClick={handler}
      id={id}
      ref={buttonRef as Ref<HTMLAnchorElement>}
      {...props}
      {...targetProps}
    >
      {text}
    </a>
  ) : (
    <button
      className={getClassnames()}
      disabled={isDisabled}
      onClick={!isLoading ? handler : undefined}
      type={isSubmit ? 'submit' : 'button'}
      aria-label={ariaLabel}
      id={id}
      ref={buttonRef as Ref<HTMLButtonElement>}
      title={title}
      {...props}
    >
      {isLoading ? (
        <Spinner size={5} isUnsetPosition color="white" />
      ) : (
        <>
          {!hasActionIcon && icon && (
            <span className={styles.button__icon}>{icon}</span>
          )}
          {text && (
            <span
              className={classNames(styles.button__label, {
                [styles['button__label--big']!]: showBigLabelVariant,
              })}
            >
              {text}
            </span>
          )}
          {hasActionGroup && (
            <span className={styles.button__actionGroup}>
              <span className={styles.button__subtext}>{subtext}</span>
              <span className={styles.button__icon}>{icon}</span>
            </span>
          )}
          {!hasActionGroup && subtext && (
            <span className={styles.button__subtext}>{subtext}</span>
          )}
          {!hasActionGroup && hasActionIcon && icon && (
            <span className={styles.button__icon}>{icon}</span>
          )}
        </>
      )}
    </button>
  );
}

export default Button;
